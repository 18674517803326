@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }


  .BackgroundContainer{
    position:absolute !important;
    top:0 !important;
    background-color: var(--amplify-colors-darkgrey) !important;
  }

  @media screen and (max-width: 450px) {
    .BackgroundContainer {
      position:absolute !important;
      top:0 !important;
    }
  }
    
    .Background {  
      position: absolute; 
      z-index: -500;   
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }