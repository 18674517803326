@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
    font-family: 'Mulish', sans-serif;
    font-size: 28px;
    color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;  
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
  }  



  .TextField{
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) { 
    .TextField{
      color: var(--amplify-colors-grey) !important;
      font-size: 0.8em !important;
    }
  }

  
  .TextFieldRecieved{
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:12vw !important;
  }

  

  .TextFieldTitle {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:30vw !important;
  }

  .TextFieldDateTime {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:10vw !important;
  }

  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }


  .RowButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
  }


  .RowButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RowButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  @media screen and (max-width: 450px) {
    .RowButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  auto  !important;
      width:  8vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) {
    .RowButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  4vh  !important;
      width:  2vw  !important;
      padding: 0px 0px;
    }
  }

  .TableRowBackground {
    background-color: var(--amplify-colors-black) !important;
    width:50vw !important;
  }

  .TableRowProduction {
    background-color: var(--amplify-colors-turquoise-light) !important;
    border-bottom-color: var(--amplify-colors-purple-dark) !important;
    color: var(--amplify-colors-black) !important;
    --amplify-components-text-color: var(--amplify-colors-black);
  }

  .TableRowTest {
    background-color: var(--amplify-colors-orange-light) !important;
    border-bottom-color: var(--amplify-colors-purple-dark) !important;
    border-bottom-width: 2px !important;
    color: var(--amplify-colors-black) !important;
    --amplify-components-text-color: var(--amplify-colors-black);
  }

  
  .RedditTitleSpan{
    padding: 100px 10px;
  }

  .ToolBar{
    padding: 20px 10px;
  }

  .HideStatusRow {
    visibility: collapse;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .ShowStatusRow {
    background-image: url("https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo2.svg");
    visibility: visible;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .TableRowDataStatus{
    color: var(--amplify-colors-grey) !important;  

  }    


  .TableRowData{
    text-align: left !important;
    vertical-align: middle !important;
    font-size: 0.8em !important;
  } 

  .TableRowHeadingData{
    text-align: left !important;
    vertical-align: middle !important;
    font-size: 0.8em !important;
    width: 20vw !important;
  } 
  
  .TableRowBackground:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .TableRowDataERROR{
    background-color: darkred !important;
    color: var(--amplify-colors-grey) !important;  
  }

  .TableRowDataERROR:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:2.2vw  !important;
  }


  .ImageCell {
    height:10vh !important;
    width: 5vw !important;
  }

  .PhotoImage {
    width: 30%;
    margin: 25px; 
    padding: 5px 5px 5px 5px;
  }

  .OFName {
    color: var(--amplify-colors-turquoise) !important;
    font-size: 1em !important;
    font-family: ConigenReg;
  }

.TwitterImage{
  height:5vh !important;
  width: 2.5vw !important;
  --amplify-components-image-max-width: 150px !important;
}


  .CheckboxFieldColumn {
    color: transparent !important;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    box-sizing: border-box;  
    height: 1em; 
    width: 1em; 
    font-size:1em !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  span {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1.5em !important;
    font-weight: 100 !important;
  }



  .ChildRow {
    background-color: transparent!important;
  }
  
  .ExpandedRow {
    padding: 10px;
  }