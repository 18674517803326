
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}


@media screen and (max-width: 450px) {
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:1em !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}
}
.navBar {
  position: absolute;
  height:5vh;
  top: 2vh;
  width: 100vw;
  background-color: transparent !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size:4em !important;
}


@media screen and (max-width: 450px) {
.navBar {
  position: absolute;
  height:20vh;
  top: 3vh;
  width: 100vw;
  background-color: transparent !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size:1em !important;
  }
}


@media screen and (min-width: 451px) and (max-width: 1000px) {

  .navBar {
    position: absolute;
    height:20vh;
    top: 5vh;
    width: 100vw;
    background-color: transparent !important;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1em !important;
  
  }

}

@media screen and (min-width: 1001px) {

  .navBar {
    position: absolute;
    height:5vh;
    top: 10vh;
    width: 100vw;
    background-color: transparent !important;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1em !important;
  
  }

}


.navLinkDAO {
  display: inline;
  text-decoration: none;
	list-style: none;
  color:#CCCBCA;
  font-family: Graphik !important;
}

@media screen and (max-width: 450px) {
.navLinkDAO {
  padding-right:.5em !important;
  padding:0vw;
  font-size: 0.5em !important;
}
}

@media screen and (min-width: 451px)  and (max-width: 1000px) {
  .navLinkDAO {
    font-size: 0.8em !important;
  }
  }
  
  @media screen and (min-width: 1001px)  and (max-width: 4000px) {
    .navLinkDAO {
        font-size: 1.5em !important;
      }
    }  

.navLinkDAO:hover {
  color: var(--amplify-colors-purple) !important;
}

.logoimg{
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
}


@media screen and (max-width: 450px) { 
  .logoimg{
    height:5vh;
    width:10vw;
  }
}

@media screen and (min-width: 451px) {
  .logoimg{
    height:8vh;
    width:4vw;
  }
}

.logoName{
  position:relative !important;
  font-family: Runalto;
  font-size: 6em !important;
  color: var(--amplify-colors-turquoise);  
}


@media screen and (max-width: 450px) { 
  .logoName{
    font-size: 2em !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .logoName{
    font-size: 2em !important;
  }
}

.Table{
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
}

@media screen and (max-width: 450px){
  .Table{
    width: 90vw !important;
    position: relative !important;
    top: 0vh !important;
    left:0vw !important;
  }
}

@media screen and (min-width: 451px){
  .Table{
    border-width: 2px;
    width: 60vw !important;
    position: relative !important;
    top: 0vh !important;
    left:0vw !important;
  }
}


.TableRow {
  border-style: solid;
  border-color: transparent !important;
  border-width: 2px;  
}
@media screen and (max-width: 450px){
  .TableRow {

    position: relative !important;
    top: 0vh !important;
    left:3vw !important;
    margin-bottom: 1em !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .TableRow {
    position: relative !important;
    left:3vw !important;    
    width: 60vw !important;
    padding-bottom: 2em !important;
    height: 8vh !important;
  }
}

@media screen and (min-width: 1001px) {
  .TableRow {
    position: relative !important;
    top: 0vh !important;
    left:3vw !important;
    width: 60vw !important;
    padding-bottom: 1em !important;
    height: 3vh !important;
  }
}

.TableCell {
  --amplify-components-table-data-padding:3px !important;
  --amplify-internal-table-td-padding:3px !important;
  border-style: solid;
  border-color: transparent !important;
  border-width: 2px;  
}
@media screen and (min-width: 451px)  {
  .TableCell {
    --amplify-components-table-data-padding:0px !important;
    --amplify-internal-table-td-padding:0px !important;  
  }
}