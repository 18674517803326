
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.BackgroundTop{
  position: relative;
  z-index:-999 !important;
  top: 0vh !important;
  left: 0vw !important;  
  gap:0rem !important;
  height: 1000vh !important;
  width: 100vw !important;
  background-color: var(--amplify-colors-black) !important;
}

.MasterContainer{
  position: absolute !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: stretch !important;
  align-content: start !important;
  row-gap: 2em !important;
  flex-wrap: wrap !important;
  top: 0vh !important;
  left: 0vw !important;  
  z-index:-999 !important;
  height: 100vh;
  width: 100vw;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .MasterContainer{
    gap:1rem !important;
    background-color: var(--amplify-colors-black) !important;
  }
}

.NavBarRow{
  position:sticky !important;
  z-index:900 !important;
  top: 5vh !important;
  left: 15vw !important;
  width: 75vw !important;
  display: flex !important;
  flex-direction:row !important;
  justify-content:right !important;
  align-items:center !important;
  align-content:center !important;
  flex-wrap:nowrap !important;
  gap:1vw !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

@media screen and (max-width: 450px) {
  .NavBarRow{
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
    gap:1vw !important;
  }
}

.LogoName{
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.Link{
  text-decoration: none !important;
}

.TurboFanBoostButton{
  position:sticky !important;
  top:20vh !important;
  left:20vw !important;
}

.TestButton{
  font-size: 2em !important;
  font-weight: 400 !important;
  color: lightgoldenrodyellow !important;

}