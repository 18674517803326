@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }




  .PhotoImage {
    width:10vw !important;
    height:20vh !important;
    border-radius: 50%;
  }

  @media screen and (max-width: 450px) {
  .PhotoImage {
    width:20vw !important;
    height:11vh !important;
    opacity: 0.75 !important;
  }
}

