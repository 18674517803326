

  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  --amplify-colors-podcastpurple:#16052b;  
  --amplify-components-fieldcontrol-font-size:20px !important;
  --hue: 190deg;
  --sat: 50%;
  --blur: 0px;
  --hue2: calc(var(--hue) + 60deg);
  --sat2: calc(var(--sat) + 10%);
  --clr: hsl(var(--hue) var(--sat) 90%);  
  --clr2: hsl(var(--hue2) var(--sat2) 85%);
  --text: hsla(var(--hue), 70%, 10%, .9);
  --gradoffset: 45%; 
  --gradgap: 30%;
}

.Background{  
  border-style: solid !important;
  border-width: 3px !important;
  border-color:transparent !important;
  margin:3em !important;
  padding:3em !important;
}

@media screen and (max-width: 768px) {
  .Background{
    margin: 0.1em !important;
    padding:0.1em !important;
  }
}


.BackgroundSquare {
  display:block !important;
  box-sizing: border-box !important;
  position: absolute  !important;
  background-image: radial-gradient(var(--amplify-colors-podcastpurple), var(--amplify-colors-black));
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  opacity: 0.8 !important;  
  filter:blur(1.1rem) !important;
  border-style: solid !important;
  border-width: 3px !important;
  border-color:transparent !important;
  height:60vh !important;
  width:80vw !important;
}

@media screen and (max-width: 768px) {
  .BackgroundSquare{
    width:90vw !important;
  }
}


.FormDiv {
  position:relative !important;
  top: 3vh important;
  left:0vw !important;
  padding: 1em;
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
  box-sizing: border-box;
}

  @media screen and (max-width: 768px) {
    .FormDiv {
      position:relative;
      top: 0vh !important;
      left:0vw !important;
      padding: 0.1em !important;;
      border-style: solid;
      border-width: 0px;
      border-color:transparent;
      display:flex;
      flex-direction:column; 
    }
}

.TextDiv{
  width: 60vw !important;
  padding: 4vh !important;
}
@media screen and (max-width: 768px) {
  .TextDiv {
    position:relative;
    left:8vw !important;
    width: 80vw !important;
    padding: 0.1rem !important;
  }
}


.Title {
  font-size: 2em !important;  
  line-height: 3vh !important;
  letter-spacing: .4rem !important;
  font-family: ConigenExtBd !important;  
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-purple-light) !important;
  width: 60vw !important;
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 1em !important;
    width: 80vw !important;
    letter-spacing: .1rem !important;
    color: var(--amplify-colors-purple-light) !important;
  }
}


.Info {
  font-size: 1.5em !important;  
  line-height: 3vh !important;
  font-family: ConigenReg !important;
  font-weight: 100 !important;
  letter-spacing: 0.1em !important;  
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-orange-light) !important;
  width: 60vw !important;
}


@media screen and (max-width: 768px) {
  .Info {
    font-size: 1em !important;
    width: 80vw !important;
    letter-spacing: .1rem !important;
    color: var(--amplify-colors-orange-light) !important;
  }
}


.SubTitle {
  font-size: 5em !important;  
  line-height: 3vh !important;
  font-family: ConigenExtBd !important;  
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-turquoise-light) !important;
  width: 60vw !important;
}



@media screen and (max-width: 450px) {
  .SubTitle {
    font-size: 1em !important;  
    line-height: 1.5em !important;  
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    width: 90vw !important;    
  }
}


@media screen and (min-width: 451px) and (max-width: 1200px) {
  .SubTitle {
    font-size: 0.5em;
    line-height: 1.2em;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    width: 60vw !important;
  }
}


.SubSubTitle {
  font-size: 1.5em !important;  
  line-height: 3vh !important;
  padding-bottom: 8px;
  padding-top: 8px;
  font-family: ConigenReg !important;  
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
  width: 60vw !important;
}


@media screen and (max-width: 450px) {
  .SubSubTitle {
    font-size: 0.5em !important;  
    line-height: 1em !important;  
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    width: 90vw !important;
  }
}


@media screen and (min-width: 451px) and (max-width: 1200px) {
  .SubSubTitle {
    font-size: 0.5em;
    line-height: 1.2em;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    width: 60vw !important;
  }
}

  .ButtonLinkText {
    text-decoration: none !important;
    color: var(--amplify-colors-black) !important; 
    font-size:  clamp(0.75rem, 2.5vw, 2rem) !important;
    font-weight: 100 !important;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    padding: 1rem !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
  }



.Button {
  width:  20vw !important;
  text-decoration: none !important;
  height:5vh !important;
  border-width: 2px;
  color: var(--amplify-colors-black) !important; 
  border-color: var(--amplify-colors-turquoise-light) !important; 
  background-color: var(--clr)!important;
  background-image:   
    linear-gradient(180deg, 
      var(--clr2) var(--gradgap), 
      transparent calc(100% - var(--gradgap))
    )!important;
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  padding: 1.1em 1.5em;
  border-radius: 2em;
  border: none;    
  box-shadow: 
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  
  &::before, &::after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 5em;
  }

  &::before {
    background-image: 
      radial-gradient(ellipse, 
        hsla(var(--hue), 100%, 90%, .8) 20%,
        transparent 50%,
        transparent 200%
      ),
      linear-gradient(90deg, 
        hsl(0deg, 0%, 25%) -10%, 
        transparent 30%, 
        transparent 70%, 
        hsl(0deg, 0%, 25%) 110%
      );
    box-shadow: 
      inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
      inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
      inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: 200% 80%, cover;
    background-position: center 220%;
    mix-blend-mode: overlay;
    filter: blur(calc(var(--blur) * 0.5));
  }
  
  &::after {
    background: linear-gradient(
      180deg, 
      hsla(var(--hue2),100%,90%,.9), 
      hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
      transparent 80%
    );
    top: 0.075em;
    left: 0.75em;
    right: 0.75em;
    bottom: 1.4em;
    filter: blur(var(--blur));
    mix-blend-mode: screen;
  }
  
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: 
      0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
      0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
      0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
      0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
      inset 0 -2px 2px rgba(255,255,255,0.2);
    background-position: center calc( var(--gradoffset) - 0.75em );
  }  
}


@media screen and (max-width: 768px) {
  .Button {
    width: clamp(30vw, 50vw, 50vw) !important;
    height:5vh !important;
    font-size: 1rem!important;
    line-height: 1.1!important;
    letter-spacing: 0.1vw!important;
    font-weight: 20!important;
    font-family: Graphik, Arial, Helvetica, sans-serif!important;
  
    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color:var(--amplify-colors-black) !important;
    font-weight:800 !important;    
  }
}


.Button:hover{ 
  border-color: var(--amplify-colors-turquoise);
  background-color:var(--amplify-colors-orange);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
  color:var(--amplify-colors-black) !important;
  font-weight:800 !important;
}

.Button:focus{
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-orange);
     
}

.Button:disabled{ 
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-black);
     
}

.StatusMessageBox {
  position:relative !important;
  top: 4vh !important;
  color: var(--amplify-colors-orange);
}

@media screen and (max-width: 450px) {
.StatusMessageBox {  
  padding-top: 3vh !important;
  font-size: 1em !important;
}
}



@keyframes ApplicationButtonAnimation {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  95% {
    opacity: 0;
    transform: translateY(0%);
  }  
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


.Introduction {
  font-size: 2em !important;  
  line-height: 3vh !important;
  padding-bottom: 8px;
  padding-top: 8px;
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
}


@media screen and (max-width: 450px) {
  .Introduction {
    font-size: 1.0em;
    line-height: 1.2em;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    color: var(--amplify-colors-grey);
    width: 90vw !important;
  }
}

.metallicbutton {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:16vw !important;
  height:8vh !important;
  font-size: 2em !important;
  padding: 6px 16px;
  font-weight: 500;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

@media screen and (max-width: 450px) {  
  .metallicbutton {
    width:60vw !important;
    height:8vh !important;
    font-size: 1em !important;
    left:3vw !important;
  }
}




.SelectField {
  --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-color:var(--amplify-colors-white) !important;
  --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
  --amplify-components-selectfield-font-size:15px !important;
  --amplify-components-select-background-color:transparent !important;
  --amplify-components-select-color:var(--amplify-colors-white) !important;
  background-color: transparent !important;
  width:20vw !important;
}

@media screen and (max-width: 450px) {
  .SelectField {
    --amplify-components-fieldcontrol-font-size:1em !important;
    font-size:1.5em !important;
    width:90vw !important;
  }
  }


.SelectField option {
  --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-color:var(--amplify-colors-white) !important;
  --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
  --amplify-components-selectfield-font-size:15px !important;
  --amplify-components-select-option-background-color:var(--amplify-colors-black) !important;
  color: var(--amplify-colors-grey)!important;
  height: 2vh !important;
}

@media screen and (max-width: 450px) {
.SelectField option {
  --amplify-components-selectfield-font-size:1em !important;
  font-size:1em !important;
  height: 2vh !important;
}
}

.SelectField option:hover {
  --amplify-components-select-background-color:var(--amplify-colors-orange) !important;
}

.SelectFieldLabel{
  width:80vw !important;
  font-size:0.75em !important;
  color:var(--amplify-colors-white) !important;
}

@media screen and (max-width: 450px) {
  .SelectFieldLabel{
    width:80vw !important;
    font-size:1em !important;
    color:var(--amplify-colors-white) !important;
  }
}

.TopicLabel{
  width:80vw !important;
  font-size:0.75em !important;
  color:var(--amplify-colors-white) !important;
}

.DateTimeLabel{
  width:80vw !important;
  font-size:1em !important;
  color:var(--amplify-colors-white) !important;
}

@media screen and (max-width: 450px) {
.DateTimeLabel{
  width:80vw !important;
  font-size:0.75em !important;
  color:var(--amplify-colors-white) !important;
}
}

@media screen and (max-width: 450px) {
  .SelectField{
    width:80vw !important;
    --amplify-components-selectfield-font-size:0.75em !important;
    font-size:0.75em !important;
  }
}


.TextAreaField {
  --amplify-components-textareafield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
  color: var(--amplify-colors-grey) !important;
  font-family:ConigenReg !important;
}

@media screen and (max-width: 450px) {
  .TextAreaField {
    --amplify-components-fieldcontrol-font-size:0.8em !important;
  }
}

@media screen and (min-width: 451px) {
  .TextAreaField textarea {
    --amplify-components-fieldcontrol-font-size:1em !important;
    height: 40vh !important;
    width: 40vw !important;
    --amplify-components-fieldcontrol-height: 20vh !important;
  }
}

.DateTimePicketInput{
  color-scheme: dark;
  background-color: transparent !important;
  color: white !important;
  font-size: 1em !important;
  border-color:var(--amplify-colors-turquoise) !important;
  border-width:2px !important;
  width:20vw !important;
  height:4vh !important;
}

@media screen and (max-width: 450px) {
  .DateTimePicketInput {
    font-size: 0.75em !important;
    width:80vw !important;
    height:4vh !important;
  }
}
 
.MeetingSetCompleteText {
  font-size: 3em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-orange-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .MeetingSetCompleteText {
    font-size: 1em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-orange-light) !important;
    line-height: 2em !important;
  }
}

.MeetingSetCongratulationsText{
  font-size: 5em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-purple-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .MeetingSetCongratulationsText {
    font-size: 2em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-purple-light) !important;
    line-height: 2em !important;
  }
}