@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
  font-family: 'Mulish', sans-serif;
  font-size: 28px;
  color: #CCCBCB;
}


[data-amplify-theme="studioTheme"] {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  --amplify-colors-purple-dark:#16052b;  
}

.TableRowBackground {
  background-color: var(--amplify-colors-purple-dark) !important;
  opacity: 1 !important; 
  border-style: solid;
  border-width: 1px;
  padding: 3em !important;
  margin: 3em !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

@media screen and (max-width: 768px) {
  .TableRowBackground {
    width: 80vw !important;
  }
}

.EndLine {
  height: 3px !important;
  background: linear-gradient(to right, var(--amplify-colors-purple), var(--amplify-colors-turquoise), var(--amplify-colors-orange));
  filter: blur(2px);
  border-radius: 5px;
}

.EpisodeRowFlex {
  align-items: center;
  margin: 1em -1em;
}

.ImageActive {
  height: 25vh !important;
  width: 15vw !important;
  border-style: solid;
  border-color: var(--amplify-colors-turquoise) !important;
  border-width: 1px;
}


@media screen and (max-width: 768px) {
  .ImageActive {
    width: 15vw !important;
    height: 7vh !important;
    border-style: solid;
    border-color: var(--amplify-colors-turquoise) !important;
    border-width: 1px;
  }
}

.PodcastTitleDiv {
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
}



.PodcastTitle {
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 2em !important;
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .PodcastTitle {
    font-size: 1em !important;
    font-weight: 400 !important;
  }
}

.PodcastDescriptionDiv {
  padding: 0.25em !important;
  margin: 0.25em !important;
}

@media screen and (max-width: 768px) {
  .PodcastDescriptionDiv {
    padding: 0.1em !important;
    margin: 0.1em !important;
  }
}

.PodcastDescription {
  color: white !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

@media screen and (max-width: 768px) {
  .PodcastDescription {
    font-size: 0.75em !important;
    line-height: 1.2em !important;
  }
}


.Duration {
  color: var(--amplify-colors-orange-light) !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

@media screen and (max-width: 768px) {
  .Duration {
    font-size: 0.75em !important;
    line-height: 1.2em !important;
  }
}

.Date {
  color: var(--amplify-colors-purple-light) !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

@media screen and (max-width: 768px) {
  .Date {
    font-size: 0.75em !important;
    line-height: 1.2em !important;
  }
}

.FlexRightColumn {
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
  width: 70vw !important;
}


.Button {
  width:  20vw !important;
  text-decoration: none !important;
  height:5vh !important;
  border-width: 2px;
  color: var(--amplify-colors-black) !important; 
  border-color: var(--amplify-colors-turquoise-light) !important; 
  background-color: var(--clr)!important;
  background-image:   
    linear-gradient(180deg, 
      var(--clr2) var(--gradgap), 
      transparent calc(100% - var(--gradgap))
    )!important;
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  padding: 1.1em 1.5em;
  border-radius: 2em;
  border: none;    
  box-shadow: 
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  
  &::before, &::after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 5em;
  }

  &::before {
    background-image: 
      radial-gradient(ellipse, 
        hsla(var(--hue), 100%, 90%, .8) 20%,
        transparent 50%,
        transparent 200%
      ),
      linear-gradient(90deg, 
        hsl(0deg, 0%, 25%) -10%, 
        transparent 30%, 
        transparent 70%, 
        hsl(0deg, 0%, 25%) 110%
      );
    box-shadow: 
      inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
      inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
      inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: 200% 80%, cover;
    background-position: center 220%;
    mix-blend-mode: overlay;
    filter: blur(calc(var(--blur) * 0.5));
  }
  
  &::after {
    background: linear-gradient(
      180deg, 
      hsla(var(--hue2),100%,90%,.9), 
      hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
      transparent 80%
    );
    top: 0.075em;
    left: 0.75em;
    right: 0.75em;
    bottom: 1.4em;
    filter: blur(var(--blur));
    mix-blend-mode: screen;
  }
  
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: 
      0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
      0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
      0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
      0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
      inset 0 -2px 2px rgba(255,255,255,0.2);
    background-position: center calc( var(--gradoffset) - 0.75em );
  }  
}


@media screen and (max-width: 768px) {
  .Button {
    width: clamp(30vw, 50vw, 50vw) !important;
    height:5vh !important;
    font-size: 1rem!important;
    line-height: 1.1!important;
    letter-spacing: 0.1vw!important;
    font-weight: 20!important;
    font-family: Graphik, Arial, Helvetica, sans-serif!important;
  
    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color:var(--amplify-colors-black) !important;
    font-weight:800 !important;    
  }
}


.Button:hover{ 
  border-color: var(--amplify-colors-turquoise);
  background-color:var(--amplify-colors-orange);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
  color:var(--amplify-colors-black) !important;
  font-weight:800 !important;
}

.Button:focus{
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-orange);
     
}

.Button:disabled{ 
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-black);     
}


.Button1{
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-orange) !important;
}

.Button1:hover{ 
  border-color: var(--amplify-colors-turquoise) !important;
  background-color:var(--amplify-colors-purple-dark) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-purple-dark) !important;
  color:var(--amplify-colors-orange-light) !important;
  font-weight:800 !important;
}
