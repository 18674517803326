@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  .GridContainer {
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;;
  }

  
  @media screen and (max-width: 450px) {
  .GridContainer {
    position: relative !important;
    top: 10vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 1001px) {
  .GridContainer {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 60vw !important;
  }
}


  .Container {
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;;
  }

  
  @media screen and (max-width: 450px) {
  .Container {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 1001px) {
  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    width: 50vw !important;
  }
}



  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }

  .PhoneNumberField {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color:var(--amplify-colors-orange)
  
  }
  
  .PhoneNumberField:after {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise);
  
  }

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }



  .TextField{
    --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-grey) !important;
  }

  @media screen and (max-width: 450px) { 
    .TextField{
      --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
      color: var(--amplify-colors-grey) !important;
      font-size: 0.8em !important;
    }
  }

  .TextField input{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-grey) !important;
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
    font-size:1em !important;
    font-weight:500 !important;
  }

  @media screen and (max-width: 450px) {
    .Text{    
      --amplify-components-text-color: var(--amplify-colors-grey);
      padding-right: 2vw !important;
      font-size:0.5em !important;
    }
  }
  .TextUpdated{    
    --amplify-components-text-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-orange) !important;
    font-weight:500 !important;
  }

  
  @media screen and (max-width: 450px) {
    .TextUpdated{    
      --amplify-components-text-color: var(--amplify-colors-orange) !important;
      color: var(--amplify-colors-orange) !important;
      font-weight:500 !important;
    }  
  }


  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }

  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: row;
    justify-content: left;
    flex-wrap:wrap;
    align-items:center;
  }

  .ToolBarItem{
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    padding: 0.25vh !important;
    vertical-align: top;

  }

  .RedditTitleButtons {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-grey) !important;
    border-color:  transparent !important;
    padding: 100px 10px;
  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1em !important;
      height:  auto  !important;
      width:  30vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1.5em !important;
      height:  5vh  !important;
      width:  10vw  !important;
      padding: 0px 0px;
    }
  }

  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  
  
  .Instructions{
    padding-bottom: 3em !important;
  }


  .SMSOptInText{
    color: var(--amplify-colors-grey) !important;
    font-size: 18px !important;

  }

  .TextFieldLabel{
    
    --amplify-components-text-color: var(--amplify-colors-grey) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
  }

  @media screen and (max-width: 450px) {
    .TextFieldLabel{    
      color: var(--amplify-colors-grey) !important;
      --amplify-components-text-font-size: 0.8em !important;
      --amplify-internal-table-td-font-size: 0.8em !important;
      font-size:1em !important;
    }
  }

  @media screen and (min-width: 451px) {
    .TextFieldLabel{    
      color: var(--amplify-colors-grey) !important;
      --amplify-components-text-font-size: 0.8em !important;
      --amplify-internal-table-td-font-size: 0.8em !important;
      font-size:0.75em !important;
      width: 10vw !important;
    }
  }

  

  .TextFieldHeaderLabel{    
    color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width: 450px) {
    .TextFieldHeaderLabel{    
      font-size:0.5em !important;
    }
  }


  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    vertical-align: top;
  }
  
  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    --amplify-components-select-background-color:var(--amplify-colors-black) !important;
    --amplify-components-select-color:var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    width:30vw !important;
  }



  .SelectField option {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    --amplify-components-select-option-background-color:var(--amplify-colors-black) !important;
    color: var(--amplify-colors-grey)!important;
    height: 2vh !important;
  }


  .SelectField option:hover {
    --amplify-components-select-background-color:var(--amplify-colors-orange) !important;
  }

  

  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}


@media screen and (max-width: 450px) {

  .RhyteItAccountsTableSection{
    position:relative !important;
    top:45vh !important;
    left:3vw !important;
  }
}

@media screen and (min-width: 451px) {
.RhyteItAccountsTableSection{
  position:relative !important;
  top:20vh !important;
  left:3vw !important;
  width:90vw !important;
}
}


@media screen and (max-width: 450px) {
  .TableRow{
    height:1vh !important;

  }
}

@media screen and (max-width: 450px) {
  .MessageBox{
    position: relative !important;
    top: 5vh !important;
    font-size: 0.5em !important;
    color:#00CCCC !important;
  }
}

@media screen and (min-width: 451px) {
.MessageBox{
  position:relative !important;
  top:1vh !important;
  font-size: 1em !important;
  color:#00CCCC !important;
}
}




@media screen and (min-width: 451px) {
  .RadioGroupFieldContainer
  {
    position:relative !important;
    top:3vh !important;
  }
}

@media screen and (min-width: 451px) {
  .RadioGroupField {
    color: var(--amplify-colors-turquoise) !important;

  }
}
  
span {
  font-weight: 400 !important;
}

@media screen and (min-width: 451px) {
  .RadioOption {
    color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
    font-weight: 400 !important;
    --amplify-components-radio-button-background-color: var(--amplify-colors-grey) !important;
    --amplify-components-radio-button-border-color: var(--amplify-colors-orange) !important;

  }

  .RadioOption:checked {
    color: var(--amplify-colors-turquoise) !important;
  }  
}


.RowLabelColumn{
  width:10vw !important;
}


@media screen and (max-width: 450px) {
  .RowLabelColumn{
    width:30vw !important;
  }
}

.TextAreaField {
  --amplify-components-textareafield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
  color: var(--amplify-colors-grey) !important;
  font-family:ConigenReg !important
}

@media screen and (max-width: 450px) {
  .TextAreaField {
    --amplify-components-fieldcontrol-font-size:0.8em !important;
  }
}

@media screen and (min-width: 451px) {
  .TextAreaField textarea {
    --amplify-components-fieldcontrol-font-size:1.25em !important;
    height: 20vh !important;
    width: 30vw !important;
    --amplify-components-fieldcontrol-height: 20vh !important;
  }
}


.FormContainer { 
  position:relative !important;
  top:3vh !important;
}

.Card{
  background-color: black !important;
}

.FlexRow{
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
}