
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.SectionContainer{
  position: relative !important;
  z-index: 100 !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
  overflow: hidden !important;
}

@media screen and (max-width: 450px) {
  .SectionContainer {
    height:75vh !important;
  }
}


.Background {  
  position: absolute;  
  z-index: -800 !important;
  height: 100vh !important;
  width: 100vw !important;
}


.TextFlexColumn{
  position: relative !important;
  z-index: 700 !important;
  width:100vw !important;
  left:8vw !important;
  gap: 0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}



@media screen and (max-width: 450px) {
  .TextFlexColumn {
    width:90vw !important;
    left:1vw !important;
  }
}


.HeaderTextRow {
  position: relative !important;
  top:35vh !important;
  width:80vw !important;  
  gap:3vw !important;
  margin:1em !important;
  padding:1em !important;
  border-radius: 15px 50px !important; 
  background-color: var(--amplify-colors-black) !important;
  box-shadow: 5px 10px  5px var(--amplify-colors-darkgrey) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  opacity:0.8 !important;
}




@media screen and (max-width: 450px) {
  .HeaderTextRow {
    top:15vh !important;
    width: 90vw !important;
    gap:0.1vw !important;
    margin:0.01em !important;
    padding:0.01em !important;
  }
}


.HeaderSubTextRow{
  position: relative !important;
  top:35vh !important;
  left:8vw !important; 
  margin:1em !important;
  padding-top:1em !important;
  padding-bottom:1em !important;
  padding-left:3em !important;
  padding-right:3em !important;
  border-radius: 15px 50px !important; 
  background-color: var(--amplify-colors-black) !important;
  box-shadow: 5px 10px  5px var(--amplify-colors-darkgrey) !important;
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  opacity:0.8 !important;
}



@media screen and (max-width: 450px) {
  .HeaderSubTextRow {
    top:15vh !important;
    width: 90vw !important;
    gap:0.25vw !important;
    left:2vw !important; 
    padding-left:1em !important;
    padding-right:1em !important;
  }
}



.HeaderText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 400 !important;
  line-height: 1em !important;
  width: 50vw !important;
  color: var(--amplify-colors-turquoise-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



@media screen and (max-width: 450px) {
  .HeaderText {
    left: 1vw !important;
    padding-left:2em !important;
    font-size: 1em !important;
    width: 90vw !important;
  }
}

.HeaderSubText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-orange-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}




@media screen and (max-width: 450px) {
  .HeaderSubText {
    left: 1vw !important;
    font-size: 0.75em !important;
    width: 90vw !important;
  }
}

.SubSubText{
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 300 !important;
  line-height: 1em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-purple-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;

}

.MayanNumber{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  height:5vh !important;
  width:7vw !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  column-gap: 0em !important;
}

.MayanDot{
  position: relative !important;
  top:0vh !important;
  left:0vw !important; 
  font-family: Graphik, Arial, Helvetica, sans-serif;
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 4em !important;
  font-weight: 500  !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


@media screen and (max-width: 450px) {
  .MayanDot {
    font-size: 2em !important;
  }
}


.SBSMLogoTickerHorizontal{
  position:relative !important;
  z-index:200 !important;
  top:20vh !important;
  left:0vw !important;
}