@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

.BackgroundContainer{
  position:relative !important;
  top:0 !important;
  left:0 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

  .BackgroundAurora {  
    position: absolute !important;
    z-index: 1 !important;
    top:0 !important;
    left:0 !important;
    height: 100vh;
    width: 100vw;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  5px !important;
  }

.Logo3D {  
  position: absolute !important;  
  z-index: 2!important;  
  top:0 !important;
  left:0 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  10px !important;
}