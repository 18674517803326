@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
    font-family: 'Mulish', sans-serif;
    font-size: 28px;
    color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  


  .TextField{
    --amplify-components-text-color: var(--amplify-colors-purple-light)!important;
    --amplify-components-text-border-color: var(--amplify-colors-turquoise-light)!important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-orange-light)!important;
  }

    
  @media screen and (max-width: 450px)  {
    .TextField{
      --amplify-components-text-width: 20vw !important;
     font-size: 0.5em !important;
     color: var(--amplify-colors-purple-light)!important;
    }
  }

  @media screen and (min-width: 451px)  {
    .TextField{
      --amplify-components-text-width: 20vw !important;
      --amplify-components-text-color: var(--amplify-colors-purple-light)!important;
      --amplify-components-text-border-color: var(--amplify-colors-turquoise-light)!important;
      --amplify-components-text-focus-border-color: var(--amplify-colors-orange-light)!important;
      color: var(--amplify-colors-purple-light)!important;
    }
  }

  .TextFieldTitle {
    --amplify-components-text-color: var(--amplify-colors-purple-light)!important;
    --amplify-components-text-border-color: var(--amplify-colors-turquoise-light)!important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-orange-light)!important;
    color: var(--amplify-colors-purple-light)!important;
    width:30vw !important;
  }

  .TextFieldDateTime {
    --amplify-components-text-color: var(--amplify-colors-purple-light)!important;
    --amplify-components-text-border-color: var(--amplify-colors-turquoise-light)!important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-orange-light)!important;
    width:10vw !important;
  }

  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
  }


  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  


  .TableRowBackground {
    background-color: var(--amplify-colors-black) !important;
    width:50vw !important;    
  }

  .RedditTitleButtons {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-grey) !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    padding: 100px 10px;
  }
  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleSpan{
    padding: 100px 10px;
  }

  .ToolBar{
    padding: 20px 10px;
  }

  .HideStatusRow {
    visibility: collapse;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .ShowStatusRow {
    background-image: url("https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo2.svg");
    visibility: visible;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .TableRowDataStatus{
    color: var(--amplify-colors-grey) !important;  

  }    


  .TableRowData{
    color: var(--amplify-colors-grey) !important;  
    text-align: center !important;
    vertical-align: middle !important;
    border-top-style: solid;
    border-top-color: lightcoral !important;
    border-top-width: 2px;
    font-size:1em !important;
  } 


  @media screen and (min-width: 451px)  {
  .TableRowData{
    font-size:0.75em !important;
  } 
}

.TableRowDataBehindSchedule{
  color: var(--amplify-colors-black) !important;  
  --amplify-components-text-color: var(--amplify-colors-black) !important;  
  background-color: var(--amplify-colors-orange) !important;  
  text-align: center !important;
  vertical-align: middle !important;
  border-style: inset;
  border-color: red !important;
  border-width: 2px;
  font-size:1em !important;
} 


  
  .TableRowBackground:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .TableRowDataERROR{
    background-color: darkred !important;
    color: var(--amplify-colors-grey) !important;  
  }

  .TableRowDataERROR:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:2.2vw  !important;
  }

  .CustomFileUpload{
    border: 1px solid #ccc;
    display: inline-block;
    height: 3vh !important;
    width: 4vw !important; 
    padding: 0.75em 1.5em;
    text-align: center !important;
    cursor: pointer;       
    color: var(--amplify-colors-turquoise) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-orange) !important;
  }

  .CustomFileUpload:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .CustomFileUpload:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }

  .ImageCell {
    height:10vh !important;
    width: 5vw !important;
  }

  .PhotoImage {
    height:150px !important;
    width: 150px !important;
    --amplify-components-image-max-width: 150px !important;
  }

  .CheckboxFieldColumn {
    color: transparent !important;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    box-sizing: border-box;  
    height: 1em; 
    width: 1em; 
    font-size:1em !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  span {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1.5em !important;
    font-weight: 100 !important;
  }

  @media screen and (max-width: 900px) {

  
    [data-amplify-theme="studioTheme"]  {
      --amplify-colors-grey: #CCCBCA;
      --amplify-colors-darkgrey: #333333;
      --amplify-colors-black: #000000;
      --amplify-colors-turquoise: #00CCCC;
      --amplify-colors-orange: #CC6633;
      --amplify-colors-purple: #9999CC;
      --amplify-components-fieldcontrol-font-size:11px !important;
      --amplify-internal-table-td-font-size:11px !important;
    }
    
    .RhyteItTable {
      --amplify-components-table-width: 50vw !important;
    } 
    body {
        font-family: 'Graphik', Arial, Helvetica, sans-serif;  
        font-size: var(--amplify-components-fieldcontrol-font-size) !important;
        color: #CCCBCB;
    }
    
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    }
    
    .QueryTopicTextField{
        
      --amplify-components-text-color: var(--amplify-colors-grey) !important;
      --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
      --amplify-components-text-font-size: 11px !important;
    
    }

    .TableRowDataStatus{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    }    
  
  
    .TableRowData{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    } 
  
    
  }