@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
}

  .BackgroundContainer{
    position:absolute !important;
    top:0 !important;
    height:100vh !important;
    width:100vw !important;
    display: flex !important;
    z-index: 800 !important;
    flex-direction:row !important;
    justify-content:center !important;
    align-items:center !important;
    align-content:center !important;
    flex-wrap:nowrap !important;
    gap:0vw !important;
    border-color: transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
    background-color: var(--amplify-colors-black) !important;
  }
  
  
  .SectionContainer{
    position: relative !important;
    height:100vh !important;
    width:100vw !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  5px !important;
    overflow: hidden !important;
  }
  
  .Background {  
    position: absolute;  
    z-index: -900 !important;
    height: 100vh !important;
    width: 100vw !important;
  }
  
  
  .TextFlexColumn{
    position: relative !important;
    z-index: 700 !important;
    width:100vw !important;
    left:8vw !important;
    gap: 0vh !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  5px !important;
  }
  
  .HeaderTextRow {
    position: relative !important;
    top:35vh !important;
    width:80vw !important;   
    padding: 1em !important;
    gap:3vw !important;
    border-radius: 15px 50px !important; 
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
  
  .HeaderSubTextRow{
    position: relative !important;
    top:35vh !important;
    left:7vw !important; 
    margin:1em !important;
    padding-top:1em !important;
    padding-bottom:1em !important;
    padding-left:3em !important;
    padding-right:3em !important;
    border-radius: 15px 50px !important;
    border-color: transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
  
  
  .HeaderText {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 1.5em !important;
    font-weight: 600 !important;
    line-height: 1em !important;
    width: 50vw !important;
    color: var(--amplify-colors-turquoise-light) !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
  
  
  
  @media screen and (max-width: 450px) {
    .HeaderText {
      left: 1vw !important;
      font-size: 2em !important;
      width: 90vw !important;
    }
  }
  
  
  .HeaderSubText {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 3em !important;
    font-weight: 400 !important;
    line-height: 1.5em !important;
    left: 10vw !important;
    width: 60vw !important;
    -webkit-font-smoothing: antialiased;
    color: var(--amplify-colors-orange-light) !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
  
  
  
  
  @media screen and (max-width: 450px) {
    .HeaderSubText {
      left: 3vw !important;
      font-size: 0.5em !important;
      width: 90vw !important;
      line-height: 1.5em !important;
    }
  }
  
  .SubSubText{
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 1.5em !important;
    font-weight: 300 !important;
    line-height: 1em !important;
    left: 10vw !important;
    width: 60vw !important;
    -webkit-font-smoothing: antialiased;
    color: var(--amplify-colors-purple-light) !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  
  }
  
  .MayanNumber{
    font-family: Graphik, Arial, Helvetica, sans-serif;
    color: var(--amplify-colors-turquoise-light) !important;
    font-size: 3em !important;
    font-weight: 500  !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
  
  .Circle{
    position:relative !important;
    z-index:810 !important;
    display: flex !important;
    width: 10vw !important;
    height: 14vh !important;
    border-radius: 50% !important;
    background-color: var(--amplify-colors-orange-light)!important;
    align-content: center!important;
    justify-content: center!important;
    align-items: center!important;
  }
  
  .cursor{
    transform: translate3d(758px, 704px, 0px) scale(1) !important;
  }
  
  .cursorcircle{
    
    background-color: rgb(139, 139, 139) !important;
  }
  
  .ButtonText{
    font-size: 1em !important;
    color:  var(--amplify-colors-darkgrey)!important;
  }
  
  
  .BenefitText{
    color: var(--amplify-colors-purple-light)!important;
    font-size:2em !important; 
    font-weight:300 !important;
  }
  
  
  
  
  .Circle{
    position:relative !important;
    display: flex !important;
    z-index:999 !important;
    width: 15vw !important;
    height: 22vh !important;
    border-radius: 50% !important;
    box-shadow: 5px 10px var(--amplify-colors-orange);
    background-color: var(--amplify-colors-orange-light)!important;
    align-content: center!important;
    justify-content: center!important;
    align-items: center!important;
    background-image: url("https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/MayanCoin.png");
  }
  
  .ButtonText{
    font-size: 4em !important;
    font-weight: 500 !important;
    color:  var(--amplify-colors-orange-light)!important;
  }
  
  .BenefitDev{
    position:absolute !important;
    top: 0;
    left: 0;
    z-index:999 !important;
  }
  
