@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  
  
  .GridContainer {
    position: relative;
    left: 3vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;    
  }

  @media screen and (max-width: 450px) {
  .GridContainer {
    position:relative;
    left:3vw !important;
    padding: 0.25em;
    border-style: solid;
    border-width: 0px;
    border-color:transparent;
    display:flex;
    flex-direction:column; 
    background-color:transparent;  
  }
}


@media screen and (min-width: 451px) and (max-width: 2000px) {
  .GridContainer {
    position:relative !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}


  .h1 {
    font-family: Runalto;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Graphik;
      font-size: 1em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 80vw !important;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: ConigenReg;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: ConigenReg;
      font-size: 0.8em !important;
      color: var(--amplify-colors-purple);
    }
  }

  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    width: 10vw !important;
  }

  @media screen and (max-width: 450px) {
    .TextField{
      width: 50vw !important;
      font-size:0.75em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
    }    
  }




  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TextField{
      width: 20vw !important;
      font-size:1em !important;
      line-height:1em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
      --amplify-components-fieldcontrol-width:15vw !important;
    }    
  }

  @media screen and (min-width: 1001px) {
    .TextField{
      width: 20vw !important;
      font-size:1em !important;
      line-height:1em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
      --amplify-components-fieldcontrol-width:15vw !important;
      margin-right: 15em !important;
    }    
  }
  
  
  @media screen and (min-width: 451px) {
  .TextField input{
    --amplify-components-text-color: var(--amplify-colors-white) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
    background-color: var(--amplify-colors-black)     !important;
    font-size:1em !important;
    width:30vw !important;
  }

}

@media screen and (max-width: 450px) {
  .ToolBar{  
    padding-top:1em !important;
    width:90vw !important;
    height:10vh !important;
  }
}

  .Text{    
    --amplify-components-text-color: var(--amplify-colors-orange-light) !important;
    padding-right: 2vw !important;
    color: var(--amplify-colors-orange-light) !important;
  }
  
.Table{
  border-color: transparent !important;
  width:50vw !important;
}


@media screen and (max-width: 450px) {
  .Table{  
    width:90vw !important;
  }
}

.TableCell{
  border-color: transparent !important;
}

.TableRow{
  background-color: transparent!important;
  --amplify-components-table-row-hover-background-color: transparent!important;
}

.TableRow:hover{
  background-color: transparent!important;
  --amplify-components-table-row-hover-background-color: transparent!important;
}

  .Slider {
      --amplify-components-sliderfield-range-background-color: var(--amplify-colors-orange);      
      --amplify-components-sliderfield-track-background-color: var(--amplify-colors-turquoise);   
      --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-purple);    
      --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-grey); 
      --amplify-components-sliderfield-thumb-focus-border-color: var(--amplify-colors-orange); 
  }

  @media screen and (max-width: 450px) {
    .Slider {
      --amplify-components-sliderfield-track-min-width: 30vw !important;
      width:30vw !important;
    }
  }
@media screen and (max-width: 450px) {

  .MessageBox{
    position:relative !important;
    top: 2vh !important;
    font-size: 1em !important;
    width:90vw !important;
  }
}


@media screen and (min-width: 451px) {
.MessageBox{
  position:relative !important;
  top:5vh !important;
  font-size: 1em !important;
}
}



  
span {
  font-weight: 400 !important;
}


.metallicbutton {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:8vw !important;
  height:5vh !important;
  font-size: 1.25em !important;
  padding: 6px 16px;
  font-weight: 500;
  flex: 1 1 auto;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}




@media screen and (max-width: 450px) {
.metallicbutton {
  width:30vw !important;
  font-size: 0.70em !important;
}
}


@media screen and (min-width: 451px) and (max-width: 1500px) {
  .metallicbutton {
    width:20vw !important;
    font-size: 1em !important;
  }
}



@media screen and (min-width: 1501px) and (max-width: 2000px) {
    .metallicbutton {
      width:10vw !important;
      font-size: 1em !important;
    }
  }
  


