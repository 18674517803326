@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  
  }


    
  @media screen and (max-width: 450px) {
    .Table {
      --amplify-components-table-width: 90vw !important;
    } 

  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .Table {
      --amplify-components-table-width: 90vw !important;
    } 
  } 

  @media screen and (min-width: 1001px) {
      .Table {
        --amplify-components-table-width: 50vw !important;
      } 
    } 

  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }


  @media screen and (max-width: 450px) {
    .LineGraph {
      height:  60vh  !important;
      width:  90vw  !important;
      font-size:0.5em !important;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1500px) {
    .LineGraph {
      height:  60vh  !important;
      width:  90vw  !important;
      font-size:0.5em !important;
      margin-top: 10px;
    }
  }
  

  @media screen and (min-width: 1501px) {
    .LineGraph {
      height:  60vh  !important;
      width:  80vw  !important;
      margin-top: 10px;
    }
  }

  