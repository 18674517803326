

  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
}

.VideoSection{
  position:relative !important;
  left:3vw !important;

}

@media screen and (max-width: 450px) {  
.VideoTitle{
  font-size:1em !important;
  color: var(--amplify-colors-grey) !important;
}
}

@media screen and (min-width: 451px) and (max-width: 2000px)  {  
  .VideoTitle{
    font-size:1em !important;
    color: var(--amplify-colors-grey) !important;
  }
}

@media screen and (min-width: 2001px) {  
  .VideoTitle{
    font-size:1em !important;
    color: var(--amplify-colors-grey) !important;
  }
}

@media screen and (max-width: 450px) {  
.VideoTitleH3{
  font-size:0.75em !important;
  color: var(--amplify-colors-purple) !important;
  width: 90vw !important;
}
}


@media screen and (min-width: 451px) and (max-width: 2000px)  {  

  .VideoTitleH3{
    font-size:1em !important;
    color: var(--amplify-colors-purple) !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 2001px) {  

  .VideoTitleH3{
    font-size:1em !important;
    color: var(--amplify-colors-purple) !important;
    width: 90vw !important;
  }
}


.RhyteItProcessMapVideo {  
  position:relative !important;
  top:0vh !important;
  left:0vw !important;
  width:90vw !important;
  height:30vh !important;
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 1px;
}

@media screen and (min-width: 450px) {  
.RhyteItProcessMapVideo {  
  position:relative !important;
  top:0vh !important;
  left:0vw !important;
  width:30vw !important;
  height:30vh !important;
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 1px;
}
}