
  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.MenuItems{
  gap:0.75em !important;
  width:5vw !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;

}


@media screen and (max-width: 450px) {
.MenuItems{
  gap:0.25em !important;
}
}

.MenuItem{
  width:10vw !important;
  height:0.5vh !important;
}

.MenuItem:hover{
  transform: translateX(1vw) !important;
  transition-duration: 1s;
}
