@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
  font-family: 'Mulish', sans-serif;
  font-size: 28px;
  color: #CCCBCB;
}

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  


.Background{
  background-color: #f8f4e3 !important;
  background-image: 
    radial-gradient(circle, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
    radial-gradient(circle, rgba(255, 255, 255, 0.3) 1px, transparent 1px) !important;
  background-size: 20px 20px, 40px 40px !important;
  background-position: 0 0, 10px 10px !important;  
  min-height: 100vh !important;
}


h1{  
  color: var(--amplify-colors-turquoise-dark) !important;
}

@media (max-width: 768px) {
  h1{
    font-size: 15px !important;
  }
}


h3{  
  color: var(--amplify-colors-orange-dark) !important;
}

@media (max-width: 768px) {
  h3{
    font-size: 14px !important;
  }
}


p{
  color: var(--amplify-colors-black) !important;
}


@media (max-width: 768px) {
  p{
    font-size: 14px !important;
  }
}



li{
  color: var(--amplify-colors-purple-dark) !important;
}

.DateLine{
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: var(--amplify-colors-purple) !important;
}

.Date {
  color: var(--amplify-colors-purple-dark) !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 2em !important;
  letter-spacing: 0.1vw !important;
}
