
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.SectionContainer{
  position: relative !important;
  width:100vw !important;
  z-index: 100 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
  background-color: black !important;
}

.Circle{
  position: relative !important;
  z-index: 200 !important;
}

@media screen and (max-width: 450px) {
  .Circle{
    top:-12vh !important;
  }
}


.TextFlexColumn{
  position: absolute !important;
  z-index: 300 !important;
  top: 0 !important;
  left:10vw !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}



@media screen and (max-width: 450px) {
  .TextFlexColumn{
    width:90vw !important;
    top: -12vh !important;
  }
}


.Title{
  color:#c5b5a5 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  font-size: 11vw !important;
  font-weight: 400 !important;
  letter-spacing: -.15vw;
  text-decoration-line: none !important; 
  text-decoration-style: solid !important; 
  text-decoration-color:#c5b5a5 !important;
  text-decoration-thickness: 5rem !important;
  display: inline-block;
  padding-bottom:2px;
  background-image: linear-gradient(#c5b5a5 0 0);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition:
    background-size 0.7s,
    background-position 0s 0.7s; /*change after the size immediately*/

}


@media screen and (max-width: 450px) {
  .Title{
    font-size: 3rem !important;  
  }
}

.Title:hover{
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;
}

.Footer{
  position:fixed !important;
  bottom: 0vh !important;
  border-color:  lightgreen !important;
  border-style:  solid !important;
  border-width:  5px !important;
}