@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  .FlexRow{
    position:relative !important;
    top: 20vh !important;
    left:0vw !important;
    z-index:900 !important;
    width:300vw !important;   
    display:flex !important; 
    flex-wrap:wrap !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    row-gap:0.1rem !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }

  
 
  @media screen and (max-width: 450px) {
    .FlexRow{
      top: 1vh !important;
      width:800vw !important;
    }
  }


  .ImageContainer{
    width:6vw !important;
    height:7vh !important;
    padding: 1em !important;
    margin: 1em !important;
    align-content: center !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
    /* animation properties */
    -moz-transform: translateX(-3000%);
    -webkit-transform: translateX(-3000%);
    transform: translateX(-3000%);
    
    -moz-animation: my-animation 40s linear infinite;
    -webkit-animation: my-animation 40s linear infinite;
    animation: my-animation 40s linear infinite;
  }
 
  @media screen and (max-width: 450px) {
    .ImageContainer{
      width:10vw !important;
      height:20vh !important;
      padding: 0.1em !important;
      margin: 0.1em !important;
    }
  }
  
  

  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(-3000%); }
    to { -moz-transform: translateX(300%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(-3000%); }
    to { -webkit-transform: translateX(300%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(-3000%);
      -webkit-transform: translateX(-3000%);
      transform: translateX(-3000%);
    }
    to {
      -moz-transform: translateX(300%);
      -webkit-transform: translateX(300%);
      transform: translateX(300%);
    }
  }

  @media screen and (max-width: 450px) {
  .ImageContainer{
    width:5vw !important;
    height:7vh !important;
    transition: all  0.2s ease-out;
    padding: 1em !important;
    margin: 6em !important;
    text-align: right;
  
    /* animation properties */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    
    -moz-animation: my-animation 10s linear infinite;
    -webkit-animation: my-animation 10s linear infinite;
    animation: my-animation 10s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(100%); }
    to { -moz-transform: translateX(-3000%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(100%); }
    to { -webkit-transform: translateX(-3000%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(-3000%);
      -webkit-transform: translateX(-3000%);
      transform: translateX(-3000%);
    }
  }

}

  .PhotoImage {
    width:5vw !important;
    padding: 1em !important;
    opacity: 1 !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }

  @media screen and (max-width: 450px) {
  .PhotoImage {
    width:20vw !important;
  }
}

.Text{
  color:var(--amplify-colors-grey) !important;
  font-size: 1em !important;
  font-weight: 400 !important;
}