
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}


.Footer{
  position:relative;
  left: 3vw !important;
  bottom:0;
  height:10vh !important;
  width:70vw !important;
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
  color: var(--amplify-colors-grey) !important;
}



@media screen and (max-width: 450px) {
.Footer{
  padding-top:10vh !important;
  width:90vw !important;
}
}

.FooterCopyRight {
  align-items: left !important;
  font-size: 0.5em !important;
}

@media screen and (max-width: 450px) {
  .FooterCopyRight {
    align-items: left !important;
    font-size:  0.5em !important;
  }
}

@media screen and (max-width: 450px) {
  .FooterSMLink{
    text-decoration: none;
    color: var(--amplify-colors-grey) !important;
    font-size: 1em !important;
    font-weight: 500 !important;
  }
}


@media screen and (min-width: 451px) {
  .FooterSMLink{
    text-decoration: none;
    color: var(--amplify-colors-grey) !important;
    font-size: 2em !important;
    line-height: 2em !important;
    font-weight: 500 !important;
  }
}


.FooterSMLink:hover {
  color: var(--amplify-colors-purple) !important;
}

@media screen and (max-width: 450px) {
.Dots {
    color: var(--amplify-colors-purple) !important;
    font-size: 0.8em !important;
  }
}

@media screen and (min-width: 450px) {
  .Dots {
      color: var(--amplify-colors-turquoise) !important;
      font-size: 2em !important;
    }
  }
  

.MailIcon {
	margin: auto;
	width: 200px;
	max-width: 100%;
  color:aliceblue !important;
}

.PageLink {
  text-decoration: none;
  color: var(--amplify-colors-grey) !important;
  font-size: 1.5em !important;
  line-height: 2em !important;
  font-weight: 500!important;
}

@media screen and (max-width: 450px) {
.PageLink {
  font-size: 1em !important;
  line-height: 1em !important;
  font-weight: 500!important;
}
}


.PageLink:hover {
  color: var(--amplify-colors-purple) !important;
}

.TopFlexRow{
  position: relative !important;
  top: 2vh !important;
}

.TermsRow{  
  gap:2rem !important;
}

@media screen and (min-width: 451px) and (max-width: 2000px) {
  .TermsRow{  
    gap:1rem !important;
  }
}

.space{
  height:5vh !important;
}