@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
  font-family: 'Mulish', sans-serif;
  font-size: 28px;
  color: #CCCBCB;
}

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  


.Background{
  background-color: transparent!important;
  background-image: 
    radial-gradient(ellipse at top, var(--amplify-colors-grey), transparent),
    radial-gradient(ellipse at bottom, #f8f4e3, transparent) !important;
    padding:1rem !important;
  border-radius: 25% 10% !important;
  width:20vw !important;
}

@media (max-width: 768px) {
  .Background{
    width:75vw !important;
  }
}


.BackgroundSocialMedia{
  background-color: transparent !important;
  background-image: 
    radial-gradient(ellipse at top, var(--amplify-colors-orange-light), transparent),
    radial-gradient(ellipse at bottom, #f8f4e3, transparent) !important;
    padding:1rem !important;
  border-radius: 25% 10% !important;
  width:20vw !important;
}

@media (max-width: 768px) {
  .BackgroundSocialMedia{
    width:75vw !important;
  }
}


.BackgroundLegalEthics{
  background-color: transparent !important;
  background-image: 
    radial-gradient(ellipse at top, var(--amplify-colors-turquoise-light), transparent),
    radial-gradient(ellipse at bottom, #f8f4e3, transparent) !important;
    padding:1rem !important;
  border-radius: 25% 10% !important;
  width:20vw !important;
}


@media (max-width: 768px) {
  .BackgroundLegalEthics{
    width:75vw !important;
  }
}

.BackgroundMoneyBusiness{
  background-color: transparent !important;
  background-image: 
    radial-gradient(ellipse at top, #99f4ad, transparent),
    radial-gradient(ellipse at bottom, #f8f4e3, transparent) !important;
    padding:1rem !important;
  border-radius: 25% 10% !important;
  width:20vw !important;
}


@media (max-width: 768px) {
  .BackgroundMoneyBusiness{
    width:75vw !important;
  }
}

.BackgroundPersonalEmpowerment{
  background-color: transparent !important;
  background-image: 
    radial-gradient(ellipse at top, #FFB3D9, transparent),
    radial-gradient(ellipse at bottom, #f8f4e3, transparent) !important;
    padding:1rem !important;
  border-radius: 25% 10% !important;
  width:20vw !important;
}

@media (max-width: 768px) {
  .BackgroundPersonalEmpowerment{
    width:75vw !important;
  }
}

h1{  
  color: var(--amplify-colors-turquoise-dark) !important;
}

@media (max-width: 768px) {
  h1{
    font-size: 15px !important;
  }
}


h3{  
  color: var(--amplify-colors-orange-dark) !important;
}

@media (max-width: 768px) {
  h3{
    font-size: 14px !important;
  }
}


p{
  color: var(--amplify-colors-black) !important;
}


@media (max-width: 768px) {
  p{
    font-size: 14px !important;
  }
}



li{
  color: var(--amplify-colors-purple-dark) !important;
}

.DateLine{
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: var(--amplify-colors-purple) !important;
}

.Date {
  color: var(--amplify-colors-purple-dark) !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 2em !important;
  letter-spacing: 0.1vw !important;
}

.TextCategory{
  color: var(--amplify-colors-turquoise-dark) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 2em !important;
  letter-spacing: 0.1vw !important;
}


.TextTitle{
  color: var(--amplify-colors-orange-dark) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 2em !important;
  letter-spacing: 0.1vw !important;
}

.image{
  border-radius: 10px !important; /* Adjust the value for more or less rounding */
  display: block !important; /* Optional: Ensures proper spacing */
  max-width: 100% !important; /* Ensures image is responsive */
  height: auto !important;  
  width:10vw !important;
}

@media (max-width: 768px) {
  .image{
    width:50vw !important;
  }
}

.ImageLogo{
  display: block !important; /* Optional: Ensures proper spacing */
  max-width: 100% !important; /* Ensures image is responsive */
  height: auto !important;  
  width:6vw !important;

}