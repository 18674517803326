
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-black: #333333;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;

}

.StatusBox{  
  background-color: var(--amplify-colors-grey);
}

@media screen and (max-width: 450px)  {
.StatusMessageBox {
  --amplify-components-text-color: var(--amplify-colors-grey);
  font-size: 1em !important;
}
}

@media screen and (min-width: 451px)  {
.StatusMessageBox {
  --amplify-components-text-color: var(--amplify-colors-grey);
  font-size: 1em !important;
}
}
