@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
  }

  .Background{
    background-color: var(--amplify-colors-black) !important;
    background-size: 20px 20px, 40px 40px !important;
    background-position: 0 0, 10px 10px !important;  
    min-height: 200vh !important;
    width: 100vw !important;
  }

  .GridContainer { 
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
    font-weight:500 !important;
    max-width: 50vw !important;
    margin: 50px auto;
    background: var(--amplify-colors-darkgrey) !important;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 3px 3px var(--amplify-colors-grey), -1em 0 .4em var(--amplify-colors-darkgrey)!important;
  }

  
  @media screen and (max-width: 450px) {
  .GridContainer {
    position: relative !important;
    top: 15vh !important;
    left: 3vw !important;
    width: 90vw !important;
    gap:2rem !important;
  }
}

@media screen and (min-width: 451px) {
  .GridContainer {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 60vw !important;
    gap:3rem !important;
  }
}


  .Container {
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;;
  }

  
  @media screen and (max-width: 450px) {
  .Container {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 1001px) {
  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    width: 50vw !important;
  }
}



  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Runalto;
      font-size: 2em !important;
      color: var(--amplify-colors-turquoise);
      line-height: 1em !important;
    }
  }


  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @media screen and (min-width: 451px) {
    .headerline {
      height: 0.25vh;
      width: 50vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }


  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 90vw;
    }
  }

  
    @media screen and (min-width: 451px) {
    @keyframes dude {
      0% {
        width: 0;
      }
      100% {
        width: 50vw;
      }
    }
  }
  
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }

  .PhoneNumberField {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color:var(--amplify-colors-orange)
  
  }
  
  .PhoneNumberField:after {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise);
  
  }

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }


  .TextFieldProduct{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
  }

  @media screen and (max-width: 450px) {
    .TextFieldProduct{
      width: 80vw !important;
      font-size:1em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
    }    
  }

  
  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    width: 35vw !important;
  }

  @media screen and (max-width: 450px) {
    .TextField {
      width: 80vw !important;
      font-size:1em !important;
      line-height:1.5em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
    }    
  }


  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TextField{
      width: 50vw !important;
      font-size:1em !important;
      line-height:1em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
      --amplify-components-fieldcontrol-width:15vw !important;
    }    
  }

  @media screen and (min-width: 1001px) {
    .TextField{
      width: 35vw !important;
      font-size:1em !important;
      line-height:1em !important;
      --amplify-components-fieldcontrol-font-size:1em !important;
      --amplify-components-fieldcontrol-width:15vw !important;
    }    
  }
  
  .TextField input{
    --amplify-components-text-color: var(--amplify-colors-white) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
    background-color: var(--amplify-colors-black)     !important;
    font-size:1em !important;
    width:30vw !important;
  }

  @media screen and (max-width: 450px) {
    .TextField input {
      --amplify-components-text-color: var(--amplify-colors-white) !important;
      --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
      background-color: var(--amplify-colors-black)     !important;
      font-size:1em !important;
      width:90vw !important;
    }    
  }


  .InvalidEmailField input{
    --amplify-components-text-color: var(--amplify-colors-black) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple)     !important;        
    box-shadow: 0 30px 45px -15px var(--amplify-colors-purple);
    width:90vw !important;
  }

  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
    font-size:1em !important;
  }

  @media screen and (max-width: 450px) {
    .Text{    
      --amplify-components-text-color: var(--amplify-colors-grey);
      padding-right: 2vw !important;
      font-size:0.5em !important;
    }
  }

  
  @media screen and (max-width: 450px) {
    .TextUpdated{    
      --amplify-components-text-color: var(--amplify-colors-orange) !important;
      color: var(--amplify-colors-orange) !important;
      font-size:0.5em !important;
    }  
  }

  .TextUpdated{    
    --amplify-components-text-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-orange) !important;
    font-size:0.5em !important;
  }

  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }

  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: row;
    justify-content: left;
    flex-wrap:wrap;
    align-items:center;
  }

  .ToolBarItem{
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    padding: 0.25vh !important;
    vertical-align: top;

  }

  .RedditTitleButtons {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-grey) !important;
    border-color:  transparent !important;
    padding: 100px 10px;
  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1em !important;
      height:  auto  !important;
      width:  30vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1.5em !important;
      height:  5vh  !important;
      width:  10vw  !important;
      padding: 0px 0px;
    }
  }

  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  

  .TextFieldLabel{
    
    --amplify-components-text-color: var(--amplify-colors-grey) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
  }

  @media screen and (max-width: 450px) {
    .TextFieldLabel{    
      color: var(--amplify-colors-grey) !important;
      --amplify-components-text-font-size: 0.8em !important;
      --amplify-internal-table-td-font-size: 0.8em !important;
      font-size:1em !important;
    }
  }

  .TextFieldLabelThin{    
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
  }

  @media screen and (max-width: 450px) {
    .TextFieldLabelThin{    
      color: var(--amplify-colors-orange) !important;
      --amplify-components-text-font-size: 0.8em !important;
      --amplify-internal-table-td-font-size: 0.8em !important;
      font-size:1em !important;
      width:50vw !important;
    }
  }

  @media screen and (min-width: 451px) {
    .TextFieldLabelThin{    
      color: var(--amplify-colors-orange) !important;
      --amplify-components-text-font-size: 0.8em !important;
      --amplify-internal-table-td-font-size: 0.8em !important;
      font-size:1em !important;
    }
  }

  .TextFieldThin{    
    color: var(--amplify-colors-grey) !important;
    font-size:1em !important;
  }

  .TextFieldEmailInfo{    
    color: var(--amplify-colors-grey) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
    font-family: ConigenReg !important;
    font-size:1em !important;
  }

  .TextFieldHeaderLabel{    
    color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-font-size: 0.8em !important;
    --amplify-internal-table-td-font-size: 0.8em !important;
  }

  @media screen and (max-width: 450px) {
    .TextFieldHeaderLabel{    
      font-size:0.5em !important;
    }
  }

  .ButtonFlex{
    position:relative !important;
    top: 5vh !important;
    width:90vw !important;
    align-content: center !important;
  }

  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    vertical-align: top;
    font-size:2em !important;
    height: 10vh !important;
    width: 40vw !important;
    margin-left:25% !important;
  }

    @media screen and (min-width: 451px) {
    .RedditButtons {
      color: var(--amplify-colors-purple) !important;
      background-color: transparent !important;
      border-color:  var(--amplify-colors-turquoise) !important;
      vertical-align: top;
      font-size:2em !important;
      height: 7vh !important;
      width: 20vw !important;
      margin-left:0% !important;
    }
  }
  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  

  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
  }

  .SelectFieldOption {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }

  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}


@media screen and (max-width: 450px) {

  .RhyteItAccountsTableSection{
    position:relative !important;
    top:45vh !important;
    left:3vw !important;
  }
}

@media screen and (min-width: 451px) {
.RhyteItAccountsTableSection{
  position:relative !important;
  top:20vh !important;
  left:3vw !important;
  width:90vw !important;
}
}



@media screen and (max-width: 450px) {
  .Table{
    height: 30vh !important;
    width: 50vw !important;
  }
}

@media screen and (max-width: 450px) {
  .TableRow{
    height:0.25vh !important;
    width: 50vw !important;

  }
}

@media screen and (max-width: 450px) {
  .MessageBox{
    position: relative !important;
    top: 5vh !important;
    font-size: 0.5em !important;
  }
}

@media screen and (min-width: 451px) {
.MessageBox{
  position:relative !important;
  top:1vh !important;
  font-size: 1em !important;
  color:#00CCCC !important;
}
}

@media screen and (min-width: 451px) {
  .RadioGroupFieldContainer
  {
    position:relative !important;
    top:3vh !important;
  }
}

@media screen and (min-width: 451px) {
  .RadioGroupField {
    color: var(--amplify-colors-turquoise) !important;

  }
}
  
span {
  font-weight: 400 !important;
}

@media screen and (min-width: 451px) {
  .RadioOption {
    color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
    font-weight: 400 !important;
    --amplify-components-radio-button-background-color: var(--amplify-colors-grey) !important;
    --amplify-components-radio-button-border-color: var(--amplify-colors-orange) !important;

  }

  .RadioOption:checked {
    color: var(--amplify-colors-turquoise) !important;
  }  
}


.RowLabelColumn{
  width:10vw !important;
}


@media screen and (max-width: 450px) {
  .RowLabelColumn{
    width:80vw !important;
    height:0.5vh !important;
    line-height: 0.25vh !important;
  }
}

.RowLabelColumnThin{
  width:10vw !important;
}


@media screen and (max-width: 450px) {
  .RowLabelColumnThin{
    width:30vw !important;
    height:0.5vh !important;
    line-height: 0.25vh !important;
  }
}

.RowLabelColumnActions{
  width:10vw !important;
}


@media screen and (max-width: 450px) {
  .RowLabelColumnActions{
    width:90vw !important;
    height:0.5vh !important;
    line-height: 0.25vh !important;    
  }
}


.TextAreaField {
  --amplify-components-textareafield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
  color: var(--amplify-colors-grey) !important;
  font-family:Graphik !important;
  line-height: 2vh !important;
  border-color:transparent !important;
  border-style:solid !important;
  border-width: 2px !important;
  margin-left: 0vw !important;
}

@media screen and (max-width: 450px) {
  .TextAreaField {
    --amplify-components-fieldcontrol-font-size:0.5em !important;
    line-height: 4vh !important;
  }
}

@media screen and (min-width: 451px) {
  .TextAreaField {
    line-height: 3vh !important;
    width:60vw !important;
  }
}

.FormContainer { 
  position:relative !important;
  top:5vh !important;
}

.Card{
  background-color:var(--amplify-colors-black) !important;
  border-color:transparent !important;
  border-style:solid !important;
  border-width: 2px !important;
  position: relative !important;
  top: 30vh !important;
}

.CardEmail{
  background-color:var(--amplify-colors-black) !important;
  border-color:transparent !important;
  border-style:solid !important;
  border-width: 2px !important;
  position: relative !important;
  top: 30vh !important;
}

.CardDescription{
  border-color:transparent !important;
  border-style:solid !important;
  border-width: 2px !important;
  background-color:var(--amplify-colors-black) !important;
  height: 20vh !important;
}

.ProductImage{
  border-color:transparent !important;
  border-style:solid !important;
  border-width: 2px !important;
  width: 10vw !important;
}

@media screen and (max-width: 450px) {
  .ProductImage{
    width: 25vw!important;
  }
}

.Image{
  width:12vw !important;
}


@media screen and (max-width: 450px) {
  .Image{
    width: 45vw!important;
  }
}

.CustomerContainer{
  position: relative !important;
  top:60vh !important;
}

.FlexColumn {
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:50vh !important;
  width:50vw !important;
}

.FlexProductColumns{
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:80vh !important;
  width:50vw !important;

}

@media screen and (max-width: 450px) {
  .FlexProductColumns{
    height:90vh !important;
    width:90vw !important;
    padding-top:1vh !important;
  }
}

.FlexRowProductAttributes{
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:70vh !important;
  width:35vw !important;
}

@media screen and (max-width: 450px) {
.FlexRowProductAttributes{
  width:80vw !important;
  height:90vh !important;
}
}

.FlexRowDescription {
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:30vh !important;
  width:35vw !important;
}
@media screen and (max-width: 450px) {
  .FlexRowDescription{
    width:90vw !important;
    height:50vh !important;
  }
  }

.FlexRowPrice {
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:5vh !important;
  width:35vw !important;
}

@media screen and (max-width: 450px) {
  .FlexRowPrice{
    width:80vw !important;
    height:8vh !important;
  }
  }

.FlexRowEmail {
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:5vh !important;
  width:35vw !important;
}


@media screen and (max-width: 450px) {
  .FlexRowEmail{
    width:90vw !important;
    height:10vh !important;
    }
  }

  .FlexRow{
    border-style: solid;
    border-color: transparent !important;
    border-width:2px !important;
  }


.FlexRowBuyNow{
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:5vh !important;
  width:50vw !important;
}

@media screen and (max-width: 450px) {
  .FlexRowBuyNow{
    width:80vw !important;
    height:10vh !important;
    }
  }


.FlexRowCustomerApplause{
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  height:50vh !important;
  width:50vw !important;
}


@media screen and (max-width: 450px) {
  .FlexRowCustomerApplause{
    width:90vw !important;
    height:60vh !important;
  }
  }

.FlexRowFooter {
  height:10vh !important;
  width:60vw !important;
}

@media screen and (max-width: 450px) {
  .FlexRowFooter{
    width:90vw !important;
    height:30vh !important;
  }
  }

.HobnilTheBee{
  height:3vh;
  position: relative;
  animation-name: box;
  animation-duration: 10s;
  animation-iteration-count: 1;
  left: 3vw; 
  top:18vh;
}

@media screen and (max-width: 450px) {
.HobnilTheBee{
  --amplify-components-image-height:3vh !important;
}
}

@keyframes box {
  0%   { left: 5vw; top:-120vh;z-index: -100; transform: rotate(0deg);}
  20%  { left: 40vw; top:-120vh;z-index: 100;transform: rotate(30deg); background-color:transparent;}
  40%  {left: 10vw; top:-20vh;z-index: -100;transform: rotate(60deg); background-color:transparent;}
  60%  { left: 10vw; top:-15vh;z-index: 100;transform: rotate(-120deg);background-color:transparent;}
  70%  {left: 18vw; top:-30vh;z-index: 100;transform: rotate(180deg);}
  80%  {left: 18vw; top:10vh;z-index: 100;background-color:transparent;}
  90%  {left: 25vw; top:18vh;z-index: 100;}
  100%  { left: 3vw; top:18vh;z-index: 100;}
}


.HobnilTheBee:hover{
  position: relative;
  animation-name: shakeybee;
  animation-duration: 3s;
  animation-iteration-count: 1;
} 

@keyframes shakeybee {
  0% { transform: translateX(0) }
  10% { transform: translateX(5px) }
  20% { transform: translateX(-5px) }
  30% { transform: translateX(5px) }
  400% { transform: translateX(0) }
  50% { transform: translateX(0) }
  60% { transform: translateX(5px) }
  70% { transform: translateX(-5px) }
  80% { transform: translateX(5px) }
  90% { transform: translateX(0) }
  100% { transform: translateX(5px) }
}


.metallicbutton {
  border-color:  var(--amplify-colors-turquoise) !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:30vw !important;
  height:7vh !important;
  font-size: 1.25em !important;
  padding: 6px 16px;
  font-weight: 800;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 450px) {
  .metallicbutton{
    width:70vw !important;
    height:10vh !important;
    font-size: 2em !important;
    }
  }

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

.Space{
  height:5vh !important;
}

.ProductName{
  font-size: 3em !important;
  color: var(--amplify-colors-turquoise-light)!important;
}


.NextStepText {
  font-size: 3em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-orange-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .NextStepText {
    font-size: 1em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-orange-light) !important;
    line-height: 2em !important;
  }
}

.CongratulationsText{
  font-size: 4em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-purple-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .CongratulationsText {
    font-size: 1.5em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-purple-light) !important;
    line-height: 2em !important;
  }
}

.PleaseWaitText{
  font-size: 4em !important;
  font-weight: 600 !important;
  color:white !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .PleaseWaitText {
    font-size: 1.5em !important;
    font-weight: 600 !important;
    color:white !important;
    line-height: 2em !important;
  }
}

.HeroSectionHeadline{
  font-size: 1.5em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-orange-light) !important;
  line-height: 2em !important;

}


.HeroSectionSubheadline{
  font-size: 1.25em !important;
  font-weight: 400 !important;
  color:white !important;
  line-height: 2em !important;
}

.HeroFlexRow{
  position: relative !important;
  width:40vw !important;
}

.ProductImageContainer{
  position:relative !important;
  border-style: solid;
  border-color: transparent !important;
  border-width:2px !important;
  width:50vw !important;
}

.DividerHeader{
  width:40vw !important;
  --amplify-components-divider-border-color:var(--amplify-colors-purple-light) !important;
  --amplify-components-divider-label-color:white !important;
  padding-top:5vh !important;
}