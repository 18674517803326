@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  @media screen and (max-width: 450px) {
    .GridContainer {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .GridContainer {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }

  
  @media screen and (min-width: 1001px) {
  .GridContainer {
    position:relative !important;
    top: 1vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

  .Container {
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
  }

  
  @media screen and (max-width: 450px) {
  .Container {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .Container {
    position:relative !important;
    top: 25vh !important;
    left: 3vw !important;
    height: 30vh !important;
    width: 50vw !important;
  }
}


@media screen and (min-width: 1001px) {
  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    height: 30vh !important;
    width: 60vw !important;
  }
}


  .h1 {
    font-family: Runalto;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Graphik, sans-serif;
      font-size: 1.5em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .h1 {
      font-family: Runalto;
      font-size: 1em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 90vw;
    }
  }


  @media screen and (min-width: 1001px) {
  .headerline {
    height: 0.25vh;
    width: 60vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  
  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 60vw;
    }
  }
}
  
  .h2 {
    font-family: ConigenReg;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      line-height: 1.5em !important;
    }
  }


  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .h2 {
      font-family: ConigenReg;
      font-size: 0.5em !important;
      color: var(--amplify-colors-purple);
    }
  }

  @media screen and (max-width: 1001px) {
    .h2 {
      font-family: ConigenReg;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }


  .TextCell{
    border-color:transparent !important;
  }

  .TextFieldCell{
    border-color:transparent !important;
  }


  .TextField {
    width:50vw !important;
  }

  .TextField input{
    color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    font-size: 1em !important;
    font-family: ConigenReg !important;
    padding-right: 1vw !important;
    width:10vw !important;
  }
  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  
.TableRow{
  width:100vw !important;
  border-color:transparent !important;
  
}

.TextFieldCell{
  width:20vw !important;
}





@media screen and (max-width: 450px) {

  .MessageBox{
    position: relative !important;
    top: 2vh !important;
    font-size: 0.5em !important;
    width: 90vw !important;
  }
}


@media screen and (min-width: 451px) {
.MessageBox{
  position:relative !important;
  top:0vh !important;
  font-size: 1em !important;
}
}

