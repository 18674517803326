@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  }

.BackgroundContainer{
  position:relative !important;
  z-index:-999 !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;  
  top:0 !important;
  left:0 !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  background: linear-gradient(0deg, rgba(119,119,119,1) 0%, rgba(0,0,0,1) 45%);
  overflow: hidden !important;
}



.canvas{
  position:absolute !important;
  z-index:-925 !important;
  height:50vh !important;
  width:50vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;

}
