body {    
  background-color: #1f1f1f;
  font-family: Graphik, Arial, Helvetica, sans-serif;  
}

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}

.LandingPage{
  position: absolute;  
  z-index: -1000;  
  top:0 !important;
  left:0 !important;
  height: 200vh;
  width: 200vw;
  overflow: hidden !important;
  background-color: var(--amplify-colors-black) !important;
  border-color:  lightskyblue !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

.MenuBackground {  
  position: absolute;  
  z-index: -1000;  
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


.Background {  
  position: absolute;  
  z-index: -1500;  
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


.MasterContainer{
  position: relative;
  top: 3vh !important;
  left: 3vw !important;  
  gap:5rem !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .MasterContainer{
    gap:1rem !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
  }
}

@media screen and (max-width: 450px) {
  .ButtonRow{
    position: relative !important;
    left: 10vw !important;
    padding-top:7vh !important;
  }
}

.NegativeText{
  position: relative !important;
  background-color:transparent !important;
  opacity:0.85 !important;
  top: 0vh !important;
  left: 10vw !important;
  width: 60vw !important;
  text-align: left;
  transform: scale(0.94);
  font-family: ConigenExtBd, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 500 !important;
  margin:2vh !important;
  line-height: 1.5em !important;
}

.TextBackGround{
  position: relative !important;
  background-color:transparent !important;
  opacity:0.85 !important;
  top: 5vh !important;
  left: 10vw !important;
  width: 60vw !important;
  text-align: left;
  transform: scale(0.94);
  font-family: ConigenExtBd, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 500 !important;
  margin:2vh !important;
  line-height: 1.5em !important;
}



@media screen and (max-width: 450px) {
  .TextBackGround{
    position: relative !important;
    background-color:transparent !important;
    opacity:0.85 !important;
    top: 5vh !important;
    left: 10vw !important;
    width: 60vw !important;
    text-align: left;
    transform: scale(0.94);
    margin:2vh !important;
    line-height: 1.5em !important;
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.h1Blue {
  font-family: ConigenExtBd, Arial, Helvetica, sans-serif;
  font-size: 4em !important;
  line-height: 1em !important;
  font-weight: 800 !important;
  animation: scaleh1Blue 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  color: white !important;
}


@keyframes scaleh1Blue {
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 450px) {
  .h1Blue {
    left: 1vw !important;
    font-size: 2em !important;
    width: 90vw !important;
  }
}



.h1Orange {
  left: 1vw !important;
  font-family: ConigenExtBd, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  line-height: 1.75em !important;
  font-weight: 500 !important;
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  color: var(--amplify-colors-orange-light) !important;
}


@media screen and (max-width: 450px) {
  .h1Orange {
    left: 3vw !important;
    font-size: 0.5em !important;
    width: 90vw !important;
    line-height: 1.5em !important;
  }
}



.h1Turq {
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  color: var(--amplify-colors-turquoise-light) !important;
}



@media screen and (max-width: 450px) {
  .h1Turq {
    left: 3vw !important;
    font-size: 0.5em !important;
    width: 90vw !important;
    line-height: 1.5em !important;
  }
}

.h1Purple {
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  color: var(--amplify-colors-purple-light) !important;
}



@media screen and (max-width: 450px) {
  .h1Purple {
    left: 3vw !important;
    font-size: 0.5em !important;
    width: 90vw !important;
    line-height: 1.5em !important;
  }
}


.h2{
  position: relative;
  left: 13vw !important;
  line-height: 1.5em !important;
  color: white !important;
}

.headerline {
  position: relative;
  left: 10vw !important;
  height: 0.25vh;
  width: 20vw;
  background: var(--amplify-colors-purple);
  animation: dude 2s 1 forwards;
}

@media screen and (max-width: 450px) {
  .headerline {
    height: 0.25vh;
    background: var(--amplify-colors-purple);
    animation: dude 2s 1 forwards;
  }
}
@keyframes dude {
  0% {
    width: 0;
  }
  100% {
    width: 50vw;
  }
}

@media screen and (max-width: 450px) {
  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 90vw;
    }
  }
}

.Button {
  position: relative;
  left: 12vw !important;
}


@media screen and (max-width: 450px) {
.Button {
  position: relative;
  left: 3vw !important;
}
}

.ApplicationLink {
  text-decoration: none !important;
  color: var(--amplify-colors-white)!important;
}

@media screen and (max-width: 450px) {
.ApplicationLink {
  font-size: 0.7em !important;
}
}

.metallicbutton {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:40vw !important;
  height:20vh !important;
  font-size: 2em !important;
  margin: 6px !important;
  font-weight: 500;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

.Overview {
  position: relative;
  left: 3vw !important;
}

@media screen and (max-width: 450px) {
  .metallicbutton {
    font-size: 1.5em !important;
    width:80vw !important;
  }
}

.HeaderMenu{
  position:relative !important;
  left:50vw !important;
  z-index:1900 !important;
}

