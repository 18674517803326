@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
    --amplify-colors-turquoise-light: #b1f5f5;
    --amplify-colors-orange-light: #f4ba9d;
    --amplify-colors-purple-light: #cfcff5;
  }

 


  .GridContainer {
    position: relative !important;
    left:3vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;    
    background-color:transparent !important;
    z-index:900 !important;
  }

  .FlexRow{ 
    border-style: solid;
    border-width: 2px;
    border-color: transparent !important;
  }

  .FlexColumn{ 
    border-style: solid;
    border-width: 2px;
    border-color: transparent !important;
    width: 50vw;
  }

  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Runalto;
      font-size: 2em !important;
      color: var(--amplify-colors-turquoise);
      line-height: 1.5em !important;
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @media screen and (min-width: 451px) {
    .headerline {
      height: 0.25vh;
      width: 50vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }


  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 90vw;
    }
  }

  
    @media screen and (min-width: 451px) {
    @keyframes dude {
      0% {
        width: 0;
      }
      100% {
        width: 50vw;
      }
    }
  }
  
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }

  .TextField{
    color: var(--amplify-colors-grey) !important;
    font-weight: 500 !important;
    border-style: solid;
    border-width: 2px;
    border-color: transparent !important;
  }


  .TextField input{
    color: var(--amplify-colors-grey) !important;
    font-weight: 500 !important;
  }

  .TextField input:focus{
    border-style: solid;
    border-width: 2px;
    border-color: lightblue !important;
  }

  @media screen and (max-width: 450px) {
    .TextField{
      width: 50vw !important;
      font-size:1em !important;
      line-height:1.5em !important;
    }    
  }

  @media screen and (min-width: 451px) {
    .TextField{
      width: 30vw !important;
      font-size:1em !important;
      line-height:1em !important;
    }    
  }




  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
    font-size:1em !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width: 450px) {
    .Text{    
      --amplify-components-text-color: var(--amplify-colors-grey);
      padding-right: 2vw !important;
      font-size:0.5em !important;
    }
  }


  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }


  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
  }

  .SelectFieldOption {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }

  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}

.TextLabel{
  font-size: 1em !important;
  color: white !important;
  font-weight: 500 !important;
  width:15vw !important;
}

@media screen and (max-width: 450px) {
.TextLabel{
  font-size: 0.75em !important;
  color: white !important;
  font-weight: 500 !important;
  width:30vw !important;
}
}

.Button {
  color:white !important;
  background: transparent !important;
  border-color:var(--amplify-colors-orange) !important;
  --amplify-internal-button-color: var(--amplify-colors-grey) !important;
  --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
  height:5vh !important;
  font-size: 1em !important;
  width:10vw !important;
}

@media screen and (max-width: 450px) {
  .Button{
    height:4.5vh !important;
    width:40vw !important;
    font-size: 1em !important;
  }
}

.Button:hover {
  color:black !important;
  background:var(--amplify-colors-turquoise) !important;
  border-color:var(--amplify-colors-purple) !important;
  --amplify-internal-button-color: var(--amplify-colors-grey) !important;
  --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
  height:3vh !important;
}

.RhyteItHeaderMenu{
  padding-bottom: 3vh !important;
}

.H1Row{
  padding-top:5vh !important;

}

.MessageBox{
  color:var(--amplify-colors-orange-light) !important;
  font-size: 0.5 !important;
  font-weight: 500 !important;
}


.NextStepText {
  font-size: 2em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-orange-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .NextStepText {
    font-size: 1em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-orange-light) !important;
    line-height: 2em !important;
  }
}

.DataText {
  font-size: 1em !important;
  font-weight: 600 !important;
  color:white !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .DataText {
    font-size: 1em !important;
    font-weight: 600 !important;
    color:white !important;
    line-height: 2em !important;
  }
}

.CongratulationsText{
  font-size: 3em !important;
  font-weight: 600 !important;
  color:var(--amplify-colors-purple-light) !important;
  line-height: 2em !important;
}


@media screen and (max-width: 450px) {
  .CongratulationsText {
    font-size: 2em !important;
    font-weight: 600 !important;
    color:var(--amplify-colors-purple-light) !important;
    line-height: 2em !important;
  }
}