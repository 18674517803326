@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');



[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  



  .Background{
    /* Set up the background to cover the full page */
    background: linear-gradient(180deg, #330066, #FFCC99, #99FFFF) !important;
    background-size: 100% 100% !important;
    animation: auraFlow 4s ease infinite !important;
    min-height: 200vh !important;
    margin: 0 !important;
  }
  
  @keyframes auraFlow {
    0% {
      background-position: 0% 300%;
    }
    50% {
      background-position: 300% 50%;
    }
    100% {
      background-position: 50% 200%;
    }
  }

  @media screen and (max-width: 450px) {
    .GridContainer {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .GridContainer {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }

  
  @media screen and (min-width: 1001px) {
  .GridContainer {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
  }

  @media screen and (max-width: 450px) {  
    .Container {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
    }
  }
  
  



  @media screen and (min-width: 451px)and (max-width: 1000px) {  
  .Container {
    position:relative !important;
    top: 35vh !important;
    left: 3vw !important;
  }
}


.AuthenticatedUser{

  position:relative !important;  
  top: 0vh !important;
  font-size:1em !important;

}
  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }

  
@media screen and (max-width: 1000px) {  
  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 1em !important;
    color: var(--amplify-colors-turquoise);
  }
}


  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  
  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 1000px) {    
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }


  .PhoneNumberField {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color:var(--amplify-colors-orange)
  
  }
  
  .PhoneNumberField:after {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise);
  
  }

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }



  .TextField{
    --amplify-components-textfield-color: var(--amplify-colors-grey);
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise);
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple);
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-darkgrey);
    padding-right: 2vw !important;
  }
  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }

  @media screen and (max-width: 1000px) {
    .ToolBar{
      width:90vw !important; 
      justify-content: shrink !important;
    }
  }

  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap:wrap;
    align-items:stretch;
  }

  @media screen and (max-width: 1000px) {
    .ToolBarRow{
      width:90vw !important; 
      flex-direction: row;
      justify-content: space-around;
      flex-wrap:wrap;
      max-width: 90vw;
    }
  }

  .ToolBarItem{
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    padding: 0.25vh !important;
    vertical-align: top;
  }

  @media screen and (max-width: 1000px) {
    .ToolBarItem{
      border-style: solid;
      border-width: 1px;
      border-color:transparent  !important;
      padding: 0.2vw !important;
      vertical-align: top;
      width:auto !important;
    }
  }

  .RedditTitleButtons {
    color: var(--amplify-colors-orange) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    align-items:center;

  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      font-size: 0.5em !important;
      background-color: transparent !important;
      border-color:  var(--amplify-colors-turquoise) !important;
      align-items:center;
      width: auto !important;
      flex: 0 0 15vw;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
  .RedditTitleButtons {
    color: var(--amplify-colors-orange) !important;
    font-size: 0.5em !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    align-items:center;
    width: 15vw !important;
    flex: 0 0 15vw;
  }
}
  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }

  @media screen and (max-width: 1000px) {
  .amplifyautocomplete {
    width:auto !important;
    --amplify-components-field-font-size: 0.5em !important;
    --amplify-components-fieldcontrol-font-size: 0.2em !important;
    flex: 0 0 50vw;
    height:0.5vh !important;

  }
}
  
.amplify-autocomplete__menu{
  height:0.5vh !important;

}

.TableRowData{
  padding: 2vh !important;
}

@media screen and (max-width: 450px) {
  .TableRowData{
    position: relative !important;
    top: 4vh !important;
    padding: 0vh !important;
    font-size: 0.75em !important;
    height: auto !important;
    width: 90vw !important;
    border-color:  transparent !important;
    border-style:solid !important;
    border-width: 1px !important;
    align-items: left !important;
    vertical-align: middle !important;
  }
}

  .Instructions{
    padding-bottom: 3em !important;
  }


  .SMSOptInText{
    color: var(--amplify-colors-grey) !important;
    font-size: 18px !important;

  }

  .QueryTopicTextField{
    
    --amplify-components-textfield-color: var(--amplify-colors-grey) !important;
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple) !important;
    --amplify-components-textfield-font-size: 1rem !important;

  }

  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    vertical-align: top;
  }
  
  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  

  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:1rem !important;
    --amplify-components-selectfield-font-color: var(--amplify-colors-orange-light) !important;
    background-color: transparent!important;
    width:15vw !important;
    color: var(--amplify-colors-orange-light) !important;
    --amplify-components-select-background-color: transparent!important;
    --amplify-components-select-color: var(--amplify-colors-turquoise-light)!important;
  }


  .SelectField input::placeholder {
    color: var(--amplify-colors-turquoise-light)!important;
    --amplify-components-select-option-color: var(--amplify-colors-turquoise-light)!important;
    opacity: 1; /* Firefox */
  }

  
  .SelectField::placeholder {
    color: var(--amplify-colors-turquoise-light)!important;
    --amplify-components-select-option-color: var(--amplify-colors-turquoise-light)!important;
    opacity: 1; /* Firefox */
  }

  .SelectFieldOption {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:1rem !important;
    background-color: var(--amplify-colors-purple-dark)!important;
    color: var(--amplify-colors-orange-light)!important;
    --amplify-components-select-option-color: var(--amplify-colors-turquoise-light)!important;
  }

  .SelectFieldOption::placeholder {
    color: var(--amplify-colors-turquoise-light)!important;
    opacity: 1; /* Firefox */
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }
  


  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-searchfield-button-background-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: tranparent !important;
    color: var(--amplify-colors-black)!important;
    width:30vw !important;
    font-size: 0.8rem !important;
    --amplify-components-searchfield-color: var(--amplify-colors-black)!important;
  }
  
  .SearchField input {
    background-color: transparent !important;
    font-size: 0.8rem !important;
    color: var(--amplify-colors-orange-light)!important;
  }

  .SearchField input::placeholder {
    color: var(--amplify-colors-turquoise-light)!important;
    opacity: 1; /* Firefox */
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}

.ProfileTableSection{
  position:relative !important;
  top:15vh !important;
  left:3vw !important;
}

@media screen and (max-width: 450px) {
  .ProfileTableSection{
    position:relative !important;
    top:20vh !important;
    left:3vw !important;
    width: 90vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent  !important;
  }
  }
  

@media screen and (min-width: 451px){
.ProfileTableSection{
  position:relative !important;
  top:15vh !important;
  left:3vw !important;
  width: 90vw !important;
  border-style: solid;
  border-width: 1px;
  border-color:transparent  !important;
}
}

.Button {
  text-decoration: none !important;
  --amplify-components-button-font-size:1rem !important;
  height: 3vh !important;
  border-width: 2px;
  color: var(--amplify-colors-black) !important;
  border-color: var(--amplify-colors-turquoise-light) !important;
  background-color: var(--clr);
  background-image:
    linear-gradient(180deg,
      var(--clr2) var(--gradgap),
      transparent calc(100% - var(--gradgap)));
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  border-radius: 2em;
  border: none;
  box-shadow:
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46),
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  margin-right: 1rem !important;

  &::before, &::after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 5em;
  }

  &::before {
    background-image:
      radial-gradient(ellipse,
        hsla(var(--hue), 100%, 90%, .8) 20%,
        transparent 50%,
        transparent 200%),
      linear-gradient(90deg,
        hsl(0deg, 0%, 25%) -10%,
        transparent 30%,
        transparent 70%,
        hsl(0deg, 0%, 25%) 110%);
    box-shadow:
      inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
      inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
      inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: 200% 80%, cover;
    background-position: center 220%;
    mix-blend-mode: overlay;
    filter: blur(calc(var(--blur) * 0.5));
  }

  &::after {
    background: linear-gradient(180deg,
        hsla(var(--hue2), 100%, 90%, .9),
        hsla(var(--hue2), calc(var(--sat2)*0.7), 50%, .75) 40%,
        transparent 80%);
    top: 0.075em;
    left: 0.75em;
    right: 0.75em;
    bottom: 1.4em;
    filter: blur(var(--blur));
    mix-blend-mode: screen;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow:
      0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
      0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
      0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%),
      0 0.25em 0.5em hsla(var(--hue), 20%, 30%, 0.2),
      inset 0 -2px 2px rgba(255, 255, 255, 0.2);
    background-position: center calc(var(--gradoffset) - 0.75em);
  }
}


@media screen and (max-width: 450px) {
  .Button {
    width: clamp(20vw, 20vw, 20vw) !important;
    height: 5vh;
    margin-top: 2vh;
    border-width: 2px;
    border-radius: 0;
    border-color: var(--amplify-colors-turquoise);
    background-color: transparent;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-weight: 20;
    font-family: Graphik, Arial, Helvetica, sans-serif;

    --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color: var(--amplify-colors-white) !important;
    font-weight: 800 !important;
  }
}


.Button:hover {
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-orange);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
  color: var(--amplify-colors-black) !important;
  font-weight: 800 !important;
}

.Button:focus {
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-turquoise) !important;
  color: var(--amplify-colors-black);
}

.Button:disabled {
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color: var(--amplify-colors-black);
}

.SubRowDiv{
  width: 100vw !important;
}

.TableCellDetails{
  width: 100vw !important;
}

.ErrorMessage{
  color: var(--amplify-colors-orange-light) !important;

}

.TextLoading{    
  --amplify-components-text-color: var(--amplify-colors-purple-light) !important;
  padding-right: 2vw !important;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}