
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}

.AuthenticatedUser {
  color: var(--amplify-colors-orange) !important;
  font-family: var(--amplify-fonts-default-variable)!important;
  font-weight:1 !important;
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  border-width: 1px;
}

@media screen and (max-width: 450px) {  
  .AuthenticatedUserText {
    font-size: 0.5em !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {  
  .AuthenticatedUserText {
    font-size: 0.8em !important;
    width:90vw !important;
  }
}

@media screen and (min-width: 1001px) {  
  .AuthenticatedUserText {
    font-size: 1em !important;
    width:30vw !important;
  }
}

.logoName{  
  position:relative !important;
  font-family: Runalto;
  font-size: 5em !important;
  font-weight: 600 !important;
  color: var(--amplify-colors-turquoise);  
  text-decoration: none !important;
  width:12vw !important;
  opacity: 1 !important;
}

@media screen and (max-width: 450px) { 
  .logoName{
    font-size: 2em !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .logoName{
    font-size: 2em !important;
  }
}


.TextUpperCase{
  font-family: BeautyLuxury !important;
  color: var(--amplify-colors-grey) !important;
  font-size: 1em !important;
}


@media screen and (max-width: 450px) {  
  .TextUpperCase{
    font-size: 1em !important;
  }
}


.TextLowerCase
{
  font-family: Runalto !important;
  text-transform: lowercase !important;
  background-image: linear-gradient(
    -225deg,
    var(--amplify-colors-orange) 0%,
    var(--amplify-colors-turquoise) 29%,
    var(--amplify-colors-purple) 67%,
    var(--amplify-colors-grey) 100%
  ) !important;
  background-size: auto auto !important;
  background-clip: border-box !important;
  background-size: 200% auto !important;
  --amplify-components-text-color: #fff !important;
  color: #fff;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  animation: textclip 2s linear infinite !important;
  display: inline-block !important;
  font-size: 1cap !important;
}


@media screen and (max-width: 450px) {  
  .TextLowerCase{
    font-size: 1em !important;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.logo {
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  border-width: 1px;
  vertical-align: middle;
  align-items: center;
}

@media screen and (max-width: 450px) {  
.logo {
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  border-width: 1px;
  vertical-align: middle;
  align-items: left;
}
}

@media screen and (min-width: 451px)  and (max-width: 900px) {
  .logo {
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    border-width: 1px;
    vertical-align: middle;
    align-items: left;
  }
  }


.logoimgName{
  height:10vh;
  width:10vw;
}

.logoimg{
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
}


@media screen and (max-width: 450px) { 
  .logoimg{
    height:5vh;
    width:10vw;
  }
}

@media screen and (min-width: 451px) {
  .logoimg{
    height:6vh;
  }
}

.LogoRow{
  background-color: transparent !important;
  opacity: 0.9 !important;
  width:20vw !important;
  align-content: center !important;
}