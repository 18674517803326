@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');



[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  @media screen and (max-width: 450px) {
    .GridContainer {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .GridContainer {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }

  
  @media screen and (min-width: 1001px) {
  .GridContainer {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}


  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }

  
@media screen and (max-width: 1000px) {  
  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 1em !important;
    color: var(--amplify-colors-turquoise);
  }
}


  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  
  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 1000px) {    
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }


  .PhoneNumberField {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color:var(--amplify-colors-orange)
  
  }
  
  .PhoneNumberField:after {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise);
  
  }

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }



  .TextField{
    --amplify-components-textfield-color: var(--amplify-colors-grey);
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise);
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple);
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
  }
  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }

  @media screen and (max-width: 1000px) {
    .ToolBar{
      width:90vw !important; 
      justify-content: shrink !important;
    }
  }

  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap:wrap;
    align-items:stretch;
  }

  @media screen and (max-width: 1000px) {
    .ToolBarRow{
      width:90vw !important; 
      flex-direction: row;
      justify-content: space-around;
      flex-wrap:wrap;
      max-width: 90vw;
    }
  }

  .ToolBarItem{
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    padding: 0.25vh !important;
    vertical-align: top;
  }

  @media screen and (max-width: 1000px) {
    .ToolBarItem{
      border-style: solid;
      border-width: 1px;
      border-color:transparent  !important;
      padding: 0.2vw !important;
      vertical-align: top;
      width:auto !important;
    }
  }

  .RedditTitleButtons {
    color: var(--amplify-colors-orange) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    align-items:center;

  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      font-size: 0.5em !important;
      background-color: transparent !important;
      border-color:  var(--amplify-colors-turquoise) !important;
      align-items:center;
      width: auto !important;
      flex: 0 0 15vw;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
  .RedditTitleButtons {
    color: var(--amplify-colors-orange) !important;
    font-size: 0.5em !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    align-items:center;
    width: 15vw !important;
    flex: 0 0 15vw;
  }
}
  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }

  @media screen and (max-width: 1000px) {
  .amplifyautocomplete {
    width:auto !important;
    --amplify-components-field-font-size: 0.5em !important;
    --amplify-components-fieldcontrol-font-size: 0.2em !important;
    flex: 0 0 50vw;
    height:0.5vh !important;

  }
}
  
.amplify-autocomplete__menu{
  height:0.5vh !important;

}

.TableRowData{
  padding: 2vh !important;
}

@media screen and (max-width: 450px) {
  .TableRowData{
    position: relative !important;
    top: 4vh !important;
    padding: 0vh !important;
    font-size: 0.75em !important;
    height: auto !important;
    width: 90vw !important;
    border-color:  transparent !important;
    border-style:solid !important;
    border-width: 1px !important;
    align-items: left !important;
    vertical-align: middle !important;
  }
}

  .Instructions{
    padding-bottom: 3em !important;
  }


  .SMSOptInText{
    color: var(--amplify-colors-grey) !important;
    font-size: 18px !important;

  }

  .QueryTopicTextField{
    
    --amplify-components-textfield-color: var(--amplify-colors-grey) !important;
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple) !important;
    --amplify-components-textfield-font-size: 1rem !important;

  }

  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
    vertical-align: top;
  }
  
  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  

  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
  }

  .SelectFieldOption {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }

  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}

.ProfileTableSection{
  position:relative !important;
  top:15vh !important;
  left:3vw !important;
}

@media screen and (max-width: 450px) {
  .ProfileTableSection{
    position:relative !important;
    top:20vh !important;
    left:3vw !important;
    width: 90vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent  !important;
  }
  }
  

@media screen and (min-width: 451px) and (max-width: 1000px) {
.ProfileTableSection{
  position:relative !important;
  top:45vh !important;
  left:3vw !important;
  width: 90vw !important;
  border-style: solid;
  border-width: 1px;
  border-color:transparent  !important;
}
}
