@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
  font-family: 'Mulish', sans-serif;
  font-size: 28px;
  color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  

.TableRowBackground {
  background: linear-gradient(180deg, #330066, #FFCC99, #99FFFF) !important;
  opacity: 0.8 !important;
  border-style: solid;
  border-color: transparent !important;
  border-width: 1px;
  padding: 3em !important;
  margin: 3em !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}



@media screen and (max-width: 450px) {
  .TableRowBackground {
    background-color: transparent !important;
    width: 40vw !important;
    border-color: var(--amplify-colors-turquoise) !important;
  }
}

.EndLine {
  height: 3px !important;
  background: linear-gradient(to right, var(--amplify-colors-purple), var(--amplify-colors-turquoise), var(--amplify-colors-orange));
  filter: blur(2px);
  border-radius: 5px;
}

.EpisodeRowFlex {
  align-items: center;
  margin: 1em -1em;
}

.ImageActive {
  height: 25vh !important;
  width: 15vw !important;
  border-style: solid;
  border-color: var(--amplify-colors-turquoise) !important;
  border-width: 1px;
}


@media screen and (max-width: 450px) {
  .ImageActive {
    width: 30vw !important;
    height: 15vh !important;
    border-style: solid;
    border-color: var(--amplify-colors-turquoise) !important;
    border-width: 1px;
  }
}

.PodcastTitleDiv {
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
}

.PodcastTitle {
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 2em !important;
  font-weight: 600 !important;
}

.PodcastDescriptionDiv {
  padding: 0.25em !important;
  margin: 0.25em !important;
}

.PodcastDescription {
  color: white !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

.Duration {
  color: var(--amplify-colors-orange-light) !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

.Date {
  color: var(--amplify-colors-purple-light) !important;
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 3em !important;
  letter-spacing: 0.1vw !important;
}

.FlexRightColumn {
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
  width: 70vw !important;
}

.Button {
  text-decoration: none !important;
  height: 3vh !important;
  border-width: 2px;
  color: var(--amplify-colors-black) !important;
  border-color: var(--amplify-colors-turquoise-light) !important;
  background-color: var(--clr);
  background-image:
    linear-gradient(180deg,
      var(--clr2) var(--gradgap),
      transparent calc(100% - var(--gradgap)));
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  border-radius: 2em;
  border: none;
  box-shadow:
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46),
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  margin-right: 1rem !important;

  &::before, &::after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 5em;
  }

  &::before {
    background-image:
      radial-gradient(ellipse,
        hsla(var(--hue), 100%, 90%, .8) 20%,
        transparent 50%,
        transparent 200%),
      linear-gradient(90deg,
        hsl(0deg, 0%, 25%) -10%,
        transparent 30%,
        transparent 70%,
        hsl(0deg, 0%, 25%) 110%);
    box-shadow:
      inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
      inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
      inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: 200% 80%, cover;
    background-position: center 220%;
    mix-blend-mode: overlay;
    filter: blur(calc(var(--blur) * 0.5));
  }

  &::after {
    background: linear-gradient(180deg,
        hsla(var(--hue2), 100%, 90%, .9),
        hsla(var(--hue2), calc(var(--sat2)*0.7), 50%, .75) 40%,
        transparent 80%);
    top: 0.075em;
    left: 0.75em;
    right: 0.75em;
    bottom: 1.4em;
    filter: blur(var(--blur));
    mix-blend-mode: screen;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow:
      0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
      0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
      0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%),
      0 0.25em 0.5em hsla(var(--hue), 20%, 30%, 0.2),
      inset 0 -2px 2px rgba(255, 255, 255, 0.2);
    background-position: center calc(var(--gradoffset) - 0.75em);
  }
}


@media screen and (max-width: 450px) {
  .Button {
    width: clamp(20vw, 20vw, 20vw) !important;
    height: 5vh;
    margin-top: 2vh;
    border-width: 2px;
    border-radius: 0;
    border-color: var(--amplify-colors-turquoise);
    background-color: transparent;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-weight: 20;
    font-family: Graphik, Arial, Helvetica, sans-serif;

    --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color: var(--amplify-colors-white) !important;
    font-weight: 800 !important;
  }
}


.Button:hover {
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-orange);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
  color: var(--amplify-colors-black) !important;
  font-weight: 800 !important;
}

.Button:focus {
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-turquoise) !important;
  color: var(--amplify-colors-black);
}

.Button:disabled {
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color: var(--amplify-colors-black);
}

.TextFieldTitle{
  font-size: 1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  color: var(--amplify-colors-grey) !important;

}


.Text{  
  font-size:1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  color: var(--amplify-colors-grey) !important;
}

.TextUpdate{
  font-size:1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  color: var(--amplify-colors-orange-light) !important;
}


.TextField input{  
  font-size:1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  color: var(--amplify-colors-grey) !important;
}


.SubTextField{  
  font-size:1rem !important;
  font-weight: 400 !important;
  letter-spacing: 0.1rem !important;
  font-family: Graphik, Arial, Helvetica, sans-serif !important;
  color: var(--amplify-colors-turquoise-light) !important;
}

.TableRowActive{
  background-color: transparent !important;
  border-color: transparent !important;
}

.TableRowActive:hover{
  background-color: var(--amplify-colors-black) !important;
}

.TableRowInActive{
  background-color:  var(--amplify-colors-grey)  !important;
  color: var(--amplify-colors-black) !important;
}
.TableRowInActive:hover{
  background-color:  #b2b1b0  !important;
}


.TableCell{
  border-bottom-color:var(--amplify-colors-darkgrey) !important;
  border-top-color:var(--amplify-colors-darkgrey) !important;
  border-right-color:var(--amplify-colors-darkgrey) !important;
  border-left-color:var(--amplify-colors-darkgrey) !important;
  text-align: center!important;
}

.TableCell div{
  text-align: center!important;
  align-items: center!important;
}

.TableCellLeftAlign{
  border-bottom-color:var(--amplify-colors-orange-light) !important;
  border-top-color:transparent !important;
  border-right-color:transparent !important;
  border-left-color:transparent !important;
  text-align: left !important;
}



.TableCell:hover{
  border-bottom-color:var(--amplify-colors-orange-light) !important;
  border-top-color:var(--amplify-colors-orange-light) !important;
}

.TemplateRow{

  margin-top:2vh !important;

}

.CheckBox{    
  cursor: var(--amplify-colors-turquoise);
  --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
  --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
  --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
  --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
  --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
  --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
}

.TextAreaField{
  --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
  --amplify-components-fieldcontrol-font-size: 1rem !important;
  --amplify-components-textareafield-border-color:transparent !important;
  --amplify-components-textareafield-border-top-color:var(--amplify-colors-orange-light) !important;
}

.ButtonSection {
  text-decoration: none !important;
  height: 3vh !important;
  width: 10vw !important;
  font-size: 0.75rem !important;
  border-width: 2px;
  color: var(--amplify-colors-black) !important;
  border-color: var(--amplify-colors-turquoise-light) !important;
  background-color: var(--clr);
  background-image:
    linear-gradient(180deg,
      var(--clr2) var(--gradgap),
      transparent calc(100% - var(--gradgap)));
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  border-radius: 2em;
  border: none;
  box-shadow:
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46),
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  margin-right: 1rem !important;

  &::before, &::after {
    content: "";
    inset: 0;
    position: absolute;
    border-radius: 5em;
  }

  &::before {
    background-image:
      radial-gradient(ellipse,
        hsla(var(--hue), 100%, 90%, .8) 20%,
        transparent 50%,
        transparent 200%),
      linear-gradient(90deg,
        hsl(0deg, 0%, 25%) -10%,
        transparent 30%,
        transparent 70%,
        hsl(0deg, 0%, 25%) 110%);
    box-shadow:
      inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
      inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
      inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: 200% 80%, cover;
    background-position: center 220%;
    mix-blend-mode: overlay;
    filter: blur(calc(var(--blur) * 0.5));
  }

  &::after {
    background: linear-gradient(180deg,
        hsla(var(--hue2), 100%, 90%, .9),
        hsla(var(--hue2), calc(var(--sat2)*0.7), 50%, .75) 40%,
        transparent 80%);
    top: 0.075em;
    left: 0.75em;
    right: 0.75em;
    bottom: 1.4em;
    filter: blur(var(--blur));
    mix-blend-mode: screen;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow:
      0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
      0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
      0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%),
      0 0.25em 0.5em hsla(var(--hue), 20%, 30%, 0.2),
      inset 0 -2px 2px rgba(255, 255, 255, 0.2);
    background-position: center calc(var(--gradoffset) - 0.75em);
  }
}


@media screen and (max-width: 450px) {
  .ButtonSection {
    width: clamp(20vw, 20vw, 20vw) !important;
    height: 5vh;
    margin-top: 2vh;
    border-width: 2px;
    border-radius: 0;
    border-color: var(--amplify-colors-orange);
    background-color: transparent;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-weight: 20;
    font-family: Graphik, Arial, Helvetica, sans-serif;

    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color: var(--amplify-colors-white) !important;
    font-weight: 800 !important;
  }
}


.ButtonSection:hover {
  border-color: var(--amplify-colors-orange) !important;
  background-color: var(--amplify-colors-purple) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  color: var(--amplify-colors-black) !important;
  font-weight: 800 !important;
}

.ButtonSection:focus {
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-turquoise) !important;
  color: var(--amplify-colors-black);
}

.ButtonSection:disabled {
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color: var(--amplify-colors-black);
}