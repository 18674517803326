body {    
  background-color:transparent !important;
}


.RhyteItBlobCanvasDiv{
  position:absolute;
  z-index:-100;
  height:100vh;
  width:100vw;

}

.Canvas{
  position:absolute;
  top:5vh;
  left: 20vw !important;
  height:50vh;
  width:50vw;    
}


@media screen and (max-width: 450px) {
  .Canvas{

        position:absolute;
        top:10vh;
        left: 0vw;  
        border-color: transparent;
        border-width: 2px;
        border-style: solid;
        height:100vh;
        width:100vw;    
    }
}


.RhyteItBlobCanvas{
  position:relative;
  top:10vh;
  left: 20vw;
  height:50vh;
  width:50vw;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;

}

@media screen and (max-width: 450px) {
.RhyteItBlobCanvas{
  position:relative;
  top:0vh;
  left: 20vw;
  height:25vh;
  width:100vw;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
}  
}


@media screen and (min-width: 451px)  and (max-width: 900px) {

  .RhyteItBlobCanvas{
    position:relative;
    top:5vh;
    left: 60vw;
    height:25vh;
    width:25vw;
    border-color: transparent;
    border-width: 2px;
    border-style: solid;
  
  }

}