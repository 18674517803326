@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;  
  }


.Card {
  width: 20vw !important;
  height: 85vh !important;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 450px) {
  .Card {
    width: 90vw !important;
    height: 100vh !important;
    margin:3vw !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 2000px) {
  .Card {
    width: 20vw !important;
    height: 110vh !important;
  }
}

.ProductImage {
  width: 100px !important;
  height: 100px !important;
}


.NortonLogo {
  width: 10vw !important;
  height: 5vh !important;
}


@media screen and (max-width: 450px) {
  .NortonLogo {
    width: 18vw !important;
    height: 5vh !important;
  }
}
.ButtonContainer {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width: 18vw !important;

}


@media screen and (max-width: 450px) {
  .ButtonContainer {
    width: 80vw !important;
  }
}


.HeadingContainer {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width: 18vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh !important;

}

@media screen and (max-width: 450px) {
  .HeadingContainer{
    width: 80vw !important;
    height: 5vh !important;
  }
}

.Heading{
  width: 15vw !important;
  align-self: center !important;
  color:var(--amplify-colors-white)!important;
  font-size:1.5em !important;
}


@media screen and (max-width: 450px) {
  .Heading{
    font-size:0.75em !important;
    width: 40vw !important;
  }
}

.DescriptionContainer {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width: 18vw !important;
  display: flex;
  vertical-align: top !important;
  height: 45vh !important;
}


@media screen and (max-width: 450px) {
  .DescriptionContainer{
    font-size:0.75em !important;
    width: 80vw !important;
    height: 55vh !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 2000px) {
  .DescriptionContainer{
    height: 65vh !important;
  }
}

.metallicbutton {
  align-self: center !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:11vw !important;
  height:5vh !important;
  font-size: 1.25em !important;
  padding: 6px 16px;
  font-weight: 500;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}


@media screen and (max-width: 450px) {
  .metallicbutton {
    font-size: 1em !important;
    width:60vw !important;
  }
}


.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

.Text {
  color:var(--amplify-colors-grey)!important;
  line-height: 1.5em !important;
  font-weight:500 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .Text {
    font-size: 0.75em !important;
    margin:3vw !important;
  }
}



@media screen and (max-width: 450px) {
  .BadgePrice {
    font-size: 1em !important;
  }
}

@media screen and (max-width: 450px) {
  .FlexRow{
    width:90vw !important;
  }
}