
[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  .Breadcrumbs{
    color:#00CCCC !important;
    --amplify-components-link-visited-color:var(--amplify-colors-purple) !important;  
    --amplify-components-breadcrumbs-link-current-color:var(--amplify-colors-grey) !important;
  }
    
  @media screen and (max-width: 450px) {
    .Breadcrumbs{
      font-size: 0.75em !important;
    }
  }
    

  .BreadcrumbsLink:hover{
    color:var(--amplify-colors-turquoise) !important;  
  }
  
  