
  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


  .GridContainer {
    position:relative !important;
    top: 3vh !important;
    left: 0vw !important;
    width: 90vw !important;
    gap:2rem !important; 
    box-shadow: none;
    border-width: 2px !important;
    border-color: transparent !important;
    border-style:solid !important;
  }

@media screen and (max-width: 450px) {
  .GridContainer {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;    
    gap:0.1rem !important; 
  }
}

  
@media screen and (min-width: 451px) and (max-width: 2000px) {
  .GridContainer {  
    position:relative !important;
    top: 5vh !important;
    left: 0vw !important;
    width: 90vw !important;  
    gap:2rem !important; 
  }
}

.MenuItems{
  gap:0.75em !important;
  width:10vw !important;

}

.MenuItem{
  width:10vw !important;
  height:0.5vh !important;
}

.MenuItem:hover{
  transform: translateX(1vw) !important;
  transition-duration: 1s;
}

.logoimg{
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
}


@media screen and (max-width: 450px) { 
  .logoimg{
    height:8vh;
    width:15vw;
  }
}

@media screen and (min-width: 451px) {
  .logoimg{
    height:8vh;
    width:4vw;
  }
}

.logoName{
  position:relative !important;
  font-family: Runalto;
  font-size: 2em !important;
  font-weight: 600 !important;
  color: var(--amplify-colors-turquoise);  
  text-decoration: none !important;
  width:12vw !important;
  opacity: 1 !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

@media screen and (max-width: 450px) { 
  .logoName{
    font-size: 1em !important;
    width:30vw !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .logoName{
    font-size: 2em !important;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1500px) {
  .logoName{
    font-size: 1em !important;
  }
}



.TextUpperCase{
  font-family: BeautyLuxury !important;
  color: var(--amplify-colors-grey) !important;
  font-size: 2em !important;
}


.TextLowerCase
{
  font-family: Runalto !important;
  text-transform: lowercase !important;
  background-image: linear-gradient(
    -225deg,
    var(--amplify-colors-orange) 0%,
    var(--amplify-colors-turquoise) 29%,
    var(--amplify-colors-purple) 67%,
    var(--amplify-colors-grey) 100%
  ) !important;
  background-size: auto auto !important;
  background-clip: border-box !important;
  background-size: 200% auto !important;
  --amplify-components-text-color: #fff !important;
  color: #fff;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  text-fill-color: transparent !important;
  animation: textclip 2s linear infinite !important;
  display: inline-block !important;
  font-size: 2em !important;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.Link{
  text-decoration: none !important;
  color: var(--amplify-colors-grey)!important;
  font-size: 1em !important;  
  font-weight: 500 !important;
}

@media screen and (max-width: 450px) { 
  .Link{
    padding-top: 1vh !important;
    font-size:0.75em !important;  
  }
}


.LinkButton{
  text-decoration: none !important;
  color: var(--amplify-colors-grey)!important;
  font-size: 1em !important;  
  background: transparent !important;
  box-shadow: none;
  border: 0;
  border-radius: 0px;
  font-weight: 500 !important;
}

@media screen and (max-width: 450px) { 
.LinkButton{
    font-size: 0.75em !important;  
    font-weight: 500 !important;
  }
}


.LinkButton:hover{
  transition-duration: 1s !important;
  color: var(--amplify-colors-grey)!important;
  font-size: 1em !important;  
  text-shadow:var(--amplify-colors-turquoise) 0px 0px 40px!important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  text-underline-position: under !important;
  text-decoration-thickness: 2px !important;
  text-decoration-color: var(--amplify-colors-orange)!important;
  text-decoration-line: underline overline!important;
}


.Link:hover{
  transition-duration: 1s !important;
  color: var(--amplify-colors-grey)!important;
  font-size: 1em !important;  
  text-shadow:var(--amplify-colors-turquoise) 0px 0px 40px!important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  text-underline-position: under !important;
  text-decoration-thickness: 2px !important;
  text-decoration-color: var(--amplify-colors-orange)!important;
  text-decoration-line: underline overline!important;
}




.LinkRow{  
  gap:1rem !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  width:60vw !important;
}

@media screen and (max-width: 450px) { 
  .LinkRow{  
    gap:1rem !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    width:90vw !important;
  }
}

.LogoRow{
  gap:2rem !important;
}

@media screen and (max-width: 450px) { 
  .LogoRow{  
    gap:1rem !important;
  }
}
