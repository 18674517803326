@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');



[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
  }  
  .MasterContainer{
    position: relative;
    top: 3vh !important;
    left: 3vw !important;
  }



  .h1 {
    font-family: Runalto !important;
    font-size: 8em !important;
    color: var(--amplify-colors-turquoise) !important;
  }

  
@media screen and (max-width: 450px) {  
  .h1 {
    font-family: Runalto !important;
    font-size: 1em !important;
    color: var(--amplify-colors-turquoise) !important;
  }
}

@media screen and (min-width: 451px){  
  .h1 {
    font-family: Runalto !important;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise) !important;
  }
}


  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange) !important;
    animation: dude 2s 1 forwards !important;
  }

  @media screen and (min-width: 451px){  
    .headerline {
      height: 0.25vh;
      width: 90vw;
      background: var(--amplify-colors-orange) !important;
      animation: dude 2s 1 forwards;
    }
  }  
  
  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif !important;
    font-size: 6em !important;
    color: var(--amplify-colors-purple) !important;
  }

  @media screen and (max-width: 450px) {    
    .h2 {
      font-family: Graphik, sans-serif !important;
      font-size: 0.75em !important;
      color: var(--amplify-colors-purple) !important;
    }
  }

  @media screen and (min-width: 451px){  
    .h2 {
      font-family: Graphik, sans-serif !important;
      font-size: 2em !important;
      color: var(--amplify-colors-purple) !important;
    }
  }  

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }



  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
  }
 
  .ArticleMessageBox {
    font-size: 1rem !important;
    color: var(--amplify-colors-orange-light) !important;

  }