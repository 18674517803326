@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  }


  .BackgroundContainer{
    position:relative !important;
    top:0 !important;
    left:0 !important;
    display: flex !important;
    flex-direction:row !important;
    justify-content:center !important;
    align-items:center !important;
    align-content:center !important;
    flex-wrap:nowrap !important;
    gap:0vw !important;
    border-color: transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
  }
  
    .Background {  
      position: absolute; 
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }



    .PhotoImage{
      position:relative !important;
      top:15vh !important;
      height:35vh !important;
    }

    .Rectangle{
      position: absolute; 
      z-index: -500 !important;
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
      background-color:var(--amplify-colors-orange-light) !important;
    }

    .EndMessage{
      position:relative !important;
      z-index:900 !important;
      top:10vh !important;
      left:30vw !important;
      color:var(--amplify-colors-turquoise-light) !important;
      font-size: 1em !important;
      font-weight: 400 !important;
    }