
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
}



.SectionContainer{
  position: relative !important;
  top:0vh !important;
  left:0vw !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


@media screen and (max-width: 450px) {
  .SectionContainer {
    left:0vw !important; 
  }
}


.Background {  
  position: absolute;  
  z-index: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
}


.TextFlexColumn{
  position: absolute !important;
  z-index: 700 !important;
  top:0vh !important;
  left:7vw !important;
  gap: 0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}

@media screen and (max-width: 450px) {
  .TextFlexColumn {
    left:2vw !important;
  }
}


.HeaderTextRow {
  position: relative !important;
  top:35vh !important;
  width:80vw !important;   
  padding: 1em !important;
  gap:3vw !important;
  border-radius: 15px 50px !important; 
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}
@media screen and (max-width: 450px) {
  .HeaderTextRow {
    top:5vh !important;
  }
}

.HeaderSubTextRow{
  position: relative !important;
  top:35vh !important;
  left:7vw !important; 
  margin:1em !important;
  padding-top:1em !important;
  padding-bottom:1em !important;
  padding-left:3em !important;
  padding-right:3em !important;
  border-radius: 15px 50px !important;
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .HeaderSubTextRow {
    top:5vh !important;
    left:0vw !important; 
    width:80vw !important;
    padding-top:0.1em !important;
    padding-bottom:0.1em !important;
    padding-left:1em !important;
    padding-right:1em !important;
  }
}



.HeaderText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  line-height: 1em !important;
  width: 50vw !important;
  color: var(--amplify-colors-turquoise-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



@media screen and (max-width: 450px) {
  .HeaderText {
    left: 1vw !important;
    font-size: 2em !important;
    width: 90vw !important;
  }
}


.HeaderSubText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-orange-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}




@media screen and (max-width: 450px) {
  .HeaderSubText {
    left: 1vw !important;
    font-size: 1em !important;
    width: 90vw !important;
    line-height: 1.5em !important;
  }
}

.SubSubText{
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 300 !important;
  line-height: 1em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-purple-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;

}

.MayanNumber{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  height:5vh !important;
  width:7vw !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  column-gap: 0em !important;
}

.MayanDot{
  position: relative !important;
  top:0vh !important;
  left:0vw !important; 
  font-family: Graphik, Arial, Helvetica, sans-serif;
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 4em !important;
  font-weight: 500  !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .MayanDot {
    font-size: 2em !important;
  }
}


.MayanLine {
  height:2vh !important;
  width:3vw !important;
}


.Circle{
  position:relative !important;
  z-index:810 !important;
  display: flex !important;
  width: 10vw !important;
  height: 14vh !important;
  border-radius: 50% !important;
  background-color: var(--amplify-colors-orange-light)!important;
  align-content: center!important;
  justify-content: center!important;
  align-items: center!important;
}

.cursor{
  transform: translate3d(758px, 704px, 0px) scale(1) !important;
}

.cursorcircle{
  
  background-color: rgb(139, 139, 139) !important;
}

.ButtonText{
  font-size: 1em !important;
  color:  var(--amplify-colors-darkgrey)!important;
}


.BenefitText{
  color: var(--amplify-colors-purple-light)!important;
  font-size:2em !important; 
  font-weight:300 !important;
}




.Circle{
  position:relative !important;
  display: flex !important;
  width: 22vw !important;
  height: 32vh !important;
  border-radius: 50% !important;
  box-shadow: 5px 10px var(--amplify-colors-orange);
  background-color: var(--amplify-colors-orange-light)!important;
  align-content: center!important;
  justify-content: center!important;
  align-items: center!important;
  background-image: url("https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/MayanCoin.png");
}

.ButtonText{
  font-size: 4em !important;
  font-weight: 500 !important;
  color:  var(--amplify-colors-orange-light)!important;
}

.BenefitDev{
  position:absolute !important;
  top: 0;
  left: 0;
  z-index:800 !important;
}


.ApplicationLinkMotion{
  position: relative !important;
  top:40vh !important;
  left:10vw !important; 
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

.ApplicationLink{
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .ApplicationLinkMotion {
    top:10vh !important;
  }
}

.ApplicationLink{
  position:relative !important;
  z-index:999 !important;
}

.TurboFanBoostButton{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}
