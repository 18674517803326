
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.Background {  
  position: absolute !important;
  z-index: -1500 !important;
  min-height: 100vh !important;
  min-width: 100vw !important;
}


.HeaderTextContainer{
  position: relative !important;
  min-height:100vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  overflow: hidden !important;
}

.AuthenticateRow {
  position: relative !important;
  border-color: transparent !important;
  border-width: 4px !important;
  border-style: solid !important;
}

.AuthenticateRowTextRow{
  position: relative !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
  width:80vw !important;  
  padding: 0.5rem !important;
  margin: 0.5rem !important; 
}


@media screen and (max-width: 450px) {
  .AuthenticateRowTextRow {
    gap:3vw !important;
  }
}


.MenuTextContainer{
  position: relative !important;
  left:10vw !important;
  top:30vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  4px !important;
}

@media screen and (max-width: 450px) {
  .MenuTextContainer {
    left:2vw !important;
  }
}


.MenuTextRow{
  position: relative !important;
  width:80vw !important;
  padding: 1rem !important;
  margin: 0.5rem !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}


@media screen and (max-width: 450px) {
  .MenuTextRow {
    gap:3vw !important;
  }
}

@keyframes scaleh1Blue {
  from {
    opacity: 0;
  }
  to  {
    opacity: 1;
  }
}



.MenuText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 6em !important;
  font-weight: 300 !important;
  line-height: 1em !important;
  left: 10vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-purple-light) !important;
  transition: color 2s, font-weight 2s !important;
}

@media screen and (max-width: 768px) {
  .MenuText {
    font-size: 1.5em !important;
  }
}


.MenuText:hover {
  color: var(--amplify-colors-orange-light) !important;
}



.HeaderMenu{
  border-color: transparent !important; 
  border-style:  solid !important;
  border-width:  2px !important;
}


.LogoName{
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.Link{
  text-decoration: none !important;
}

.PhotoImage{
  position:relative !important;
  top:-10vh !important;
  z-index: -100 !important;
  opacity:0 !important;
  height:10vh !important;
  width:7vw !important;
  transition:opacity 1s !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.PhotoImageVisible{
  position:relative !important;
  top:-10vh !important;
  z-index: -100 !important;
  height:10vh !important;
  width:7vw !important;
  animation: PhotoImageV 1s !important; 
}


@keyframes PhotoImageV {
  0% {
    opacity: 0;
    left:-10vw;
  }
  50% {
    opacity: 0.5;
    left:5vw;
  }
  100% {
    opacity: 1;
    left:0vw;
  }
}


.ProductImageVisible{
  position:relative !important;
  top:-10vh !important;
  z-index: -100 !important;
  height:10vh !important;
  width:7vw !important;
  animation: ProductImageAnimation 1s !important; 
}


@keyframes ProductImageAnimation {
  0% { transform: scale(0); }
  50% { transform: scale(1.25);}
  100% { transform: scale(1); }
}


.NewsImageVisible{
  position:relative !important;
  top:-10vh !important;
  z-index: -100 !important;
  height:10vh !important;
  width:7vw !important;
  animation: NewsImageAnimation 1s !important; 
}


@keyframes NewsImageAnimation {
  0% {   opacity: 0; transform: scale(0);}
  50% {  opacity: 0.5;transform: scale(1.25);}
  100% { opacity: 1;  transform: scale(1);}
}


.ModelsImageVisible{
  position:relative !important;
  z-index: -100 !important;
  top:-10vh !important;
  height:10vh !important;
  width:7vw !important;
  animation: ModelsImageAnimation 1s ease-in-out !important; 
}


@keyframes ModelsImageAnimation {
    0% {
      opacity: 0;
      left:5vw;
      top:3vh;
    }
    100% {
      opacity: 1;
      left:0vw;
      top:-10vh;
  }
}



.AboutImageVisible{
  position:relative !important;
  z-index: -100 !important;
  top:-10vh !important;
  height:10vh !important;
  width:7vw !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
  animation: AboutImageAnimation 1s ease-in-out !important; 
}


@keyframes AboutImageAnimation {
    0% {
      opacity: 0;
      left:5vw;
      top:3vh;
    }
    100% {
      opacity: 1;
      left:0vw;
      top:-10vh;
  }
}

.Button{
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

