@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
    font-family: 'Mulish', sans-serif;
    font-size: 28px;
    color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;  
  }


  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color:transparent !important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  }

  
@media screen and (max-width: 1000px) {  
  .TextField{
    font-size: 1em !important;
    --amplify-components-fieldcontrol-font-size: 0.75em !important;
  }
}

  .TextFieldTitle {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:30vw !important;
  }

  .TextFieldDateTime {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:10vw !important;
  }

  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .RedditButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
  }


  .RedditButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RedditButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  


  .TableRowBackground {
    background-color: var(--amplify-colors-black) !important;
    width:50vw !important;
  }

  @media screen and (max-width: 450px) {
    .TableRowBackground{
      background-color: transparent !important;
      color: var(--amplify-colors-purple) !important;
      --amplify-components-table-data-color: var(--amplify-colors-purple) !important;
      --amplify-internal-table-td-font-size:0.75em !important;
      font-size: 0.75em !important;
      width:50vw !important;    
      border-bottom-style: solid !important;
      border-bottom-width: 2px !important;
      border-bottom-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-table-data-border-color:transparent !important;
    }
  }

  @media screen and (min-width: 451px) {
    .TableRowBackground{
      background-color: var(--amplify-colors-black) !important;
      width:90vw !important;     
    }
  }

  .TableRowBackgroundTitle{
    background-color: transparent !important;
    width:50vw !important;
    color: var(--amplify-colors-purple) !important;
    border-color: transparent !important;
    --amplify-components-table-data-border-color: transparent !important;
    --amplify-components-table-row-background-color: transparent !important;
  }

  @media screen and (min-width: 451px) {
    .TableRowBackgroundTitle{
      background-color: var(--amplify-colors-black2) !important;
      width:90vw !important;
      color: var(--amplify-colors-purple) !important;
    }
  }

  
  .ErrorMessageRow{
    background-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-black) !important;
    --amplify-internal-table-td-font-size: 0.7em !important;
    height:auto !important;

  }

  .RedditTitleButtons {
    color: var(--amplify-colors-orange) !important;
    background-color: var(--amplify-colors-black) !important;
    border-color:  var(--amplify-colors-purple)  !important;
    height:  auto  !important;
    width:  8vw  !important;
    padding: 0px 0px;
  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  auto  !important;
      width:  8vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  2vh  !important;
      width:  1vw  !important;
      font-size:  1em  !important;
      padding: 0px 0px;
    }
}

  
  .RedditTitleButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .RedditTitleSpan{
    padding: 100px 10px;
  }

  .ToolBar{
    padding: 20px 10px;
  }

  .HideStatusRow {
    visibility: collapse;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .ShowStatusRow {
    background-image: url("https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo2.svg");
    visibility: visible;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .TableRowDataStatus{
    color: var(--amplify-colors-grey) !important;  

  }    


  .TableRowData{
    color: var(--amplify-colors-grey) !important;  
    text-align: center !important;
    vertical-align: middle !important;
  } 

  
  .TableRowBackground:hover {
    background-color: var(--amplify-colors-black) !important;
  }

  .TableRowDataERROR{
    background-color: darkred !important;
    color: var(--amplify-colors-grey) !important;  
  }

  .TableRowDataERROR:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:2.2vw  !important;
  }



  @media screen and (max-width: 450px) {
    .RhyteItMenuBackground {  
      background-color: var(--amplify-colors-purple) !important;
      width:12vw  !important;
      height:5vh  !important;
      align-items: center !important;
      border-color: transparent !important;
      border-style: solid !important;
      border-width: 2px !important;
    }
  }
  

  @media screen and (min-width: 451px) and (max-width: 1000px) {
  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:8.5vw  !important;
    height:12vh  !important;
  }
}

.RhyteItMenuCell {
  position: relative !important;
  align-content: center !important;

}

  span {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1.5em !important;
    font-weight: 100 !important;
  }




  @media screen and (max-width: 450px) {
    .Link {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 0.75em !important;
      font-weight: 100 !important;
      color:var(--amplify-colors-grey) !important;
    }
  }

  @media screen and (min-width: 451px) {
    .Link {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 1em !important;
      font-weight: 100 !important;
      color:var(--amplify-colors-grey) !important;
    }
  }


  @media screen and (max-width: 450px) {
    .SubRow{
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 1em !important;
      font-weight: 100 !important;
      color:var(--amplify-colors-grey) !important;
      --amplify-components-table-data-border-color:transparent !important;
    }
  }

@media screen and (min-width: 451px) {
  .SubRow{
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size: 1em !important;
    font-weight: 100 !important;
    color:var(--amplify-colors-grey) !important;
  }
}

.PhotoImage {
  width: 50px !important;
  --amplify-components-image-max-width: 150px !important;
}