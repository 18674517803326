@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  }
/* styles.css */
/* Base styles */
body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  background: #111;
  overflow: hidden;
}

/* Animated background */
.background {
  position: absolute;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, #ff6b6b, #ff9a6b, #ffe36b, #aaff6b, #6bffd1, #6bafff, #9a6bff, #ff6bdf, #ff6b6b);
  animation: swirl 20s linear infinite;
}

@keyframes swirl {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

/* Overlay for semi-transparent effect */
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.1), rgba(0,0,0,0.5));
  mix-blend-mode: overlay;
}

/* Content styles */
.content {
  position: relative;
  z-index: 10;
  text-align: center;
  color: white;
}

h1 {
  font-size: 3em;
  margin: 0;
}

p {
  font-size: 1.2em;
  margin-top: 0.5em;
}