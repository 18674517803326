

  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.MenuBackground{
  position: absolute;  
  z-index: -1000;  
  height: 100vh;
  width: 100vw;
}


.SignUpFormDiv {
  position:relative !important;
  top: 3vh important;
  left:3vw !important;
  padding: 1em;
  border-style: solid;
  border-width: 2px;
  border-color: transparent !important;
  box-sizing: border-box;
  background-color:transparent;  
}

  @media screen and (max-width: 450px) {
    .SignUpFormDiv {
      position:relative;
      top: 10vh !important;
      left:3vw !important;
      padding: 0.25em;
      border-style: solid;
      border-width: 0px;
      border-color:transparent;
      display:flex;
      flex-direction:column; 
      background-color:transparent;   
    }
}

.SignUpContentSection {
  position:relative;
  top: 3vh !important;
  left:3vw !important;
  width: 90vw;
  border-style: solid;
  border-color:transparent !important;
  border-width: 1px;
  box-sizing: border-box;
  z-index: 20;
}

@media screen and (max-width: 450px) {
.SignUpContentSection {
  justify-content:center;
  width: 90vw;
  font-weight: 400;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  line-height: 1.5em;
  padding-left:1em;
  padding-right:1em;
  border-style: solid;
  border-color:transparent;
  border-width: 1px;
  box-sizing: border-box;
  z-index: 20;
}
}



.SignUpContentSectionCenterLeftColumn {
  border-style: solid;
  border-color: transparent;
  border-width: 3px;
  box-sizing: border-box;
  font-weight: 400;
  width: 40vw !important;
  height:100% !important;
}


.RequiredFields {
  font-size: 3em !important;  
  line-height: 5vh !important;
  padding-bottom: 8px;
  padding-top: 8px;
  font-family: runalto !important;
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
}


@media screen and (max-width: 450px) {
  .RequiredFields {
    font-size: 1em !important;  
    line-height: 1em !important;  
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    color: var(--amplify-colors-grey);
    width: 90vw !important;
  }
}


@media screen and (min-width: 451px) and (max-width: 1200px) {
  .RequiredFields {
    font-size: 1.0em;
    line-height: 1.2em;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    color: var(--amplify-colors-grey);
    width: 90vw !important;
  }
}

@media screen and (min-width: 451px) {
.HeaderLine {
  height: 1px;
  width:0vw;
  background: var(--amplify-colors-orange) !important;
  animation: line 2s linear forwards;
  border-style: solid;
  box-sizing: border-box;
  border-color: transparent !important;
}

  @keyframes line {
    from {
      left:1vw;
      width:0vw;
    }
    to {
      left:30vw;
      width:30vw;
    }
  }
}

@media screen and (max-width: 450px) {
  .HeaderLine {
    position: relative !important;
    height: 1px !important;
    background: var(--amplify-colors-orange) !important;
    animation: line 2s linear forwards;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
  }
  
    @keyframes line {
      from {
        left:1px;
        width:0vw;
      }
      to {
        left:1px;
        width:90vw;
      }
    }
  
}


.SignUpForm {
  position:relative !important;
  z-index: 400;
  font-size: 1em !important;  
  line-height: 1.5em !important;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
  width:100%;
}

@media screen and (max-width: 450px) {
  .SignUpForm {
    align-self: center;
    justify-content: center;
    line-height: 0.5em;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border-color: transparent !important;
    color: var(--amplify-colors-grey);
    width:90vw;
  }
}



.SignUpFormContent{
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
  display:flex;
  flex-direction:column;
}

.SignUpFormContainer{
  color: var(--amplify-colors-grey);
  margin-top:1vh;
  margin-bottom:1vh;
  display:flex !important;
}

@media screen and (max-width: 450px) {
  .SignUpFormContainer{
    color: var(--amplify-colors-grey);
    margin-top:1vh;
    margin-bottom:2vh;
    display:flex !important;
    width:clamp(60vw, 90vw, 90vw) !important;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border-color: transparent !important;
  }
}

.IntroductionContainer{
  position:relative !important;
  left: 6vw !important;
  color: var(--amplify-colors-grey);
  margin-top:2vh;
  margin-bottom:2vh;
  width:clamp(40vw, 60vw, 90vw) !important;
}

@media screen and (max-width: 450px) {
  .IntroductionContainer{
    color: var(--amplify-colors-grey);
    margin-top:1vh;
    margin-bottom:2vh;
    display:flex !important;
    width:clamp(60vw, 90vw, 90vw) !important;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border-color: transparent !important;
  }
}


.SignUpButtonContainer{
  color: var(--amplify-colors-grey);
  margin-top:2vh;
}


@media screen and (max-width: 450px) {
  .SignUpButtonContainer{
    color: var(--amplify-colors-grey);
    margin-top:0.5vh;
  }
}

  .SignUpFormSpan{
    height:20px;
  }

  @media screen and (max-width: 450px) {
    .SignUpFormSpan{
      height:0.5vh !important;
    }
  }

  .SignUpFormEmailField{
    --amplify-components-text-color:     var(--amplify-colors-grey) !important;
    font-size:1em !important;
    width:30vw !important;
  }

  
@media screen and (max-width: 450px) {
  .SignUpFormEmailField{
    --amplify-components-text-color:     var(--amplify-colors-grey) !important;
    width:90vw !important;
    --amplify-components-fieldcontrol-font-size:0.5em !important;
    --amplify-components-fieldcontrol-border-color:     var(--amplify-colors-grey) !important;
    --amplify-components-fieldcontrol-focus-border-color:     var(--amplify-colors-orange) !important;
  }
}

.amplify-input amplify-field-group__control{
  background-color:     var(--amplify-colors-orange) !important;

}

.TextFieldLabel{
  font-size:1rem;
  color:var(--amplify-colors-grey) !important;
}

.EmailFieldHighlighted{
  --amplify-components-text-color:     var(--amplify-colors-white) !important;
  background-color:     transparent !important;
  font-size:1em !important;
  width:30vw !important;
}

.EmailFieldHighlighted input{
  --amplify-components-text-color: var(--amplify-colors-white) !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
  background-color:     #004f4f !important;
  font-size:1em !important;
  width:30vw !important;
}

@media screen and (max-width: 450px) {
  .EmailFieldHighlighted{
    width:90vw !important;
    --amplify-components-fieldcontrol-font-size:0.5em !important;
  }
  .EmailFieldHighlighted input{
    font-size:1em !important;
    width:75vw !important;
  }

  }

@media screen and (min-width: 451px) and (min-width: 1200px){
    .EmailFieldHighlighted{
      --amplify-components-text-color:     var(--amplify-colors-white) !important;
      width:30vw !important;
      --amplify-components-fieldcontrol-font-size:0.5em !important;
      --amplify-components-fieldcontrol-border-color:     var(--amplify-colors-grey) !important;
      --amplify-components-fieldcontrol-focus-border-color:     var(--amplify-colors-orange) !important;
    }
  }


  @media screen and (min-width: 451px) and (min-width: 1200px){
    .EmailFieldHighlighted input{
      --amplify-components-text-color: var(--amplify-colors-white) !important;
      --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
      background-color:     #004f4f !important;
      font-size:1em !important;
      width:30vw !important;
    }
  }  

  @media screen and (min-width: 1201px) and (max-width: 4000px){
    .EmailFieldHighlighted{
      --amplify-components-text-color:     var(--amplify-colors-white) !important;
      width:30vw !important;
      --amplify-components-fieldcontrol-font-size:0.5em !important;
      --amplify-components-fieldcontrol-border-color:     var(--amplify-colors-grey) !important;
      --amplify-components-fieldcontrol-focus-border-color:     var(--amplify-colors-orange) !important;
    }
  }


  @media screen and (min-width: 1201px) and (max-width: 4000px){
    .EmailFieldHighlighted input{
      --amplify-components-text-color: var(--amplify-colors-white) !important;
      --amplify-components-fieldcontrol-color: var(--amplify-colors-white) !important;
      background-color:      linear-gradient(
                                      45deg,
                                      rgb(15, 72, 72) 5%,
                                      #005050  10%,
                                      #006868  30%,
                                      #003434  50%,
                                      #000303  70%,
                                      #004e4e  80%,
                                      #004f4f  95%
                                    ) !important;
      font-size:1em !important;
      width:30vw !important;
    }
  }  





  .SignUpFormPasswordField {
    --amplify-components-fieldcontrol-color:  var(--amplify-colors-grey) !important;
    --amplify-components-passwordfield-color:  var(--amplify-colors-purple) !important;
    font-size:1em !important;
    width:30vw !important;
  }

  @media screen and (max-width: 450px) {
    .SignUpFormPasswordField {
      --amplify-components-fieldcontrol-color:  var(--amplify-colors-grey) !important;
      --amplify-components-passwordfield-color:  var(--amplify-colors-purple) !important;
      font-size:1.4em;
      width:90vw !important;
    }
  }

  .ColumnSpan {
    flex-grow: 0; 
    flex-shrink: 0; 
    height: 100%; 
    width: 80px; 
    display:flex;
    flex-direction:column;  
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color:transparent;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    box-sizing: border-box;
  }


  @media screen and (max-width: 450px) {
  .ColumnSpan {
    flex-grow: 0; 
    flex-shrink: 0; 
    height: 0vh; 
    width: 0vw; 
    display:flex;
    flex-direction:column;  
    border-style: solid;
    border-color:transparent;
    border-width: 1px;
    box-sizing: border-box;
  }
}

  .Description {
    flex-grow: 0; 
    flex-shrink: 0; 
    width: 30%;
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color: transparent;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    box-sizing: border-box;
  }


  .AcknowledgeRow {
    color: var(--amplify-colors-grey);
    border-style: solid;
    border-color: transparent;
    border-width: 1px; 
    box-sizing: border-box;  
    display:flex;
    flex-direction:row;   
    font-weight:400; 
    height: 5vh !important; 
    align-content: center;
  }



.AcknowledgeCheckboxFieldContainer{
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  display: flex !important;
}

.AcknowledgeCheckboxFieldContainer:hover{
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;  
  border-color:  var(--amplify-colors-black) !important;
  background-color: var(--amplify-colors-turquoise) !important; 
}

  
.Checkbox {
    cursor: var(--amplify-colors-turquoise) !important;
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple) !important;
    --amplify-components-checkbox-button-color: var(--amplify-colors-black) !important;
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple) !important;
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey) !important;
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise) !important;
    font-size: 1em !important;
}

@media screen and (max-width: 450px) {
  .Checkbox {
      cursor: var(--amplify-colors-turquoise) !important;
      --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple) !important;
      --amplify-components-checkbox-button-color: var(--amplify-colors-black) !important;
      --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple) !important;
      --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey) !important;
      --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise) !important;
      font-size: 1em !important;
  }  
}



.AcknowledgeDescriptionContainerColumn {
  border-style: solid;
  border-color: transparent !important;
  border-width: 1px;
  display: flex !important;
  width: 40vw !important; 
  align-items: center;
  padding-left: 1vw; 
}



@media screen and (max-width: 450px) {
  .AcknowledgeDescriptionContainerColumn {
    border-style: solid;
    border-color: transparent !important;
    border-width: 1px;
    display: flex !important;
    width: 70vw !important; 
    align-items: center;
    padding-left: 1vw; 
  }
}

.AcknowledgeDescriptionColumn {
  align-self: center;
  border-style: solid;
  border-color: transparent;
  border-width: 1px; 
  font-size:1.2em !important;
  line-height: 1.4em !important; 
  width:40vw;
  color:white;
  --amplify-components-fieldcontrol-color: transparent;
  --amplify-components-fieldcontrol-border-color: transparent;
}

.AcknowledgeDescriptionColumn:link {
  align-self: center;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  width:30vw;
  font-size:1.2em !important;
  color:white;
  text-decoration: none !important;
  --amplify-components-fieldcontrol-color: transparent;
  --amplify-components-fieldcontrol-border-color: transparent;
}



@media screen and (max-width: 450px) {
  .AcknowledgeDescriptionColumn {
    align-self: center;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    font-size:0.5em !important;
    width:60vw !important;
    --amplify-components-fieldcontrol-color: #1f1f1f !important;
    --amplify-components-fieldcontrol-border-color: transparent;
  }

  .AcknowledgeDescriptionColumn:link {
    align-self: center;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    width:30vw;
    font-size:1em !important;
    color:white;
    --amplify-components-fieldcontrol-color: transparent;
    --amplify-components-fieldcontrol-border-color: transparent;
  }
  
  
}

@media screen and (min-width: 451px)  and (max-width: 1200px) {
  .AcknowledgeDescriptionColumn {
    align-self: center;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    font-size:1em !important;
    width:60vw !important;
    --amplify-components-fieldcontrol-color: #1f1f1f !important;
    --amplify-components-fieldcontrol-border-color: transparent;
  }
}

.amplify-text--primary {
  --amplify-components-fieldcontrol-color: #1f1f1f !important;
}

.CheckOutButtonContainer{
  margin-top: 5vh;

}

.CheckOutButton {
  width: clamp(10vw, 10vw, 20vw) !important;
  height:5vh;
  border-width: 2px;
  border-radius: 0;
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-black) !important;;  
  font-size:  clamp(0.75rem, 2.5vw, 2rem) !important;
  font-weight: 100 !important;
  line-height: 1.3;
  letter-spacing: 0.1vw;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  padding: 1rem !important;

  --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
  --amplify-components-button-color: var(--amplify-colors-white) !important;
}


@media screen and (max-width: 450px) {
.CheckOutButton {
  width: clamp(20vw, 20vw, 20vw) !important;
  height:5vh;
  margin-top:2vh;
  border-width: 2px;
  border-radius: 0;
  border-color: var(--amplify-colors-turquoise);
  background-color: transparent;  
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.1vw;
  font-weight: 20;
  font-family: Graphik, Arial, Helvetica, sans-serif;

  --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
  --amplify-components-button-color: var(--amplify-colors-white) !important;
  color:var(--amplify-colors-white) !important;
  font-weight:800 !important;
}
}


.CheckOutButton:hover{
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;  
  border-color: var(--amplify-colors-turquoise);
  background-color:var(--amplify-colors-orange);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
  color:var(--amplify-colors-orange) !important;
  font-weight:800 !important;
}

.CheckOutButton:focus{
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;   
  border-color: var(--amplify-colors-turquoise);
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-orange);
     
}

.CheckOutButton:disabled{
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;   
  border-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black) !important;
  color:var(--amplify-colors-orange);
     
}

.SignUpFormLabel{
  color: var(--amplify-colors-purple) !important;
  
}

@media screen and (max-width: 450px) {
  .SignUpFormLabel{
    color: var(--amplify-colors-purple) !important;
    font-size:0.5em !important;  
  }
}

.ValuePropAnimatedBorder {
  position: relative;
  height:55vh !important;
  width: 30vw !important;
  background-color: transparent !important;
  border: 2px solid !important;
  border-color:transparent !important;
  border-style: solid !important;
	z-index: 50;
  overflow: hidden !important;
}

.InnerBoxBackGround {
  position: relative;
  top: -10vh !important;
  left: 10vw !important;
	z-index: 55;
  height: 90vh !important;
  width: 5vw !important;
  background: linear-gradient(var(--amplify-colors-purple), var(--amplify-colors-turquoise));
  animation: chris  infinite 3s linear !important;  
  border: 2px solid !important;
  border-color:transparent !important;
  border-style: solid !important;
}

@keyframes chris {
  from { 
    transform: rotate(0deg);    
  } 
  to {
    transform: rotate(360deg); 
  }
}

@media screen and (max-width: 450px) {
.ValuePropAnimatedBorder {
  position: relative;
  width: 90vw;
  padding-top: 1vh;
  flex: 0 0 50%;
  background-color: transparent;
  border: 1em solid;
  border-color: transparent !important;
	z-index: 50;
  animation: ValuePropAnimatedBorderLine 2s linear forwards;
}
}



.ParagraphText{
  color: var(--amplify-colors-grey) !important;
  font-size: 24px; 
  line-height: 1.6;
}

.InnerBlackBoxBackGround{
  position: relative;
  background-color: var(--amplify-colors-black) !important;
  width:clamp(10vw,29vw, 50vw) !important;
  margin: 2vw !important;
  left: -1.5vw !important;
  height: clamp(50vh, 52vh, 90vh) !important;
  top: -93vh !important;
	z-index: 60;
  border: 2px solid;
  border-color: transparent !important;
  align-items: center !important;
  overflow:hidden;
}


.ValuePropStatementPadding{
  position: relative;
  margin:1vw;
	z-index: 65;
}

@media screen and (max-width: 450px) {
  .ValuePropStatementPadding {
    position: relative;
    margin:1vw;
    z-index: 65;
  }
  }
  

.WhatisYourFreedomWorth{
  color: var(--amplify-colors-purple);     
  font-size: clamp(0.5em, 1.5em, 2.0em) !important;   
  line-height: 1.5em;

  &::first-letter {
    initial-letter: 3 2;
    font-family: BeautyLuxury !important;
    font-weight: 800;
    padding-right: 1rem;
    background: linear-gradient(
      to right,
      var(--amplify-colors-purple),
      var(--amplify-colors-turquoise)
    );
    color: transparent;
    -webkit-background-clip: text;
  }  
}

@media screen and (max-width: 450px) {
  .WhatisYourFreedomWorth{
    color: var(--amplify-colors-purple);     
    font-size: 1.5em;   
    line-height: 1.5em;
  }
}



.StandUpToDigitalSurveillance{
 color: var(--amplify-colors-grey);   
  font-size: 36px; 
  line-height: 1.6;
}

.CancelAnytime{
 color: var(--amplify-colors-orange);   
  font-size: 36px; 
}


.StatusContainer{
  color: var(--amplify-colors-grey);
  margin-top:2vh;
  border: 2px solid;
  border-color: transparent !important;  
  height:10vh !important;
}


@media screen and (max-width: 450px) {
  .StatusContainer{
    color: var(--amplify-colors-grey);
    margin-top:3vh;
    font-size: 0.75em !important;
    line-height: 1.2 !important;
    width:30vw !important;
    height:10vh !important;
  }
}

.StatusMessageBox {
  position:relative !important;
  top: 1vh !important;
}

@media screen and (max-width: 450px) {
.StatusMessageBox {  
  position:relative !important;
  top: 20vh !important;
  font-size: 0.5em !important;
}
}


.ApplicationContainer {
  position:relative !important;
  left:5vw;
  top:0vh;
  width:40vw !important;
  z-index:-100;
  animation: 1s ApplicationButtonAnimation ease-out;
}

@media screen and (max-width: 450px) {  
  .ApplicationContainer {
    position:relative !important;
    width:40vw !important;
    left:5vw;
    top:0vh;
    animation: 1s ApplicationButtonAnimation ease-out;
    border-style: solid;
    border-color: transparent;
    border-width:1px !important;
  }
}

@keyframes ApplicationButtonAnimation {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  95% {
    opacity: 0;
    transform: translateY(0%);
  }  
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}


.Introduction {
  font-size: 2em !important;  
  line-height: 3vh !important;
  padding-bottom: 8px;
  padding-top: 8px;
  border-style: none;
  box-sizing: border-box;
  color: var(--amplify-colors-grey);
}


@media screen and (max-width: 450px) {
  .Introduction {
    font-size: 1.0em;
    line-height: 1.2em;
    border-style: solid;
    box-sizing: border-box;
    border-color: transparent !important;
    color: var(--amplify-colors-grey);
    width: 90vw !important;
  }
}

.metallicbutton {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:11vw !important;
  height:8vh !important;
  font-size: 2em !important;
  padding: 6px 16px;
  font-weight: 500;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

@media screen and (max-width: 450px) {  
  .metallicbutton {
    width:60vw !important;
    left:5vw;
    top:0vh;
  }
}
