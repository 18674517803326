@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}



  .ImageContainer{
    width:11vw !important;
    height:7vh !important;
    transition: all 0.2s ease-out;
    padding: 1em !important;
    margin: 6em !important;
    text-align: right;
  
    /* animation properties */
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    
    -moz-animation: my-animation 60s linear infinite;
    -webkit-animation: my-animation 60s linear infinite;
    animation: my-animation 60s linear infinite;
  }

  
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(-100%); }
    to { -moz-transform: translateX(-4001%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(-100%); }
    to { -webkit-transform: translateX(-4001%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
    to {
      -moz-transform: translateX(-4001%);
      -webkit-transform: translateX(-4001%);
      transform: translateX(-4001%);
    }
  }

  @media screen and (max-width: 450px) {
  .ImageContainer{
    width:11vw !important;
    height:7vh !important;
    transition: all  0.2s ease-out;
    padding: 1em !important;
    margin: 6em !important;
    text-align: right;
  
    /* animation properties */
    -moz-transform: translateX(500%);
    -webkit-transform: translateX(500%);
    transform: translateX(500%);
    
    -moz-animation: my-animation 80s linear infinite;
    -webkit-animation: my-animation 80s linear infinite;
    animation: my-animation 80s linear infinite;
  }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(500%); }
    to { -moz-transform: translateX(-4001%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(500%); }
    to { -webkit-transform: translateX(-4001%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(500%);
      -webkit-transform: translateX(500%);
      transform: translateX(500%);
    }
    to {
      -moz-transform: translateX(-4001%);
      -webkit-transform: translateX(-4001%);
      transform: translateX(-4001%);
    }
  }

}
  


.Text{
  color: var(--amplify-colors-orange-light)!important;
  --amplify-components-text-color: var(--amplify-colors-orange-light) !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  padding: 1em !important;
  opacity: 1 !important;
}
