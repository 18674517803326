@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


  .ImageContainer{
    width:80vw !important;
    height:7vh !important;
    transition: all 3s ease-out;
    text-align: right;
  
    /* animation properties */
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0%);
    
    -moz-animation: my-animation 120s linear infinite;
    -webkit-animation: my-animation 120s linear infinite;
    animation: my-animation 120s linear infinite;
  }
  
  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(0%); }
    to { -moz-transform: translateX(-1000%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(0%); }
    to { -webkit-transform: translateX(-1000%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    to {
      -moz-transform: translateX(-1000%);
      -webkit-transform: translateX(-1000%);
      transform: translateX(-1000%);
    }
  }


  @media screen and (max-width: 450px) {
    .ImageContainer{
      width:400vw !important;
      height:7vh !important;
      transition: all 3s ease-out;
      text-align: right;
    
      /* animation properties */
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0%);
      
      -moz-animation: my-animation 120s linear infinite;
      -webkit-animation: my-animation 120s linear infinite;
      animation: my-animation 120s linear infinite;
    }

  /* for Firefox */
  @-moz-keyframes my-animation {
    from { -moz-transform: translateX(0%); }
    to { -moz-transform: translateX(-1000%); }
  }
  
  /* for Chrome */
  @-webkit-keyframes my-animation {
    from { -webkit-transform: translateX(0%); }
    to { -webkit-transform: translateX(-1000%); }
  }
  
  @keyframes my-animation {
    from {
      -moz-transform: translateX(0%);
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
    to {
      -moz-transform: translateX(-1000%);
      -webkit-transform: translateX(-1000%);
      transform: translateX(-1000%);
    }
  }
  }



.Text{
  color: var(--amplify-colors-turquoise-light)!important;
  font-weight: 500 !important;
  font-size: 1em !important;
  padding: 1em !important;
  opacity: 1 !important;
}
