

.MenuBackground {  
  position: absolute;  
  z-index: -1000;  
  height: 100vh;
  width: 100vw;
}

[data-amplify-authenticator] {
  --amplify-colors-background-primary:white !important;  
  --amplify-colors-font-primary: white !important;  
}

.AuthenticatorContainer{
  position: absolute !important;
  top: 25vh !important;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  width: 100vw;
  height: 50vh;
  font-weight: 500 !important;
}

@media screen and (min-width: 451px) and (max-width: 900px) {  
  .AuthenticatorContainer{
    position: absolute !important;
    top: 15vh !important;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    width: 100vw;
    height: 50vh;
  }
  }


.amplifytabs {
  display: none;
}

.amplify-button {
  background: linear-gradient(
    to right,
    white,
    red
  );
}

.Authenticator {
  background-color: white !important;
}

.amplify-input {
  background-color: white !important;

}

.amplify-textfield {
  background-color: white !important;

}

.amplify-flex{
  background-color: white !important;

}

.amplify-input {
  box-sizing: border-box;
  color: white !important;
  font-size: var(--amplify-components-fieldcontrol-font-size);
  width: 100%;
  border-color:  #00CCCC !important;
  border-radius: var(--amplify-components-fieldcontrol-border-radius);
  border-style: var(--amplify-components-fieldcontrol-border-style);
  border-width: var(--amplify-components-fieldcontrol-border-width);
  outline-color: var(--amplify-components-fieldcontrol-outline-color);
  outline-style: var(--amplify-components-fieldcontrol-outline-style);
  outline-width: var(--amplify-components-fieldcontrol-outline-width);
  outline-offset: var(--amplify-components-fieldcontrol-outline-offset);
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
  display: inline-flex;
}

.amplify-label {
  color: white !important;
}

  .RhyteItlogo {
    --amplify-components-image-height:20vh !important;

    display: block;
    text-shadow: 0 0 8px #fff;
    animation-name: cross-fade-blur;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: 0.04s;
  }

  @keyframes cross-fade-blur {
    0% {
      opacity: 0;
      filter: blur(1rem);
      transform: translate3d(0, 3rem, 0) rotate3d(0.25, -1, 0, 66.6deg);
    }
    20%,
    60% {
      opacity: 1;
      filter: blur(0px);
      transform: translate3d(0, 0, 0);
    }
    80%,
    100% {
      opacity: 0;
      filter: blur(1rem);
      transform: translate3d(0, -3rem, 0) rotate3d(-0.25, 1, 0, 66.6deg);
    }
  }

@media screen and (min-width: 451px) and (max-width: 900px) {  
.RhyteItlogo {
  --amplify-components-image-height:20vh !important;
}
}

.Loginarea{
  position:relative !important;
  top:3vh !important;
  left: 40vw !important;
}


.metallicbutton {
  border-color:  var(--amplify-colors-turquoise) !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:20vw !important;
  height:7vh !important;
  font-size: 2em !important;
  padding: 6px 16px;
  font-weight: 800;
  border: none;
  outline: none;
  color: var(--amplify-colors-white)!important;
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}


.metallicbutton {
  width:40vw !important;
  height:7vh !important;
  font-size: 2em !important;
}

.metallicbutton:hover {
  color: var(--amplify-colors-grey);  
  transform: scale(1.2);
  box-shadow: 0 30px 45px -15px #ebebf5;
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #2e2e3d 30%,
    #0f0f14 50%,
    #000000 70%,
    #4d4d66 80%,
    #9999cc 95%
    ) !important;
}

.Text {
  color:#00CCCC !important;
  font-size:2em !important;
  font-weight:500 !important;
}