@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  
  }

    
  .RhyteItTable {
    --amplify-components-table-width: 90vw !important;
  }


  @media screen and (max-width: 450px) {
    .RhyteItTable {
      width: 40vh !important;
    } 
  }

  @media screen and (min-width: 451px) and (max-width: 1500px) {
    .RhyteItTable {
      width: 90vw !important;
    } 
  }

  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }

  .TableHeaderRowCells{
    font-size: 1rem !important;
    color:var(--amplify-colors-turquoise) !important;
    background-color:var(--amplify-colors-black) !important;
  }

  

  @media screen and (max-width: 450px) {
    .TableHeaderRowCells{
      font-size:0.75em !important;      
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1500px) {  
    .TableHeaderRowCells{
      font-size:0.75em !important;      
    }
  }


  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCells{
      font-size:1em !important;
    }
  }
  
  
