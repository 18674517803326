
.AuroraContainer{
  position: relative;
  top: 0;
}


.menuBg {
  opacity: 50;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
}

.menuBgSVG {
  width: 100vw !important;
  height: 100vh !important;
}
