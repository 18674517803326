@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-podcastpurple:#230942;  
  }  
  
  .Section{    
    position: relative!important;
    background-color:transparent !important;
    opacity: 1 !important;
    left: 3vw !important;
    margin-top: 2vh !important;
  }

  .ShowRowFlex{
    padding:1em !important;
    margin:3em !important;
    border-color: transparent !important; 
    border-width: 2px !important;
    border-style: solid !important;
  }

  .FlexLeftColumn{
    border-color: transparent!important; 
    border-width: 2px !important;
    border-style: solid !important;

  }

  .FlexRightColumn {
    border-style: solid;
    border-width: 2px;
    border-color: transparent !important;
    width:70vw !important;
  }

  .BlogPromptTitleDiv{
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
  }

  .BlogPromptTitle{
    color:  var(--amplify-colors-turquoise-light) !important;
    font-size: 2rem !important;
    font-weight: 400 !important;
  }

  .BlogPromptDescriptionDiv{
    padding:1em !important;
    margin:1em !important;
  }

  .BlogPromptDescription{
    color:  white !important;
    font-size: 1em !important;
    font-weight: 500 !important;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    line-height: 3em !important;
    letter-spacing: 0.1vw !important;
  }


  .ButtonLinkText {
    text-decoration: none !important;
    color: var(--amplify-colors-orange-light) !important; 
    font-size:  clamp(0.75rem, 2.5vw, 2rem) !important;
    font-weight: 100 !important;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-family: Graphik, Arial, Helvetica, sans-serif;
    padding: 1rem !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
  }


  .Button {
    width:  10vw !important;
    text-decoration: none !important;
    letter-spacing: 0.1vw !important;
    height:3vh !important;
    border-width: 2px;
    font-size: 1em !important;
    color: var(--amplify-colors-black) !important; 
    border-color: var(--amplify-colors-turquoise-light) !important; 
    background-color: var(--clr);
    background-image:   
      linear-gradient(180deg, 
        var(--clr2) var(--gradgap), 
        transparent calc(100% - var(--gradgap))
      );
    background-repeat: no-repeat;
    background-position: center var(--gradoffset);
    background-size: 100% 200%;
    border-radius: 2em;
    border: none;    
    box-shadow: 
      0 -0.5em 0.5em transparent,
      0 0.5em 0.5em transparent,
      0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
      0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);
  
    position: relative;
    transition: all 0.5s ease;
    outline: none;
    
    &::before, &::after {
      content: "";
      inset: 0;
      position: absolute;
      border-radius: 5em;
    }
  
    &::before {
      background-image: 
        radial-gradient(ellipse, 
          hsla(var(--hue), 100%, 90%, .8) 20%,
          transparent 50%,
          transparent 200%
        ),
        linear-gradient(90deg, 
          hsl(0deg, 0%, 25%) -10%, 
          transparent 30%, 
          transparent 70%, 
          hsl(0deg, 0%, 25%) 110%
        );
      box-shadow: 
        inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
        inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
        inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
      background-blend-mode: overlay;
      background-repeat: no-repeat;
      background-size: 200% 80%, cover;
      background-position: center 220%;
      mix-blend-mode: overlay;
      filter: blur(calc(var(--blur) * 0.5));
    }
    
    &::after {
      background: linear-gradient(
        180deg, 
        hsla(var(--hue2),100%,90%,.9), 
        hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
        transparent 80%
      );
      top: 0.075em;
      left: 0.75em;
      right: 0.75em;
      bottom: 1.4em;
      filter: blur(var(--blur));
      mix-blend-mode: screen;
    }
    
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: 
        0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
        0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
        0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
        0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
        inset 0 -2px 2px rgba(255,255,255,0.2);
      background-position: center calc( var(--gradoffset) - 0.75em );
    }  
  }
  
  
  @media screen and (max-width: 450px) {
  .Button {
    width: clamp(20vw, 20vw, 20vw) !important;
    height:5vh;
    margin-top:2vh;
    border-width: 2px;
    border-radius: 0;
    border-color: var(--amplify-colors-turquoise);
    background-color: transparent;  
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.1vw;
    font-weight: 20;
    font-family: Graphik, Arial, Helvetica, sans-serif;
  
    --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color:var(--amplify-colors-white) !important;
    font-weight:800 !important;
  }
  }
  
  
  .Button:hover{
    border-color: var(--amplify-colors-turquoise);
    background-color:var(--amplify-colors-orange);
    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    color:var(--amplify-colors-black) !important;
    font-weight:800 !important;
  }
  
  .Button:focus{  
    border-color: var(--amplify-colors-turquoise);
    background-color: var(--amplify-colors-black) !important;
    color:var(--amplify-colors-purple-light) !important;   
  }
  
  .Button:disabled{
    border-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
    color:var(--amplify-colors-orange-light) !important;    
  }

  .Subscribers{
    color:var(--amplify-colors-orange-light) !important;    
    font-size:2rem !important;
    font-weight: 200 !important;
  }


  .Table{
    border-color: transparent !important;
    width:80vw !important;
  }
      

.TableHeaderRow{
  border-color: transparent !important;
}
    
.TableHeaderRowCells{
  color:var(--amplify-colors-purple-light) !important;    
  font-size:1rem !important;
  font-weight: 400 !important;
  text-align: center !important;
  border-color: transparent !important; 

}

.TableCell{
  text-align: center !important;
  border-color: transparent !important; 
}
  
.SelectField {
  --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
  --amplify-components-selectfield-font-size:15px !important;    
  --amplify-components-select-color: var(--amplify-colors-grey);
  background-color: transparent!important;
  color: var(--amplify-colors-turquoise)!important;
  width:30vw !important;
  font-weight:500 !important;
}


@media screen and (max-width: 450px) {
  .SelectField {
    width:75vw !important;
  }

}

.SelectField Option {
  --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
  --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
  --amplify-components-selectfield-font-size:15px !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-turquoise)!important;
}

.SelectFieldOption:hover {
  background: var(--amplify-colors-orange) !important;
}

.Autocomplete{
  border-color:var(--amplify-colors-turquoise) !important;
  color: var(--amplify-colors-turquoise-light)!important;
}

.Autocomplete input{
  color: var(--amplify-colors-purple-light)!important;
}


.Loader{
  color: var(--amplify-colors-purple-light)!important;
  --amplify-components-loader-text-fill: var(--amplify-colors-purple-light)!important;
  --amplify-components-loader-linear-stroke-filled:  var(--amplify-colors-orange-light)!important;
  --amplify-components-loader-linear-stroke-empty: var(--amplify-colors-purple-light)!important;
}
