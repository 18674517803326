
[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }



  .h1 {
    font-family: Runalto, sans-serif;
    font-size: 1em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Runalto, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 1em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }
