body {    
  font-family: Runalto !important;
  width: 100vw !important;
  height: 200vh !important;
}

.LandingPageContainer{
  position: fixed;
  top: 0;
  z-index: -1500 !important;
  width: 200vw !important;
  height: 1000vh !important;
  background-color: var(--amplify-colors-black) !important;
}


.menuBg {
  opacity: 50;
  top: 0;
  left: 0;
  width: 2cap !important;
  height: 200vh !important;
}

.menuBgSVG {
  width: 200vw !important;
  height: 200vh !important;
}

