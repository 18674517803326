.main {
    position: absolute;
    top: 30vh !important;
    height:3000px;
}


@media(min-width:768px) {
    .container {
        max-width: 704px
    }
}

@media(min-width:992px) {
    .container {
        max-width: 960px
    }
}

@media(min-width:1200px) {
    .container {
        max-width: 1152px
    }
}

@media(min-width:576px) {
    .container {
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        margin-right: auto;
        margin-left: auto
    }
}

@media(min-width:768px) {
    .container {
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        margin-right: auto;
        margin-left: auto
    }
}

@media(min-width:992px) {
    .container {
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        margin-right: auto;
        margin-left: auto
    }
}

@media(min-width:1200px) {
    .container {
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        margin-right: auto;
        margin-left: auto
    }
}

@media(max-width:575.98px) {
    .container {
        width: calc(100% - 48px);
        margin-right: 24px;
        margin-left: 24px
    }
}

.B2_text_hero {
    margin-top: 0px;
    margin-bottom: 16px
}

@media(min-width:768px) {
    .B2_text_hero {
        margin-top: 0px;
    }
}

@media(min-width:992px) {
    .B2_text_hero {
        margin-top: 0px;
    }
}

.B2_text_hero__content-tag {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%
}

@media(min-width:768px) {
    .B2_text_hero__content-tag {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media(min-width:992px) {
    .B2_text_hero__content-tag {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 50%;
        max-width: 50%
    }
}

.B2_text_hero__headline {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%;
    font-weight: 700;
    font-size: 4em !important;
    line-height: 48px;
    margin-top: 1px;
    margin-bottom: 0;    
    color: #00CCCC
}

@media(min-width:768px) {
    .B2_text_hero__headline {
        font-weight: 700;
        font-size: 4em !important;
        line-height: 48px
    }
}

@media(min-width:992px) {
    .B2_text_hero__headline {
        font-weight: 700;
        font-size: 56px;
        line-height: 60px
    }
}

@media(min-width:768px) {
    .B2_text_hero__headline {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 87.5%;
        max-width: 87.5%;
        font-size: 4em !important;
    }
}

@media(min-width:992px) {
    .B2_text_hero__headline {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 75%;
        max-width: 75%;
        font-size: 4em !important;
    }
}

.B2_text_hero__description {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-top: 16px;
    margin-bottom: 0
}

@media(min-width:768px) {
    .B2_text_hero__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px
    }
}

@media(min-width:992px) {
    .B2_text_hero__description {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px
    }
}

@media(min-width:768px) {
    .B2_text_hero__description {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 75%;
        max-width: 75%
    }
}

@media(min-width:992px) {
    .B2_text_hero__description {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }
}

.B2_text_hero__breaker {
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    height: 1px;
    margin-top: 40px;
    border: none
}

@media(min-width:768px) {
    .B2_text_hero__breaker {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media(min-width:992px) {
    .B2_text_hero__breaker {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 100%;
        max-width: 100%
    }
}

.B2_text_hero__breaker:after {
    position: absolute;
    top: 0;
    height: 1px;
    background: #c8cacf;
    content: "";
    right: 4px;
    left: 4px
}

@media(min-width:576px) {
    .B2_text_hero__breaker:after {
        right: 12px;
        left: 12px
    }
}

@media(min-width:768px) {
    .B2_text_hero__breaker:after {
        right: 12px;
        left: 12px
    }
}

@media(min-width:992px) {
    .B2_text_hero__breaker:after {
        right: 16px;
        left: 16px
    }
}

@media(min-width:1200px) {
    .B2_text_hero__breaker:after {
        right: 16px;
        left: 16px
    }
}

.C1_one_column_paragraph__content {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%;
    color: white
}

.C1_one_column_paragraph__content h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: white
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content h1 {
        font-weight: 700;
        font-size: 56px;
        line-height: 60px
    }
}

.C1_one_column_paragraph__content h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 16px;
    color:  #CC6633
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px
    }
}

.C1_one_column_paragraph__content h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-top: 0px;
    margin-bottom: 12px;
    color:  white
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px
    }
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content h3 {
        margin-bottom: 24px
    }
}

.C1_one_column_paragraph__content li, .C1_one_column_paragraph__content p {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color:  white
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content li, .C1_one_column_paragraph__content p {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content li, .C1_one_column_paragraph__content p {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px
    }
}

.C1_one_column_paragraph__content p {
    margin-bottom: 24px
}

.C1_one_column_paragraph__content ol, .C1_one_column_paragraph__content ul {
    padding-left: 16px
}

.C1_one_column_paragraph__content li {
    margin-bottom: 16px;
    padding-left: 3px
}

.C1_one_column_paragraph__content table {
    position: relative;
    min-width: 100%;
    color: #1f1f1f;
    border-collapse: collapse
}

.C1_one_column_paragraph__content tbody th {
    color: #00539b;
    font-weight: 700
}

.C1_one_column_paragraph__content td, .C1_one_column_paragraph__content th {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    padding: 8px 0 8px 24px;
    text-align: right;
    border-bottom: 1px solid #c8cacf
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content td, .C1_one_column_paragraph__content th {
        padding: 16px 0 16px 24px;
        padding: 16px 24px;
        text-align: center
    }
}

.C1_one_column_paragraph__content td:first-child, .C1_one_column_paragraph__content th:first-child {
    text-align: left
}

.C1_one_column_paragraph__content .table-wrapper {
    position: relative
}

.C1_one_column_paragraph__content .table-wrapper__scroller {
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.C1_one_column_paragraph__content .table-wrapper__scroller::-webkit-scrollbar {
    height: 8px
}

.C1_one_column_paragraph__content .table-wrapper__scroller::-webkit-scrollbar-thumb {
    background: #fff;
    border: solid #00539b;
    border-width: 6px 0 0
}

.C1_one_column_paragraph__content .table-wrapper__responsive-head {
    display: inline;
    padding-right: 8px
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content .table-wrapper__responsive-head {
        display: none
    }
}

@media(min-width:768px) {
    .C1_one_column_paragraph__content {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 75%;
        max-width: 75%;
        margin-left: 12.5%
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__content {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
        margin-left: 16.6666666667%
    }
}

.C1_one_column_paragraph .G7-dynamic-table {
    margin-top: 32px
}

.C1_one_column_paragraph .G7-dynamic-table, .C1_one_column_paragraph__link-list {
    position: relative;
    width: 100%;
    padding-right: 4px;
    padding-left: 4px;
    flex: 0 0 100%;
    max-width: 100%
}

@media(min-width:768px) {
    .C1_one_column_paragraph .G7-dynamic-table, .C1_one_column_paragraph__link-list {
        position: relative;
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
        flex: 0 0 75%;
        max-width: 75%;
        margin-left: 12.5%
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph .G7-dynamic-table, .C1_one_column_paragraph__link-list {
        position: relative;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
        margin-left: 16.6666666667%
    }
}

.C1_one_column_paragraph__link-list__heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-top: 34px;
    margin-bottom: 12px
}

@media(min-width:768px) {
    .C1_one_column_paragraph__link-list__heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 24px
    }
}

@media(min-width:992px) {
    .C1_one_column_paragraph__link-list__heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px
    }
}

.SignUpContentSectionCenterLeftColumn{
    font-size: 20px;
    color: #9999CC;
    border-style: solid;
    border-color: transparent;
    position: relative;
    padding-right: 18%;
    padding-left: 18%;
    flex: 0 0 100%;
    max-width: 100%;
    align-self: center;
    line-height: 40px;
    position: relative;
    width: 100%;
}


.SignUpContentSectionCenter{
    font-size: 20px;
    color: #CCCBCA;
    border-style: solid;
    border-color: transparent;
    align-items: center;    
    max-width: 1152px;
}