@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');




[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

.controlPanel {
  position: relative !important;
  top: 0vh !important;
}

.horizontalLine {
  background: var(--amplify-colors-purple) !important;
  height:2px !important;
  width: 60vw !important;
  margin-top: 2vh !important;
  margin-bottom:  2vh !important;
}



.SliderField {
  --amplify-components-sliderfield-range-background-color: var(--amplify-colors-orange);      
  --amplify-components-sliderfield-track-background-color: var(--amplify-colors-turquoise);   
  --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-purple);    
  --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-grey); 
  --amplify-components-sliderfield-thumb-focus-border-color: var(--amplify-colors-orange); 
  width: 50vw !important;
}

@media screen and (max-width: 450px) {
.SliderField {
  --amplify-components-sliderfield-track-min-width: 30vw !important;
}
}


.StepperField{
  width: 50vw !important;
  --amplify-components-stepperfield-button-background-color: var(--amplify-colors-orange) !important;
}

.Flex {
  width: 50vw !important;
}