
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.SectionContainer{
  position: relative !important;
  top:0vh !important;
  left:0vw !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  overflow: hidden !important;
}
@media screen and (max-width: 450px) {
  .SectionContainer {
    left:0vw !important; 
  }
}


.Background {  
  position: absolute;  
  z-index: -800 !important;
  height: 100vh !important;
  width: 100vw !important;
}


@media screen and (max-width: 450px) {
  .Background {
    z-index: 500 !important;
  }
}

.TextFlexColumn{
  position: absolute !important;
  z-index: 700 !important;
  top:0vh !important;
  left:7vw !important;
  gap: 0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}




.HeaderTextRow {
  position: relative !important;
  top:35vh !important;
  width:80vw !important;   
  padding: 1em !important;
  gap:3vw !important;
  border-radius: 15px 50px !important; 
  background-color: var(--amplify-colors-turquoise-light) !important;
  box-shadow: 5px 10px  5px var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .HeaderTextRow {
    top:15vh !important;
  }
}


.HeaderSubTextRow{
  position: relative !important;
  top:35vh !important;
  left:7vw !important; 
  margin:1em !important;
  padding-top:1em !important;
  padding-bottom:1em !important;
  padding-left:3em !important;
  padding-right:3em !important;
  border-radius: 15px 50px !important; 
  background-color: var(--amplify-colors-turquoise-light) !important;
  box-shadow: 5px 10px  5px var(--amplify-colors-black) !important;
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


@media screen and (max-width: 450px) {
  .HeaderSubTextRow {
    top:15vh !important;
    left:0vw !important; 
    width:80vw !important; 
  }
}



.HeaderText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  line-height: 1em !important;
  width: 50vw !important;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



@media screen and (max-width: 450px) {
  .HeaderText {
    left: 1vw !important;
    font-size: 2em !important;
    width: 90vw !important;
  }
}


.HeaderSubText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}




@media screen and (max-width: 450px) {
  .HeaderSubText {
    left: 3vw !important;
    font-size: 1em !important;
    width: 90vw !important;
    line-height: 1.5em !important;
  }
}

.SubSubText{
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 600 !important;
  line-height: 1em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;

}



.MayanNumber{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  height:5vh !important;
  width:7vw !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  column-gap: 0em !important;
}

.MayanDot{
  position: relative !important;
  top:0vh !important;
  left:0vw !important; 
  font-family: Graphik, Arial, Helvetica, sans-serif;
  color: var(--amplify-colors-black) !important;
  font-size: 4em !important;
  font-weight: 500  !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .MayanDot {
    font-size: 2em !important;
  }
}
