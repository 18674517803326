@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
    --amplify-components-fieldcontrol-font-size:1em !important;
    --amplify-internal-table-td-font-size:1em !important;
  
  }

    
  .RhyteItTable {
    --amplify-components-table-width: 40vw !important;
  }

  @media screen and (max-width: 450px)  {
      .RhyteItTable {
        --amplify-components-table-width: 90vw !important;
      }
    }

  @media screen and (min-width: 451px)  {
  .RhyteItTable {
    --amplify-components-table-width: 40vw !important;
  }
  }
  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }

  .TableHeaderRowCells{
    font-size: 1em !important;
    color:var(--amplify-colors-turquoise) !important;
    text-align: center !important;
  }
  
  
  .TableTopHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-purple) !important;
    text-align: center !important;
  }

  .TableRecordIdTopHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-grey) !important;
    background-color: transparent !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-grey) !important;
    border-width: 2px;
    column-width: 100px !important;
  }  

  .TableRecordIdHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-grey) !important;
    background-color: var(--amplify-colors-grey) !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-grey) !important;
    border-width: 2px;
    column-width: 100px !important;

  }
  @media screen and (max-width: 450px) {
    .TableRecordIdHeaderRowCells{
      font-size: 0.7em !important;
    }
  }

  .TableTweetsHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-turquoise) !important;
    text-align: center !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-turquoise) !important;
    border-width: 2px;

  } 

  .TableTweetsTopHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    color: var(--amplify-colors-turquoise) !important;
    background-color: transparent !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-turquoise) !important;
    border-width: 2px;
  }  

  .TableFollowingTopHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-orange) !important;
    background-color: transparent !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-orange) !important;
    border-width: 2px;
  }

  .TableFollowingHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-orange) !important;
    background-color: var(--amplify-colors-orange) !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-orange) !important;
    border-width: 2px;

  }

  .TableFollowersTopHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    color: var(--amplify-colors-purple) !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-purple) !important;
    border-width: 2px;
  }


  .TableFollowersHeaderRowCells{
    --amplify-components-table-header-color: var(--amplify-colors-purple) !important;
    background-color: var(--amplify-colors-purple) !important;
    text-align: center !important;
    border-style: solid;
    border-color: var(--amplify-colors-purple) !important;
    border-width: 2px;

  }


  
  @media screen and (max-width: 450px) {
    .TableFollowersHeaderRowCells{
      font-size: 0.7em !important;
    }
  }

  .TableHead {
    position: sticky; 
    top: 0; 
    z-index: 1; 
    box-shadow: inset 1px 1px #000, 0 1px #000;
  }
