
  [data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise-dark: #006666;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-turquoise-light:#99FFFF;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-orange-dark: #993300;
    --amplify-colors-orange-light: #FFCC99;
    --amplify-colors-purple: #9999CC;
    --amplify-colors-purple-light:#CCCCFF;  
    --amplify-colors-purple-dark:#330066;  
    }  
  
  
  .Background{
    background-color: #f8f4e3 !important;
    background-image: 
      radial-gradient(circle, rgba(255, 255, 255, 0.3) 1px, transparent 1px),
      radial-gradient(circle, rgba(255, 255, 255, 0.3) 1px, transparent 1px) !important;
    background-size: 20px 20px, 40px 40px !important;
    background-position: 0 0, 10px 10px !important;  
    min-height: 200vh !important;
    width: 100vw !important;
  }
  
  
  @media (max-width: 768px) {
    .Background {
        top: 10vh !important;
    }
  }
    @media screen and (max-width: 450px) {
      .FlexColumn {
        position:relative !important;
        top: 1vh !important;
        left: 3vw !important;
        width: 90vw !important;
      }
    }
    
      
    @media screen and (min-width: 451px) and (max-width: 1000px) {
      .FlexColumn {
        position:relative !important;
        top: 15vh !important;
        left: 3vw !important;
        width: 20vw !important;
      }
    }
  
    
    @media screen and (min-width: 1001px) {
    .FlexColumn {
      position:relative !important;
      top: 3vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }
  
  .MainColumn{
    position:relative !important;
    top:15vh !important;
    width: 95vw !important;
  }
  
  .SideBarFlexColumn{
    position:relative !important;
    top: 3vh !important;
    left: 1vw !important;
    width: 40vw !important;
    border-right-style: solid !important;
    border-right-color: var(--amplify-colors-darkgrey) !important;
    border-right-width: 2px !important;
    margin-right: 1rem !important;
    display: Flex;
  }
  
  @media (max-width: 768px) {
    .SideBarFlexColumn {
        width: 90vw !important;
        border-right-color: transparent !important;
    }
  }
  
  
  .FlexColumnBlogEntries {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 100vw !important;
    margin-left: 2rem !important;
  }
  
  @media (max-width: 768px) {
    .FlexColumnBlogEntries {
      top: 5vh !important;
      width: 90vw !important;
    }
  }
  
  .CategoryBlogTitle{
    font-family:Runalto !important;
    font-size: 3em !important;
    font-weight: 800 !important;
    color: var(--amplify-colors-turquoise-dark) !important;
  }
  
  @media (max-width: 768px) {
    .CategoryBlogTitle{
      font-family:Runalto !important;
      font-size: 1.5em !important;
      font-weight: 800 !important;
      color: var(--amplify-colors-turquoise-dark) !important;
    }
  }
  
    .h1 {
      font-family: Runalto;
      font-size: 3em !important;
      color: var(--amplify-colors-turquoise) !important;
    }
    
    @media screen and (max-width: 450px) {
      .h1 {
        font-family: Graphik, sans-serif !important;
        font-size: 1.5em !important;
        color: var(--amplify-colors-turquoise) !important;
      }
    }
  
    @media screen and (min-width: 451px) and (max-width: 1000px) {
      .h1 {
        font-family: Runalto !important;
        font-size: 1em !important;
        color: var(--amplify-colors-turquoise) !important;
      }
    }
  
    
    .h2 {
      font-family: ConigenReg !important;
      font-size: 2em !important;
      color: var(--amplify-colors-purple) !important;
    }
  
    @media screen and (min-width: 451px) and (max-width: 1000px) {
      .h2 {
        font-family: ConigenReg !important;
        font-size: 0.5em !important;
        color: var(--amplify-colors-purple) !important;
      }
    }
  
    @media screen and (max-width: 1001px) {
      .h2 {
        font-family: ConigenReg !important;
        font-size: 1em !important;
        color: var(--amplify-colors-purple) !important;
      }
    }
  
  
  
    .TextField{
      --amplify-components-text-color: var(--amplify-colors-grey) !important;
      --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
      color:white !important;
      font-size: 1rem !important;
    }
  
    @media (max-width: 768px) {
      .TextField {
        top: 5vh !important;
        left: 1vw !important;
        width: 90vw !important;
        margin-left: 1rem !important;
      }
    }
  
  
    .SubscribeRow{
      width:90vw !important;  
    }
  
    .SubscribeField{
      --amplify-components-text-color: var(--amplify-colors-grey) !important;
      --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
      color:white !important;
      font-size: 1rem !important;
    }
  
    @media (max-width: 768px) {
      .SubscribeField {
        top: 5vh !important;
        width: 90vw !important;      
      }
    }
  
  
  
    .SubscribeField input{
      font-size: 1rem !important;
      width: 20vw !important;  
    }
  
    @media (max-width: 768px) {
      .SubscribeField input{
        font-size: 0.8rem !important;
        width: 60vw !important;  
      }
    }
  
    .SearchField{
      --amplify-components-text-color: var(--amplify-colors-grey) !important;
      --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
      --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
      color:white !important;
      font-size: 1rem !important;
    }
  
    
    .SearchField input{
      font-size: 0.8rem !important;
    }
  
    .Text{    
      --amplify-components-text-color: var(--amplify-colors-grey);
      padding-right: 2vw !important;
    }
    
    .CheckBox{    
      cursor: var(--amplify-colors-turquoise);
      --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
      --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
      --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
      --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
      --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
      --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
    }
  
  
  
    .Slider {
        --amplify-components-sliderfield-range-background-color: var(--amplify-colors-orange);      
        --amplify-components-sliderfield-track-background-color: var(--amplify-colors-turquoise);   
        --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-purple);    
        --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-grey); 
        --amplify-components-sliderfield-thumb-focus-border-color: var(--amplify-colors-orange); 
    }
  
    
    .SelectField {
      --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
      --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
      --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
      --amplify-components-selectfield-font-size:15px !important;    
      --amplify-components-select-color: var(--amplify-colors-grey);
      background-color: transparent!important;
      color: var(--amplify-colors-turquoise)!important;
      width:20vw !important;
      font-weight:500 !important;
    }
  
    
  
  
    .SelectField Option {
      --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
      --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
      --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
      --amplify-components-selectfield-font-size:15px !important;
      background-color: var(--amplify-colors-black)!important;
      color: var(--amplify-colors-turquoise)!important;
    }
  
   .SelectFieldOption:hover {
      background: var(--amplify-colors-orange) !important;
    }
  
  
  
  .amplify-autocomplete {
    --amplify-components-autocomplete-menu-width:100% !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;  
    --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
  }
  .amplify-autocomplete__menu__options {
    --amplify-components-autocomplete-menu-options-flex-direction:column;
    --amplify-components-autocomplete-menu-width:100% !important;
    --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
  }
  
  
  
  .Text{
    color:white !important;
    font-weight:500 !important;
    font-size:1em !important;
    width:10vw !important;
  }
  
    
  .Fieldset{
    border-top-color:white !important;
    border-top-width: 0.25em !important;
    border-top-style: solid !important;
    font-weight:500 !important;
    font-size:1em !important;
    color:white !important;
    --amplify-components-fieldset-legend-color:white !important;
    width:50vw !important;
    padding:1em !important;
    margin-top:1em !important;  
  }
  
  @media screen and (max-width: 450px) {
    .Fieldset{
      width:90vw !important;
      padding:0em !important;
      padding-top:1em !important;
      margin-top:1em !important;  
    }
    }
  
  .CheckboxField{
    color:var(--amplify-colors-orange) !important;
    font-size: 0.75em !important;
  }
  
  .SearchRow {
    width:90vw !important;
  }
  
  
  @media screen and (max-width: 768px) {
    .SearchRow {
      width:90vw !important;
    }  
  }
  
  
  .FlexRow {
    padding: 0px 0 25px;
  }
  
  @media screen and (max-width: 450px) {
    .FlexRow{
      padding: 0px 0 25px;
    }
  }
  
  
  .Card{
    background: linear-gradient(var(--amplify-colors-purple-light), var(--amplify-colors-orange-light), var(--amplify-colors-turquoise-light));
    box-shadow: 12px 12px 10px 1px var(--amplify-colors-darkgrey);
    height: 20vh !important;
    width: 25vw !important;
    border-radius: 3em !important;
    padding: 2em !important;
    border-color:var(--amplify-colors-orange) !important;
    border-width: 2px !important;
    border-style: solid !important;
  }
  
  .Card:hover{
    background: linear-gradient(var(--amplify-colors-purple), var(--amplify-colors-orange), var(--amplify-colors-turquoise));
    box-shadow: 12px 12px 10px 1px var(--amplify-colors-grey);
    border-color:var(--amplify-colors-orange-light) !important;
    border-width: 4px !important;
  }
  
  .Card:active{
    background: linear-gradient(var(--amplify-colors-orange), var(--amplify-colors-turquoise), var(--amplify-colors-purple));
    box-shadow: 12px 12px 10px 1px var(--amplify-colors-turquoise);
    border-color:var(--amplify-colors-black) !important;
    border-width: 5px !important;
  }
  
  .TextAreaField{
    color:var(--amplify-colors-turquoise-light) !important;  
    border-color:transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
    width:70vw !important;
  }
  
  .TextAreaField textarea{
    color:var(--amplify-colors-grey) !important;  
    background-color: var(--amplify-colors-darkgrey) !important;  
    font-weight: 300 !important;
    border-color:transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
    width:70vw !important;
  }
  
  
  .SearchField input {
    background-color: transparent !important;
  }
  
  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-searchfield-button-background-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: tranparent !important;
    color: var(--amplify-colors-black)!important;
    width: 20vw !important;
  }
  
  
  
  
  
  
  .SearchField input {
    font-size:1rem !important;
  }
  
  
  
  @media (max-width: 768px) {
    .SearchField {
      width:90vw !important;
    }
  }
  
  
  @media (max-width: 768px) {
    .SearchField input {
      font-size:0.75rem !important;
    }
  }
  
  
  
  
  .BlogTitle{
    font-family: Runalto !important;
    color: var(--amplify-colors-purple-dark)!important;
    font-size: 3rem !important;
  }
  
  @media (max-width: 768px) {
    .BlogTitle{
      font-size: 0.9rem !important;
    }
  }
  
  
  .LogoTitleRow{
    border-bottom-style: solid !important;
    border-bottom-color:  var(--amplify-colors-orange-light) !important;
    border-bottom-width: 2px !important;
    background-color: transparent !important;
  }
  
  
  .Label{
    color: var(--amplify-colors-purple-dark)!important;
    font-weight: 600 !important;
  }
  
  .Description{
    color: var(--amplify-colors-black)!important;
    font-size: 1rem !important;
    width:  clamp(10vw, 30vw, 40vw) !important;
  }
  
  @media (max-width: 768px) {
    .Description {
        width: 90vw !important;
        padding: 1rem !important;
    }
  }
  
  
  .logoimg{
    border-style: solid;
    border-color: transparent !important;
    box-sizing: border-box;
    border-width: 2px;
  }
  
  
  @media screen and (max-width: 450px) { 
    .logoimg{
      height:8vh;
      width:15vw;
    }
  }
  
  @media screen and (min-width: 451px) {
    .logoimg{
      height:8vh;
      width:4vw;
    }
  }
  
  
  .LogoBlogTitleRow{
    border-bottom-style: solid !important;
    border-bottom-color:  var(--amplify-colors-purple-dark) !important;
    border-bottom-width: 2px !important;
    background-color: transparent !important;
  }
  
  @media (max-width: 768px) {
    .LogoBlogTitleRow{
      border-bottom-style: solid !important;
      border-bottom-color: var(--amplify-colors-darkgrey) !important;
      border-bottom-width: 2px !important;
    }  
  }
  
  .CategoryRow{
    border-bottom-style: solid !important;
    border-bottom-color:  var(--amplify-colors-turquoise-light) !important;
    border-bottom-width: 2px !important;
    background-color: var(--amplify-colors-purple-dark) !important;
  }
  
  .HeaderSection{  
    position: relative !important;
    display: block !important;
  }
  
  
  @media (max-width: 768px) {
    .HeaderSection{  
      position: relative !important;
      top:3vh !important;
      display: block !important;
    }
  }
  
  .Button {
    width:  10vw !important;
    text-decoration: none !important;
    letter-spacing: 0.1vw !important;
    height:3vh !important;
    border-width: 2px;
    font-size: 1em !important;
    color: var(--amplify-colors-black) !important; 
    border-color: var(--amplify-colors-orange-light) !important; 
    background-color: var(--amplify-colors-orange-light) !important; 
    background-image:   
      linear-gradient(180deg, 
        var(--clr2) var(--gradgap), 
        transparent calc(100% - var(--gradgap))
      );
    background-repeat: no-repeat;
    background-position: center var(--gradoffset);
    background-size: 100% 200%;
    border-radius: 2em;
    border: none;    
    box-shadow: 
      0 -0.5em 0.5em transparent,
      0 0.5em 0.5em transparent,
      0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
      0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);
  
    position: relative;
    transition: all 0.5s ease;
    outline: none;
    
    &::before, &::after {
      content: "";
      inset: 0;
      position: absolute;
      border-radius: 5em;
    }
  
    &::before {
      background-image: 
        radial-gradient(ellipse, 
          hsla(var(--hue), 100%, 90%, .8) 20%,
          transparent 50%,
          transparent 200%
        ),
        linear-gradient(90deg, 
          hsl(0deg, 0%, 25%) -10%, 
          transparent 30%, 
          transparent 70%, 
          hsl(0deg, 0%, 25%) 110%
        );
      box-shadow: 
        inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
        inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
        inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
      background-blend-mode: overlay;
      background-repeat: no-repeat;
      background-size: 200% 80%, cover;
      background-position: center 220%;
      mix-blend-mode: overlay;
      filter: blur(calc(var(--blur) * 0.5));
    }
    
    &::after {
      background: linear-gradient(
        180deg, 
        hsla(var(--hue2),100%,90%,.9), 
        hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
        transparent 80%
      );
      top: 0.075em;
      left: 0.75em;
      right: 0.75em;
      bottom: 1.4em;
      filter: blur(var(--blur));
      mix-blend-mode: screen;
    }
    
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: 
        0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
        0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
        0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
        0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
        inset 0 -2px 2px rgba(255,255,255,0.2);
      background-position: center calc( var(--gradoffset) - 0.75em );
    }  
  }
  
  
  @media screen and (max-width: 768px) {
    .Button {
      width: clamp(30vw, 30vw, 90vw) !important;
      height:5vh !important;
      font-size: 0.75rem!important;
      line-height: 1.1!important;
      letter-spacing: 0.1vw!important;
      width:20vw !important;
      font-family: Graphik, Arial, Helvetica, sans-serif!important;
    
      --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
      --amplify-components-button-color: var(--amplify-colors-white) !important;
      color:var(--amplify-colors-black) !important;
      font-weight:800 !important;
      
    }
  }
  
  
  .ButtonCategory {
    width:  clamp(10vw, 17vw, 20vw) !important;
    text-decoration: none !important;
    letter-spacing: 0.1vw !important;
    height:3vh !important;
    border-width: 2px;
    font-size: 0.8em !important;
    color: var(--amplify-colors-black) !important; 
    border-color: var(--amplify-colors-turquoise-light) !important; 
    background-color: var(--amplify-colors-purple-light) !important; 
    background-image:   
      linear-gradient(180deg, 
        var(--clr2) var(--gradgap), 
        transparent calc(100% - var(--gradgap))
      );
    background-repeat: no-repeat;
    background-position: center var(--gradoffset);
    background-size: 100% 200%;
    border-radius: 2em;
    border: none;    
    box-shadow: 
      0 -0.5em 0.5em transparent,
      0 0.5em 0.5em transparent,
      0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
      0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);
  
    position: relative;
    transition: all 0.5s ease;
    outline: none;
    
    &::before, &::after {
      content: "";
      inset: 0;
      position: absolute;
      border-radius: 5em;
    }
  
    &::before {
      background-image: 
        radial-gradient(ellipse, 
          hsla(var(--hue), 100%, 90%, .8) 20%,
          transparent 50%,
          transparent 200%
        ),
        linear-gradient(90deg, 
          hsl(0deg, 0%, 25%) -10%, 
          transparent 30%, 
          transparent 70%, 
          hsl(0deg, 0%, 25%) 110%
        );
      box-shadow: 
        inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
        inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
        inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
      background-blend-mode: overlay;
      background-repeat: no-repeat;
      background-size: 200% 80%, cover;
      background-position: center 220%;
      mix-blend-mode: overlay;
      filter: blur(calc(var(--blur) * 0.5));
    }
    
    &::after {
      background: linear-gradient(
        180deg, 
        hsla(var(--hue2),100%,90%,.9), 
        hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
        transparent 80%
      );
      top: 0.075em;
      left: 0.75em;
      right: 0.75em;
      bottom: 1.4em;
      filter: blur(var(--blur));
      mix-blend-mode: screen;
    }
    
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: 
        0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
        0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
        0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
        0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
        inset 0 -2px 2px rgba(255,255,255,0.2);
      background-position: center calc( var(--gradoffset) - 0.75em );
    }  
  }
  
  
  @media screen and (max-width: 768px) {
  .ButtonCategory {
    width: clamp(10vw, 90vw, 90vw) !important;
    height:7vh !important;
    font-size: 1rem!important;
    line-height: 1.3!important;
    letter-spacing: 0.1vw!important;
    font-weight: 20!important;
    font-family: Graphik, Arial, Helvetica, sans-serif!important;
  
    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    --amplify-components-button-color: var(--amplify-colors-white) !important;
    color:var(--amplify-colors-black) !important;
    font-weight:800 !important;
    
  }
  }
  
  .TextCategory{
    color: var(--amplify-colors-orange-light) !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    padding: 1rem !important;    
    cursor: pointer !important;
  }
  
  .KeyWordRow{
    border-bottom-style: solid !important;
    border-bottom-color: transparent !important;
    border-bottom-width: 2px !important;
    background-color: #b8b8ff !important;
    height:3vh !important;
  }
  
  .TrendRow{
    border-bottom-style: solid !important;
    border-bottom-color: transparent !important;
    border-bottom-width: 2px !important;
    background-color: #d4d4f9 !important;
    height:3vh !important;
  
  }
  
  .TextKeyWord{
    color: var(--amplify-colors-purple-dark) !important;
    font-size: 0.9rem !important;
    cursor: pointer !important;
  }
  
  .TextKeyWordHeader{
    color: var(--amplify-colors-purple-dark) !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
  }
  
  .PageTitle{
    color: var(--amplify-colors-purple-dark)!important;
    font-size: 2rem !important;
    font-weight: 600 !important;   
    cursor: pointer !important;
  }
  
  .TextSubcategory{
    color: var(--amplify-colors-turquoise-dark)!important;
    font-size: 1rem !important;
    font-weight: 600 !important;   
    cursor: pointer !important;
  
  }
  
  .TextCategory{
      color: var(--amplify-colors-orange-light) !important;
      font-size: 1.1rem !important;
      font-weight: 700 !important;
      padding-right: 1vw !important;    
      cursor: pointer !important;
    }
  
  .CellPhoneCategoriesFlex{
    position:relative !important;
    left:10vw !important;
    top:10vh!important;
    width: 70vw !important;
  }

  .CellPhoneCategoriesTitle{
    font-size: 1rem !important;
    color: var(--amplify-colors-orange-dark) !important;
    font-weight: 600 !important;
    padding-bottom:2rem !important;
  }

  .CellPhoneCategoriesTitles{
    font-size: .75rem !important;
    color: var(--amplify-colors-purple-dark) !important;
    font-weight: 400 !important;
    padding-bottom:0.5rem !important;
  }

  
.ButtonRow{
  padding-top:5vh !important;
}