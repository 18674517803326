
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.SectionContainer{
  position: relative !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
  overflow: hidden !important;
}

.Foreground{
  position: absolute;   
  top:0vh !important; 
  z-index: 700 !important;
  height: 100vh !important;
  width: 100vw !important;
}

.Background {  
  position: absolute !important; 
  top:0vh !important; 
  z-index: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
}
@media screen and (max-width: 450px) {
  .Background {
    position: absolute !important; 
    top:0vh !important; 
  }
}

.TextFlexColumn{
  position: relative !important;
  z-index: 100 !important;
  width:100vw !important;
  left:8vw !important;
  gap: 0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}



.HeaderTextRow {
  position: relative !important;
  top:25vh !important;
  width:100vw !important;
  gap:3vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}
@media screen and (max-width: 450px) {
  .HeaderTextRow {
    top:5vh !important;
  }
}


.HeaderSubTextRow{
  position: relative !important;
  top:25vh !important;
  left:10vw !important; 
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .HeaderSubTextRow {
    top:5vh !important;
  }
}
.HeaderText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 400 !important;
  line-height: 1em !important;
  width: 50vw !important;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



@media screen and (max-width: 450px) {
  .HeaderText {
    left: 1vw !important;
    font-size: 2em !important;
    width: 90vw !important;
  }
}


.HeaderSubText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3em !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-darkgrey) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}




@media screen and (max-width: 450px) {
  .HeaderSubText {
    left: 3vw !important;
    font-size: 1em !important;
    width: 80vw !important;
    line-height: 1.5em !important;
  }
}

.SubSubText{
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 300 !important;
  line-height: 1em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-darkgrey) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .SubSubText {
    left: 3vw !important;
    font-size: 1em !important;
    width: 80vw !important;
    line-height: 1.5em !important;
  }
}


.MayanNumber{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:7vw !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  flex-wrap: nowrap !important;
  column-gap: 0em !important;
}


.MayanDot{
  position: relative !important;
  top:0vh !important;
  left:0vw !important; 
  font-family: Graphik, Arial, Helvetica, sans-serif;
  color: var(--amplify-colors-black) !important;
  font-size: 4em !important;
  font-weight: 500  !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  margin:0em !important;
  padding:0em !important;
  opacity:1 !important;
  line-height:0.5em !important;
}

@media screen and (max-width: 450px) {
  .MayanDot {
    font-size: 2em !important;
  }
}

.MayanLine {
  height:3vh !important;
  width:6vw !important;
}

@media screen and (max-width: 450px) {
  .MayanLine {
    width:10vw !important;
  }
}
.ApplicationLinkMotion{
  position:absolute !important;
  z-index:999 !important;
}

.ApplicationLink{
  position: relative !important;
  top:80vh !important;
  left:18vw !important; 
  z-index:999 !important;
}

@media screen and (max-width: 450px) {
  .ApplicationLink {
    top:70vh !important;
    left:24vw !important; 
  }
}

.TurboFanBoostButton{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



.Chris{
  position:relative !important;
  z-index:999 !important;
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 3em !important;
  font-weight: 900 !important;
}