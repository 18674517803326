body {    
  background-color: #1f1f1f;
}

.RhyteItBlobCanvasDiv{
  position:absolute;
  z-index:-100;
  height:100vh;
  width:100vw;

}

.RhyteItBlobCanvas{
  position:relative;
  top:10vh;
  left: 60vw;
  height:35vh;
  width:35vw;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;

}
