
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}

html {

  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

.App {
  background-color: var(--amplify-colors-black) !important;
}

.Main {
  background-color: var(--amplify-colors-black) !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: black;
  z-index: 200;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-footer {
  position: absolute;
  height: 10%;
  bottom: 0;
  background-color: #1f1f1f;
}

.App-intro {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  bottom: 0;
  position:absolute;
  left:0;
  background-color: var(--amplify-colors-black) !important;
}

.FooterPosition {
  position: fixed;
  padding-bottom:60px;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 40;}

 
  @media(max-width:450px) {
    .App-intro {
      position:absolute;
      left:0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
      z-index: 1;
    }
  }
