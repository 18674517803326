@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  
  }


    
  @media screen and (max-width: 450px) {
    .Table {
      --amplify-components-table-width: 90vw !important;
    } 

  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .Table {
      --amplify-components-table-width: 90vw !important;
    } 
  } 

  @media screen and (min-width: 1001px) {
      .Table {
        --amplify-components-table-width: 50vw !important;
      } 
    } 

  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }

  
      
  @media screen and (max-width: 450px) {
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCells{
      font-size: 20px !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  .TableHeaderRowCellsWide{
    border-bottom-color: var(--amplify-colors-orange) !important; 
    border-bottom-style: solid !important;  
    border-bottom-width: 2px !important;  
  }

  @media screen and (max-width: 450px) {
    .TableHeaderRowCellsWide{
      width: 50vw !important;
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCellsWide{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCellsWide{
      font-size: 20px !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
    }
  } 

  .TableHeaderRowCellsActions{
    border-bottom-color: var(--amplify-colors-orange) !important; 
    border-bottom-style: solid !important;  
    border-bottom-width: 2px !important;  
  }

  @media screen and (max-width: 450px) {
    .TableHeaderRowCellsActions{
      width: 40vw !important;
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCellsActions{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCellsActions{
      font-size: 20px !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
    }
  } 

  
  .RedditTitleButtons {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-grey) !important;
    border-color:  transparent !important;
    padding: 100px 10px;
  }

  @media screen and (max-width: 450px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1em !important;
      height:  auto  !important;
      width:  30vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 0.8em !important;
      height:  10vh  !important;
      width:  20vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 1001px) {
    .RedditTitleButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1em !important;
      height:  3vh  !important;
      width:  20vw  !important;
      padding: 0px 0px;
    }
  }


  @media screen and (max-width: 450px) {
    .LineGraph {
      height:  60vh  !important;
      width:  90vw  !important;
      font-size:0.5em !important;
      margin-top: 10px;
    }
  }

  

  @media screen and (min-width: 1001px) {
    .LineGraph {
      height:  60vh  !important;
      width:  60vw  !important;
      margin-top: 10px;
    }
  }

  