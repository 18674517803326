@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }


  .MarqueeWrapper{
    overflow: hidden;
    margin: 0 auto !important;
  }

  .MarqueeBlock{    
    flex: 1;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    float: left;
  }

  .MarqueeInner{
    position: relative;
    display: inline-block;
    padding: 1em !important;
    animation: marqueeTop 20s linear infinite;
    animation-timing-function: linear;
    &:hover {
      animation-play-state: paused;
  }
}

@keyframes marqueeTop {
  0% {
    transform: translateY(-350vh);
  }
  100% {
    transform: translateY(45vh);
  }
}

  .ImageContainer{
    width:11vw !important;
    height:15vh !important;
    transition: all 0.2s ease-out;
    padding: 1em !important;
    margin: 6em !important;
  }

  .PhotoImage {
    width:50vw !important;
    padding: 1em !important;
    opacity: 1 !important;
  }

  @media screen and (max-width: 450px) {
  .PhotoImage {
    width:20vw !important;
    height:11vh !important;
    opacity: 0.75 !important;
  }
}
.Text{
  color: var(--amplify-colors-turquoise-light)!important;
  font-weight: 500 !important;
  font-size: 1em !important;
  padding: 1em !important;
  opacity: 1 !important;
}
