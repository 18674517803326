@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  }

.BackgroundContainer{
  position:relative !important;
  z-index:-999 !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;  
  top:0 !important;
  left:0 !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  background-color: white !important;
  overflow: hidden !important;
}



.PurpleBlob{
  position:absolute !important;
  z-index:-850 !important;
  height:100vh !important;
  width:100vw !important;
  filter:url("#OuterGlow1") !important;
}

.Image{
  position:absolute !important;
  top:0vh !important;
  z-index:-700 !important;
  opacity:0.5!important;
}


.canvas{
  position:absolute !important;
  z-index:-925 !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;

}

.PurpleBlob2{
  position:absolute !important;
  z-index:-850 !important;
  height:100vh !important;
  width:100vw !important;
  filter:url("#OuterGlow2") !important;
}


.BlobPath{
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}


