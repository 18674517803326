@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
    font-family: 'Mulish', sans-serif;
    font-size: 28px;
    color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  } 

  .Background{
    /* Set up the background to cover the full page */
    background-color: transparent !important;
    min-height: 200vh !important;
    margin: 0 !important;
}

  .TextField{
    --amplify-components-textfield-color: var(--amplify-colors-grey);
    --amplify-components-textfield-border-color:transparent !important;
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple);
  }

  
@media screen and (max-width: 768px) {  
  .TextField{
    font-size: 1em !important;
    --amplify-components-fieldcontrol-font-size: 0.75em !important;
  }
}

  .TextFieldTitle {
    --amplify-components-textfield-color: var(--amplify-colors-grey);
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise);
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple);
    width:30vw !important;
  }

  .TextFieldDateTime {
    --amplify-components-textfield-color: var(--amplify-colors-grey);
    --amplify-components-textfield-border-color: var(--amplify-colors-turquoise);
    --amplify-components-textfield-focus-border-color: var(--amplify-colors-purple);
    width:10vw !important;
  }

  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }

  .TableCellActions{
    width:20vw !important;
  }
  .TableCellPubDate{
    width:10vw !important;
  }


   .TableRowBackgroundTitle{
    background-color: transparent !important;
    width:50vw !important;
    color: var(--amplify-colors-darkgrey) !important;    
    border-color: transparent !important;
  }

  .TextStringPresent{
    background-color: var(--amplify-colors-turquoise-light) !important;
    width:50vw !important;
    color: var(--amplify-colors-darkgrey) !important;
  }

  

  
  .ErrorMessageRow{
    background-color: var(--amplify-colors-orange-light) !important;
    color: var(--amplify-colors-darkgrey) !important;
    --amplify-internal-table-td-font-size: 0.7em !important;
    height:auto !important;

  }

  .Button {
    text-decoration: none !important;
    --amplify-components-button-font-size:0.75rem !important;
    height: 3vh !important;
    width: 5vw !important;
    border-width: 2px;
    color: var(--amplify-colors-black) !important;
    border-color: var(--amplify-colors-turquoise-light) !important;
    background-color: var(--clr);
    background-image:
      linear-gradient(180deg,
        var(--clr2) var(--gradgap),
        transparent calc(100% - var(--gradgap)));
    background-repeat: no-repeat;
    background-position: center var(--gradoffset);
    background-size: 100% 200%;
    border-radius: 2em;
    border: none;
    box-shadow:
      0 -0.5em 0.5em transparent,
      0 0.5em 0.5em transparent,
      0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46),
      0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);
  
    position: relative;
    transition: all 0.5s ease;
    outline: none;
    margin-right: 1rem !important;
  
    &::before, &::after {
      content: "";
      inset: 0;
      position: absolute;
      border-radius: 5em;
    }
  
    &::before {
      background-image:
        radial-gradient(ellipse,
          hsla(var(--hue), 100%, 90%, .8) 20%,
          transparent 50%,
          transparent 200%),
        linear-gradient(90deg,
          hsl(0deg, 0%, 25%) -10%,
          transparent 30%,
          transparent 70%,
          hsl(0deg, 0%, 25%) 110%);
      box-shadow:
        inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
        inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
        inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
      background-blend-mode: overlay;
      background-repeat: no-repeat;
      background-size: 200% 80%, cover;
      background-position: center 220%;
      mix-blend-mode: overlay;
      filter: blur(calc(var(--blur) * 0.5));
    }
  
    &::after {
      background: linear-gradient(180deg,
          hsla(var(--hue2), 100%, 90%, .9),
          hsla(var(--hue2), calc(var(--sat2)*0.7), 50%, .75) 40%,
          transparent 80%);
      top: 0.075em;
      left: 0.75em;
      right: 0.75em;
      bottom: 1.4em;
      filter: blur(var(--blur));
      mix-blend-mode: screen;
    }
  
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow:
        0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
        0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
        0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%),
        0 0.25em 0.5em hsla(var(--hue), 20%, 30%, 0.2),
        inset 0 -2px 2px rgba(255, 255, 255, 0.2);
      background-position: center calc(var(--gradoffset) - 0.75em);
    }
  }
  
  
  @media screen and (max-width: 450px) {
    .Button {
      width: clamp(20vw, 20vw, 20vw) !important;
      height: 5vh;
      margin-top: 2vh;
      border-width: 2px;
      border-radius: 0;
      border-color: var(--amplify-colors-turquoise);
      background-color: transparent;
      font-size: 20px;
      line-height: 1.3;
      letter-spacing: 0.1vw;
      font-weight: 20;
      font-family: Graphik, Arial, Helvetica, sans-serif;
  
      --amplify-components-fieldcontrol-color: var(--amplify-colors-purple) !important;
      --amplify-components-button-color: var(--amplify-colors-white) !important;
      color: var(--amplify-colors-white) !important;
      font-weight: 800 !important;
    }
  }
  
  
  .Button:hover {
    border-color: var(--amplify-colors-turquoise);
    background-color: var(--amplify-colors-orange);
    --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-black) !important;
    font-weight: 800 !important;
  }
  
  .Button:focus {
    border-color: var(--amplify-colors-turquoise);
    background-color: var(--amplify-colors-turquoise) !important;
    color: var(--amplify-colors-black);
  }
  
  .Button:disabled {
    border-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
    color: var(--amplify-colors-black);
  }
  
  
  .RedditTitleSpan{
    padding: 100px 10px;
  }

  .ToolBar{
    padding: 20px 10px;
  }

  .HideStatusRow {
    visibility: collapse;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .ShowStatusRow {
    background-image: url("https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo2.svg");
    visibility: visible;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .TableRowDataStatus{
    color: var(--amplify-colors-grey) !important;  

  }    


  .TableRowData{
    color: var(--amplify-colors-grey) !important;  
    text-align: center !important;
    vertical-align: middle !important;
  } 

  
  .TableRowBackground:hover {
    background-color: var(--amplify-colors-black) !important;
  }

  .TableRowDataERROR{
    background-color: darkred !important;
    color: var(--amplify-colors-grey) !important;  
  }

  .TableRowDataERROR:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:2.2vw  !important;
  }



  @media screen and (max-width: 450px) {
    .RhyteItMenuBackground {  
      background-color: var(--amplify-colors-purple) !important;
      width:12vw  !important;
      height:5vh  !important;
      align-items: center !important;
      border-color: transparent !important;
      border-style: solid !important;
      border-width: 2px !important;
    }
  }
  

  @media screen and (min-width: 451px) and (max-width: 1000px) {
  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:8.5vw  !important;
    height:12vh  !important;
  }
}

.RhyteItMenuCell {
  position: relative !important;
  align-content: center !important;

}

  span {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1.5em !important;
    font-weight: 100 !important;
  }


  .Link {
    font-family: sans-serif;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    color:var(--amplify-colors-darkgrey) !important;
  }


  @media screen and (max-width: 451px)  {
    .Link {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 1em !important;
      font-weight: 400 !important;
      color:var(--amplify-colors-darkgrey) !important;
    }   
  }
  
  @media screen and (min-width: 451px)  {
    .SubRow {
      font-family: Graphik, Arial, Helvetica, sans-serif;
      font-size: 0.9em !important;
      font-weight: 100 !important;
      color:var(--amplify-colors-orange) !important;
    }   
  }
  
  .TableCellParameter{
    width:10vw !important;
    border-color: transparent !important;
  }

  .TableCellValue{
    width:50vw !important;
    border-color: transparent !important;
  }

  .TextAreaField{
    --amplify-components-fieldcontrol-font-size: 0.75rem !important;
    background-color: var(--amplify-colors-purple-light) !important;
    border-color: transparent !important;
  }

  .TextAreaField input{
    --amplify-components-fieldcontrol-font-size: 0.75rem !important;
    background-color: var(--amplify-colors-purple-light) !important;
    border-color: transparent !important;
  }

  .TextField input{
    --amplify-components-fieldcontrol-font-size: 0.75rem !important;
    background-color: var(--amplify-colors-purple-light) !important;
  }

  .RowHeader{
    --amplify-components-fieldcontrol-font-size: 0.75rem !important;
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    color: var(--amplify-colors-purple-dark) !important;

  }

  .ButtonCell{
    border-color: transparent !important;
  }