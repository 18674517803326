

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  

.MenuContainer {
  position:relative !important;
  top: 10vh !important;
  left: 3vw !important;
  width: 90vw !important;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .MenuContainer {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    width: 90vw !important;      
    border-style: solid !important;
    border-width: 2px !important;
    border-color:transparent !important;
  }
}


@media screen and (min-width: 1501px) {
.MenuContainer {
  position:relative !important;
  top: 3vh !important;
  left: 3vw !important;
  width: 90vw !important;
}
}

.GridContainer {
  position:relative !important;
  top: 1vh !important;
  left: 3vw !important;
  width: 90vw !important;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .GridContainer {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    width: 90vw !important;      
    border-style: solid !important;
    border-width: 2px !important;
    border-color:transparent !important;
  }
}


@media screen and (min-width: 1501px) {
.GridContainer {
  position:relative !important;
  top: 3vh !important;
  left: 3vw !important;
  width: 90vw !important;
}
}


.MenuBackground{
  height:100vh;
  width:100vh;
}


@media screen and (max-width: 450px) {
  .RhyteItMenuContainer {
    position: relative !important;
    top: 10vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}


@media screen and (min-width: 451px) {
  .RhyteItMenuContainer {
    position: relative !important;
    top: 10vh !important;
    left:5vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}




@media screen and (max-width: 450px) {
  .ErrorMessage {
    position: relative !important;
    font-size: 0.5em !important;
    top: 10vh !important;
    color: var(--amplify-colors-grey) !important;
  }
}


@media screen and (min-width: 451px) {
  .ErrorMessage {
    position: relative !important;
    top: 10vh !important;
    left:5vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}


.TableImage{
  height: 10vh !important;
  width: 10vw !important;
}


.headerline {
  width: 50vw;
  color: var(--amplify-colors-grey) !important;
  border-color: var(--amplify-colors-turquoise)!important;
  animation: linegrowanimation 2s 1 forwards;
}

.Fieldset{
  width: 50vw;
  color: var(--amplify-colors-grey) !important;
  border-color: var(--amplify-colors-purple)!important;
  animation: linegrowanimation 2s 1 forwards;
  --amplify-components-fieldset-legend-color: var(--amplify-colors-orange) !important;
  --amplify-components-fieldset-legend-font-size:2em !important;
}

@keyframes linegrowanimation {
  0% {
    width: 0;
  }
  100% {
    width: 50vw;
  }
}



@media screen and (max-width: 450px) {
  .TableHeaderRow {
    --amplify-components-table-data-color:  var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}

@media screen and (min-width: 451px) {
  .TableHeaderRow {
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 1.5em !important;
    height:1.5vh !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
    background-color:transparent !important;
  }
}

.TableHeaderRow:hover {
  background-color:transparent !important;
}


@media screen and (max-width: 450px) {
  .TableRow {
    font-size:  0.75em !important;
    --amplify-components-table-data-color: var(--amplify-colors-purple) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    line-height: 2 !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}

@media screen and (min-width: 451px) {
  .TableRow {
    --amplify-components-table-data-color: var(--amplify-colors-purple) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    line-height: 2 !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}


.TableRow:hover {
  --amplify-components-table-data-color: var(--amplify-colors-orange) !important;
  background-color: var(--amplify-colors-black) !important;
}

.Link {
  color:var(--amplify-colors-grey) !important;
}


.TableRowDescription{
  color: var(--amplify-colors-orange) !important;
  font-family:ConigenReg !important;
}

.NavButton {
  width: clamp(5vw,10vw,20vw) !important;
  text-decoration: none !important;
  letter-spacing: 0.1vw !important;
  height:3vh !important;
  border-width: 2px;
  padding:1rem !important;
  font-size: clamp(0.5rem,0.75rem,1.2rem) !important;
  color: var(--amplify-colors-black) !important; 
  border-color: var(--amplify-colors-orange-light) !important; 
  background-color: var(--amplify-colors-turquoise-light) !important; 
  background-image:   
    linear-gradient(180deg, 
      var(--clr2) var(--gradgap), 
      transparent calc(100% - var(--gradgap))
    );
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  border-radius: 2em;
  border: none;    
  box-shadow: 
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  
}

.NavButton:hover {
  width: clamp(5vw,10vw,20vw) !important;
  text-decoration: none !important;
  letter-spacing: 0.1vw !important;
  height:3vh !important;
  border-width: 2px;
  font-size: 1rem;
  color: var(--amplify-colors-black) !important; 
  border-color: var(--amplify-colors-turquoise-light) !important; 
  background-color: var(--amplify-colors-orange-light) !important; 
  background-image:   
    linear-gradient(180deg, 
      var(--clr2) var(--gradgap), 
      transparent calc(100% - var(--gradgap))
    );
  background-repeat: no-repeat;
  background-position: center var(--gradoffset);
  background-size: 100% 200%;
  border-radius: 2em;
  border: none;    
  box-shadow: 
    0 -0.5em 0.5em transparent,
    0 0.5em 0.5em transparent,
    0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
    0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);

  position: relative;
  transition: all 0.5s ease;
  outline: none;
  
}


.Text{
  color: var(--amplify-colors-orange-light) !important;
  font-family:ConigenReg !important;
  font-size:2em !important;

}

@media screen and (max-width: 768px) {
.Text{
  color: var(--amplify-colors-purple-light) !important;
  font-family:ConigenReg !important;
  font-size:1em !important;

}
}

.MenuHeaderText{
  color: var(--amplify-colors-orange-light) !important;
  font-family:ConigenReg !important;
  font-size:2em !important;

}

@media screen and (max-width: 768px) {
.MenuHeaderText{
  color: var(--amplify-colors-purple-light) !important;
  font-family:ConigenReg !important;
  font-size:1em !important;

}

}

.FlexRowButton{
  width:90vw !important;
}