@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  .PhotoImage {
    width:6vw !important;
    height:12vh !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: center !important; 
  }




  @media screen and (max-width: 450px) {
  .PhotoImage {
    width:22vw !important;
    height:12vh !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: center !important; 
  }
  }

  .button {
    --amplify-components-button-border-color:transparent !important;

  }

  .View {
    background-color:transparent !important;
    padding:1em !important;
  }

  .BadgeRanking{
    background-color: var(--amplify-colors-purple) !important;
    font-size: 1em !important;
  }

  @media screen and (max-width: 450px) {
    .BadgeRanking {
      font-size: 0.75em !important;
      width:50vw !important;
    }
    }
  


  .BadgePrice{
    background-color: var(--amplify-colors-turquoise) !important;
    font-size: 1em !important;
  }
  
  
  @media screen and (max-width: 450px) {
    .BadgePrice {
      font-size: 0.75em !important;
      width:50vw !important;
    }
    }

  .Card{
    background-color: transparent !important;
  }

  .ImageLogo{
    height:3em !important;
    width:3em !important;
  }

  .HeadingContainer {
    height: 3em !important;
    line-height: 3em !important;
    text-align: center !important;
    border: 2px dashed transparent !important;
  }
  .Heading{
    display: inline-block !important;
    vertical-align: middle !important;
    line-height: normal !important;
    color:var(--amplify-colors-turquoise) !important;
  }