@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;  
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
  }  

  @media screen and (max-width: 450px) {
    .FlexColumn {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1500px) {
    .FlexColumn {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;      
      border-style: solid !important;
      border-width: 2px !important;
    }
  }

  
  @media screen and (min-width: 1501px) {
  .FlexColumn {
    position:relative !important;
    top: 20vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}



  .h1 {
    font-family: Runalto;
    font-size: 3rem !important;
    color: var(--amplify-colors-turquoise-light) !important;
    letter-spacing: 0.1rem !important;
  }
  
  @media screen and (max-width: 768px) {
    .h1 { 
      font-family: Graphik, sans-serif;
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1500px) {
    .h1 {
      font-family: Runalto;
      font-size: 1rem !important;
    }
  }

  .h2 {
    font-family: ConigenReg;
    font-size: 2rem !important;
    color: var(--amplify-colors-orange-light) !important;
    letter-spacing: 0.1rem !important;
  }
  
  @media screen and (max-width: 768px) {
    .h2{
      font-size: 1rem !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1500px) {
    .h2 {
      font-size: 0.75rem !important;
    }
  }

  .li {
    font-family: Graphik;
    font-size: 1.5rem !important;
    color: var(--amplify-colors-purple-light) !important;
    letter-spacing: 0.1rem !important;
    padding-top:2rem !important;
  }
  
  @media screen and (max-width: 768px) {
    .li{
      font-size: 0.75rem !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1500px) {
    .li {
      font-size: 0.5rem !important;
    }
  }


  .Button {
    width:  15vw !important;
    text-decoration: none !important;
    letter-spacing: 0.1vw !important;
    height:3vh !important;
    border-width: 2px;
    font-size: 1em !important;
    color: var(--amplify-colors-black) !important; 
    border-color: var(--amplify-colors-orange-light) !important; 
    background-color: var(--amplify-colors-orange-light) !important; 
    background-image:   
      linear-gradient(180deg, 
        var(--clr2) var(--gradgap), 
        transparent calc(100% - var(--gradgap))
      );
    background-repeat: no-repeat;
    background-position: center var(--gradoffset);
    background-size: 100% 200%;
    border-radius: 2em;
    border: none;    
    box-shadow: 
      0 -0.5em 0.5em transparent,
      0 0.5em 0.5em transparent,
      0 0.25em 0.3em -0.2em hsla(var(--hue), var(--sat), 50%, 0.46), 
      0 0.25em 0.75em hsla(var(--hue), calc(var(--sat) - 10%), 40%, 0.3);
  
    position: relative;
    transition: all 0.5s ease;
    outline: none;
    
    &::before, &::after {
      content: "";
      inset: 0;
      position: absolute;
      border-radius: 5em;
    }
  
    &::before {
      background-image: 
        radial-gradient(ellipse, 
          hsla(var(--hue), 100%, 90%, .8) 20%,
          transparent 50%,
          transparent 200%
        ),
        linear-gradient(90deg, 
          hsl(0deg, 0%, 25%) -10%, 
          transparent 30%, 
          transparent 70%, 
          hsl(0deg, 0%, 25%) 110%
        );
      box-shadow: 
        inset 0 .25em .75em hsla(0deg, 0%, 0%, 0.8),
        inset 0 -.05em .2em rgba(255, 255, 255, 0.4),
        inset 0 -1px 3px hsla(var(--hue), 80%, 50%, .75);
      background-blend-mode: overlay;
      background-repeat: no-repeat;
      background-size: 200% 80%, cover;
      background-position: center 220%;
      mix-blend-mode: overlay;
      filter: blur(calc(var(--blur) * 0.5));
    }
    
    &::after {
      background: linear-gradient(
        180deg, 
        hsla(var(--hue2),100%,90%,.9), 
        hsla(var(--hue2),calc(var(--sat2)*0.7),50%,.75) 40%, 
        transparent 80%
      );
      top: 0.075em;
      left: 0.75em;
      right: 0.75em;
      bottom: 1.4em;
      filter: blur(var(--blur));
      mix-blend-mode: screen;
    }
    
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: 
        0 -0.2em 1em hsla(var(--hue2), 70%, 80%, 0.3),
        0 0.5em 1.5em hsla(var(--hue), 70%, 80%, 0.5),
        0 0.25em 0.3em -0.2em hsl(var(--hue) 90% 70%), 
        0 0.25em 0.5em hsla(var(--hue),20%,30%, 0.2),
        inset 0 -2px 2px rgba(255,255,255,0.2);
      background-position: center calc( var(--gradoffset) - 0.75em );
    }  
  }
  
  @media screen and (max-width: 768px) {
    .Button {
      width: clamp(30vw, 30vw, 90vw) !important;
      height:5vh !important;
      font-size: 0.75rem!important;
      line-height: 1.1!important;
      letter-spacing: 0.1vw!important;
      font-weight: 20!important;
      width:20vw !important;
      font-family: Graphik, Arial, Helvetica, sans-serif!important;
    
      --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
      --amplify-components-button-color: var(--amplify-colors-white) !important;
      color:var(--amplify-colors-black) !important;
      font-weight:800 !important;
      
    }
  }
   
  @media screen and (min-width: 769px) and (max-width: 1500px) {
    .Button {
      width: clamp(30vw, 30vw, 90vw) !important;
      height:5vh !important;
      font-size: 1rem!important;
      line-height: 1.1!important;
      letter-spacing: 0.1vw!important;
      font-weight: 20!important;
      width:20vw !important;
      font-family: Graphik, Arial, Helvetica, sans-serif!important;
    
      --amplify-components-fieldcontrol-color: var(--amplify-colors-orange) !important;
      --amplify-components-button-color: var(--amplify-colors-white) !important;
      color:var(--amplify-colors-black) !important;
      font-weight:800 !important;
      
    }
  }
  