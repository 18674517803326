[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  font-family: Graphik, Arial, Helvetica, sans-serif;
}

.ValuePropAlerting{  
  position: relative;
  height:5vh !important;
  width:50vw !important;
  opacity: 1; 
  color:rgb(204,204,204);
  left: 3vw;
  margin-bottom: 3vh;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-weight: 20;
  line-height: 1.7 ;
  letter-spacing: 0.1vw;  
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;  
  box-sizing: border-box;
  z-index: 2000;
}



@media screen and (max-width: 450px) {
    .ValuePropAlerting{  
      position: relative;
      font-size: 1em;
      left: 5vw;
      width:90vw !important;
      line-height: 1.7;
      letter-spacing: 0.1vw;    
      margin-bottom: 2vh;
    }
  }


  @media screen  and (min-width: 451px)  and (max-width: 900px) {  
  .ValuePropAlerting{  
    position: relative;
    font-size: 1em !important;
    left: 5vw;
    width:80vw;
    line-height: 1.7;
    letter-spacing: 0.1vw;    
    height: 7vh;
    }
  }


  @media screen  and (min-width: 901px)  and (max-width: 2000px) {  
    .ValuePropAlerting{  
      position: relative;
      font-size: 1em !important;
      left: 5vw;
      width:80vw;
      line-height: 1.7;
      letter-spacing: 0.1vw;    
      height: 7vh;
      }
    }

  @media screen  and (min-width: 2001px) {  
    .ValuePropAlerting{  
      position: relative;
      font-size: 2em !important;
      left: 3vw;
      width:80vw;
      line-height: 1.7;
      letter-spacing: 0.1vw;    
      height: 7vh;
  }
}
   