@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  
  }

    
  .RhyteItTable {
    --amplify-components-table-width: 50vw !important;
  }

  @media screen and (min-width: 451px)  {
  .RhyteItTable {
    --amplify-components-table-width: 90vw !important;
  }
  }
  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }

  .TableHeaderRowCells{
    font-size: 20px !important;
    color:var(--amplify-colors-turquoise) !important;
  }
  
  
  @media screen and (max-width: 900px) {

  
    [data-amplify-theme="studioTheme"]  {
      --amplify-colors-grey: #CCCBCA;
      --amplify-colors-darkgrey: #333333;
      --amplify-colors-black: #000000;
      --amplify-colors-turquoise: #00CCCC;
      --amplify-colors-orange: #CC6633;
      --amplify-colors-purple: #9999CC;
      --amplify-components-fieldcontrol-font-size:11px !important;
      --amplify-internal-table-td-font-size:11px !important;
    }
    
    .RhyteItTable {
      --amplify-components-table-width: 50vw !important;
    } 
    body {
        font-family: 'Graphik', Arial, Helvetica, sans-serif;  
        font-size: var(--amplify-components-fieldcontrol-font-size) !important;
        color: #CCCBCB;
    }
    
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    }
    
  }