@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  
  }


    
  @media screen and (max-width: 450px) {
    .Table {
      --amplify-components-table-width: 90vw !important;

    } 

  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .Table {
      --amplify-components-table-width: 90vw !important;
    } 
  } 

  @media screen and (min-width: 1001px) {
      .Table {
        --amplify-components-table-width: 50vw !important;
        --amplify-components-table-data-border-color: transparent !important;
      } 
    } 

  .TableHeaderRow{
    --amplify-components-table-header-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black) !important;
  }

  
      
  @media screen and (max-width: 450px) {
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCells{
      font-size: 1em !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
      border-bottom-style: solid !important;  
      border-bottom-width: 2px !important;  
    }
  } 

  .TableHeaderRowCellsWide{
    border-bottom-color: var(--amplify-colors-orange) !important; 
    border-bottom-style: solid !important;  
    border-bottom-width: 2px !important;  
  }

  @media screen and (max-width: 450px) {
    .TableHeaderRowCellsWide{
      width: 50vw !important;
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCellsWide{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCellsWide{
      font-size: 20px !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
    }
  } 

  .TableHeaderRowCellsActions{
    border-bottom-color: var(--amplify-colors-orange) !important; 
    border-bottom-style: solid !important;  
    border-bottom-width: 2px !important;  
  }

  @media screen and (max-width: 450px) {
    .TableHeaderRowCellsActions{
      width: 10vw !important;
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .TableHeaderRowCellsActions{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
      color:var(--amplify-colors-turquoise) !important;  
    }
  } 

  @media screen and (min-width: 1001px) {
    .TableHeaderRowCellsActions{
      font-size: 20px !important;
      color:var(--amplify-colors-turquoise) !important;    
      border-bottom-color: var(--amplify-colors-orange) !important; 
    }
  } 

  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;    
    --amplify-components-select-color: var(--amplify-colors-grey);
    background-color: transparent!important;
    color: var(--amplify-colors-turquoise)!important;
    width:20vw !important;
    font-weight:500 !important;
  }

  @media screen and (max-width: 450px) {
    .SelectField {
      width:50vw !important;
    }

  }

  .SelectField Option {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-turquoise)!important;
  }
  .Divider{
    border-color:linear-gradient( --amplify-colors-orange, --amplify-colors-purple) !important;
    border-width: 0.5em !important;
    border-radius: 1em !important;
    border-style: solid !important;
    width:30vw !important;
  }
  
  .ShortDivider{
    border-color:linear-gradient( --amplify-colors-orange, --amplify-colors-purple) !important;
    border-width: 0.5em !important;
    border-radius: 1em !important;
    border-style: solid !important;
    color:var(--amplify-colors-purple) !important;
    width:10vw !important;
  }
  
  .Text{
    color:white !important;
    font-weight:500 !important;
    font-size:1em !important;
    width:10vw !important;
  }
  
  .GridContainer{
    border-color: transparent!important;
    border-width: 0.25em !important;
    border-radius: 1em !important;
    border-style: solid !important;

  }

  
  @media screen and (max-width: 450px) {
    .GridContainer{
      position:relative !important;
      left:-10 !important;
      border-color: transparent!important;
      border-width: 0.25em !important;
      border-radius: 1em !important;
      border-style: solid !important;  
    }
    }
  
  .Fieldset{
    border-top-color:white !important;
    border-top-width: 0.25em !important;
    border-top-style: solid !important;
    font-weight:500 !important;
    font-size:1em !important;
    color:white !important;
    --amplify-components-fieldset-legend-color:white !important;
    width:50vw !important;
    padding:4em !important;
    margin-top:4em !important;  
  }

    
  @media screen and (max-width: 450px) {
    .Fieldset{
      width:90vw !important;
      padding-top:4em !important;
      margin-top:4em !important;  
    }
    }
  
    .Button {
      color:white !important;
      background: transparent !important;
      border-color:var(--amplify-colors-orange) !important;
      --amplify-internal-button-color: var(--amplify-colors-grey) !important;
      --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
      height:3vh !important;
      font-size: 0.75em !important;
    }
    
    @media screen and (max-width: 450px) {
      .Button{
        height:4.5vh !important;
        width:30vw !important;
        font-size: 0.5em !important;
      }
    }
    
    .Button:hover {
      color:black !important;
      background:var(--amplify-colors-turquoise) !important;
      border-color:var(--amplify-colors-purple) !important;
      --amplify-internal-button-color: var(--amplify-colors-grey) !important;
      --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
      height:3vh !important;
    }

    .CheckboxField:checked{
      border-color:var(--amplify-colors-orange) !important;
      border-width: 2em !important;
      border-style: solid !important;
    }

    .Label{
      font-size: 0.25 !important;
      color:white !important;      
    }

    .FlexRow{
      width: 90vw !important;
    }

    .CampaignField{
      color:white !important;  
      width: 30vw !important;  
      border-color:var(--amplify-colors-purple) !important;
    }

    
    .CampaignField input{
      border-color:var(--amplify-colors-purple) !important;
      color:white !important; 
    }

    .CampaignField input:focus{
      border-color:var(--amplify-colors-purple) !important;
      color:white !important; 
    }