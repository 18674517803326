@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  }


  .ButtonDiv{
    position:relative;
    height:9vh !important;
    left:0vw !important;
    width:15vw !important;
    z-index:999 !important; 
    display:flex !important; 
    flex-direction:column !important;
    align-items: center;
    justify-content: center;
    border-radius: 25px !important;
    border-color:  transparent !important;
    border-style:  solid !important;
    border-width:  2px !important;
    overflow: hidden !important;
    background-color: var(--amplify-colors-orange-light) !important;
    box-shadow: 1px 1px 20px #CCCCFF !important;
  }


  @media screen and (max-width: 450px) {  
    .ButtonDiv{
      height:12vh !important;
      width:50vw !important;
      box-shadow: 2px 1px 40px #CCCCFF !important;
    }
  }

  
  @media screen and (min-width: 451px) and (max-width: 1350px) {  
    .ButtonDiv{
      height:9vh !important;
      width:30vw !important;
      box-shadow: 2px 1px 40px #CCCCFF !important;
    }
  }

.ButtonDiv:focus {
  transform: translateY(4px);
}

.canvasBackGround{
  position:absolute;  
  top:-10 !important;
  left:-10 !important;
  height:10vh !important;
  width:20vw !important;
  z-index:-2 !important;  
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {  
  .canvasBackGround{
    height:15vh !important;
    width:60vw !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1350px) {  
  .canvasBackGround{
    width:30vw !important;
  }
}


.canvas{
  position:absolute;  
  top:0 !important;
  left:0 !important;
  height:10vh !important;
  width:20vw !important;
  z-index:-1 !important;  
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  4px !important;
}

@media screen and (max-width: 450px) {  
  .canvas{
    height:15vh !important;
    width:60vw !important;
  }
}

.Button{
  position:relative !important; 
  height:7vh !important;
  width:15vw !important;
  z-index:700 !important; 
  border-radius: 1em !important;
  background-color: transparent !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}

@media screen and (max-width: 450px) {  
  .Button{
    width:60vw !important;
  }
}


.ButtonTextDiv{
  position:absolute;  
  height:100% !important;
  width:100%  !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  margin:0 !important;
  padding:0 !important;
  gap: 0em !important;
  row-gap: 0em !important;
  column-gap: 0em !important;
  top:0 !important;
  left:0 !important;
  z-index:999 !important;  
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



.ButtonText{
  font-size: 1.25em !important;
  font-weight: 700 !important;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  border-radius: 40px !important;
  margin:0.1rem !important;
  padding-bottom:0.1rem !important;
  transition: all 1s ease-in-out !important;
  background-color: transparent !important;
}



@media screen and (max-width: 450px) {  
  .ButtonText{
    font-size: 1em !important;
    margin:0.25rem !important;
    padding:0.25rem !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1350px) {  
  .ButtonText{
    font-size:  1.25em !important;
    width:30vw !important;
  }
}



.ButtonText:hover{
  background-color: var(--amplify-colors-turquoise-light) !important;
  opacity:1 !important;
}


.ButtonTextTop{
  font-size: 2em !important;
  font-weight: 700 !important;
  color: var(--amplify-colors-black) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  border-radius: 40px !important;
  margin:0.1rem !important;
  padding-top:0.1rem !important;
  transition: all 1s ease-in-out !important;
  background-color: transparent !important;
}

.ButtonTextTop:hover{
  background-color: var(--amplify-colors-turquoise-light) !important;
  opacity:1 !important;
}

@media screen and (max-width: 450px) {  
  .ButtonTextTop{
    font-size: 1em !important;
    margin:0.25rem !important;
    padding:0.25rem !important;
  }
}


@media screen and (min-width: 451px) and (max-width: 1350px) {  
  .ButtonTextTop{
    font-size: 1.25em !important;
    width:40vw !important;
  }
}

