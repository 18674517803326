@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

.MarqueeContainer{
  position:relative !important;
  z-index: -999 !important;
}


.MenuBackground {  
  position: absolute;  
  z-index: -950 !important;
  top:0 !important;
  left:0 !important;
  height: 100vh;
  width: 100vw;
}

.TreeGrowth{
  position:absolute !important;
  top:0vh !important;
  left:-4vw !important;
  z-index: -100 !important;
}

@media screen and (max-width: 400px) {
  .TreeGrowth{
    position:absolute !important;
    top:0vh !important;
    left:20vw !important;
  }
}

  
  .SBSMLogoTicker{
    position:absolute !important;
    top:0 !important;
    left:60vw !important;
    z-index: -100 !important;
  }

  .SBSMNewsTicker{
    position:absolute !important;
    top:75vh !important;
    left:0 !important;
    z-index: -700 !important;
  }
  
  .ProductReviewsTicker{
    position:absolute !important;
    top:50vh !important;
    left:0 !important;
    z-index: -700 !important;
  }

  @media screen and (max-width: 400px) {
    .ProductReviewsTicker{
      position:absolute !important;
      top:52vh !important;
      left:0 !important;
      z-index: -700 !important;
    }
  }

  @media screen and (min-width: 401px) and (max-width: 450px) {
    .ProductReviewsTicker{
      position:absolute !important;
      top:48vh !important;
      left:0 !important;
      z-index: -700 !important;
    }
  }

  @media screen and (max-width: 400px) {
    .SBSMNewsTicker{
      position:absolute !important;
      top:65vh !important;
      left:0 !important;
      z-index: -700 !important;
    }
  }

  @media screen and (min-width: 401px) and (max-width: 450px) {
  .SBSMNewsTicker{
    position:absolute !important;
    top:61vh !important;
    left:0 !important;
    z-index: -700 !important;
  }
}

  .SubredditTicker{
    position:absolute !important;
    top:10vh !important;
    left:0 !important;
    z-index: -100 !important;
  }
  
  
  @media screen and (max-width: 400px) {
    .SubredditTicker{
      position:absolute !important;
      top:5vh !important;
      left:0 !important;
      z-index: -700 !important;
    }
  }


.Logo3D {  
  position: absolute;  
  z-index: -500;  
  left:50 !important;
  height: 50vh !important;
  width: 50vw !important;
}