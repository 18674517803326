
  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #006666;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #993300;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#330066;  
  }  

.MenuItems{
  gap:0.75em !important;
  width:10vw !important;
  border-color: transparent !important;
  border-width: 2px !important;
  border-style: solid !important;
}

@media (max-width: 768px) {
  .MenuItems{
    gap:0.25em !important;
    width:20vw !important;
    border-color: transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
  }
}


.MenuItem{
  width:15vw !important;
  height:0.5vh !important;
}

@media (max-width: 768px) {
  .MenuItem{
    width:20vw !important;
    height:0.5vh !important;
  }
}

.MenuItem:hover{
  transform: translateX(1vw) !important;
  transition-duration: 1s;
}
