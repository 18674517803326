@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
}

  @media screen and (max-width: 450px) {
    .FlexColumn {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important; 
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .FlexColumn {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }

  
  @media screen and (min-width: 1001px) {
  .FlexColumn {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}


  .Container {
    border-style: solid;
    border-width: 2px;
    border-color:transparent !important;
  }

  
  @media screen and (max-width: 450px) {
  .Container {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .Container {
    position:relative !important;
    top: 25vh !important;
    left: 3vw !important;
    height: 30vh !important;
    width: 90vw !important;
  }
}


@media screen and (min-width: 1001px) {
  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    height: 30vh !important;
    width: 60vw !important;
  }
}


  .h1 {
    font-family: Runalto;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Graphik, sans-serif;
      font-size: 1.5em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .h1 {
      font-family: Runalto;
      font-size: 1em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: ConigenReg;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .h2 {
      font-family: ConigenReg;
      font-size: 0.5em !important;
      color: var(--amplify-colors-purple);
    }
  }

  @media screen and (max-width: 1001px) {
    .h2 {
      font-family: ConigenReg;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }


  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }

.CampaignField input{  
  --amplify-components-text-color: var(--amplify-colors-grey);
  --amplify-components-text-border-color: var(--amplify-colors-turquoise);
  --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  color:white !important;
  width:30vw !important;
  font-weight: 500 !important;
}

  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey) !important;
    --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
    color:white !important;
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
  }
  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }


    @media screen and (max-width: 450px) {
    .ToolBar{
      height:5vh !important;
      width:80vw;    
      display: flex;
      flex-direction: column;
      justify-content: left;
      flex-wrap:nowrap;
      padding-bottom: 5%;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 1000px) {
  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }
}

  @media screen and (min-width: 1001px) {
  .ToolBar{
    height:5vh !important;
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: column;
    justify-content: left;
    flex-wrap:nowrap;
    padding-bottom: 5%;
  }
}

@media screen and (max-width: 450px) {
  .ToolBarRow{
    width:80vw;    
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap:wrap;
    align-items:center;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: row;
    justify-content: left;
    flex-wrap:wrap;
    align-items:center;
  }
}

@media screen and (min-width: 1001px) {
  .ToolBarRow{
    width:50vw;    
    display: flex;
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    flex-direction: row;
    justify-content: left;
    flex-wrap:wrap;
    align-items:center;
  }
}


  .ToolBarItem{
    border-style: solid;
    border-width: 2px;
    border-color:transparent;
    padding: 0.25vh !important;
    vertical-align: top;

  }

  .Slider {
      --amplify-components-sliderfield-range-background-color: var(--amplify-colors-orange);      
      --amplify-components-sliderfield-track-background-color: var(--amplify-colors-turquoise);   
      --amplify-components-sliderfield-thumb-background-color: var(--amplify-colors-purple);    
      --amplify-components-sliderfield-thumb-border-color: var(--amplify-colors-grey); 
      --amplify-components-sliderfield-thumb-focus-border-color: var(--amplify-colors-orange); 
  }

  
  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;    
    --amplify-components-select-color: var(--amplify-colors-grey);
    background-color: transparent!important;
    color: var(--amplify-colors-turquoise)!important;
    width:20vw !important;
    font-weight:500 !important;
  }

  
  @media screen and (max-width: 450px) {
    .SelectField {
      width:75vw !important;
    }

  }

  .SelectField Option {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-turquoise)!important;
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }


  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}


@media screen and (max-width: 450px) {

  .TableSection{
    position:relative !important;
    top:15vh !important;
    left:3vw !important;
    width: 90vw !important;
  }
}


@media screen and (min-width: 451px) and (max-width: 1000px) {

  .TableSection{
    position:relative !important;
    top:30vh !important;
    left:3vw !important;
    width: 90vw !important;
  }
}



@media screen and (min-width: 1001px) {

  .TableSection{
    position:relative !important;
    top:20vh !important;
    left:3vw !important;
  }
}


span {
  font-weight: 400 !important;
}


.Button {
  color:white !important;
  background: transparent !important;
  border-color:var(--amplify-colors-orange) !important;
  --amplify-internal-button-color: var(--amplify-colors-grey) !important;
  --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
  height:3vh !important;
  font-size: 0.75em !important;
}

@media screen and (max-width: 450px) {
  .Button{
    height:4.5vh !important;
    width:30vw !important;
    font-size: 0.7em !important;
  }
}

.Button:hover {
  color:black !important;
  background:var(--amplify-colors-turquoise) !important;
  border-color:var(--amplify-colors-purple) !important;
  --amplify-internal-button-color: var(--amplify-colors-grey) !important;
  --amplify-components-storagemanager-dropzone-text-color: var(--amplify-colors-grey) !important;
  height:3vh !important;
}

.MessageBox{
  color:white !important;
  border-color:black !important;
  --amplify-components-message-filled-info-background-color:black !important;

}


.Divider{
  border-color:linear-gradient( --amplify-colors-orange, --amplify-colors-purple) !important;
  border-width: 0.5em !important;
  border-radius: 1em !important;
  border-style: solid !important;
  width:30vw !important;
}

.ShortDivider{
  border-color:linear-gradient( --amplify-colors-orange, --amplify-colors-purple) !important;
  border-width: 0.5em !important;
  border-radius: 1em !important;
  border-style: solid !important;
  color:var(--amplify-colors-purple) !important;
  width:10vw !important;
}

.Text{
  color:white !important;
  font-weight:500 !important;
  font-size:1em !important;
  width:10vw !important;
}

  
.Fieldset{
  border-top-color:white !important;
  border-top-width: 0.25em !important;
  border-top-style: solid !important;
  font-weight:500 !important;
  font-size:1em !important;
  color:white !important;
  --amplify-components-fieldset-legend-color:white !important;
  width:50vw !important;
  padding:1em !important;
  margin-top:1em !important;  
}

@media screen and (max-width: 450px) {
  .Fieldset{
    width:90vw !important;
    padding:0em !important;
    padding-top:1em !important;
    margin-top:1em !important;  
  }
  }

.CheckboxField{
  color:var(--amplify-colors-orange) !important;
  font-size: 0.75em !important;
}

.FlexRow {
  padding: 0px 0 25px;
}

@media screen and (max-width: 450px) {
  .FlexRow{
    padding: 0px 0 0px;
  }
  }


.TableHeaderRow{
  color:var(--amplify-colors-turquoise-light) !important;
}  

.TableHeaderRowCell{
  color:var(--amplify-colors-turquoise-light) !important;
}  

.AccountGroup{
  color:var(--amplify-colors-turquoise-light) !important;
  font-size: 1em !important;
  font-weight:500 !important;

}

.AccountGroupLabel{
  color:var(--amplify-colors-orange-light) !important;
  font-size: 1em !important;
  font-weight:500 !important;
}

.NewNewsletterRow{
  margin-top:3em !important;
}