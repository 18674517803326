@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
}

  .BackgroundContainer{
    position:relative !important;
    border-color: transparent !important;
    border-width: 2px !important;
    border-style: solid !important;
  }
  
    .Background {  
      position: absolute; 
      z-index: -500;   
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }

    .PhotoImage1{
      position:absolute !important;
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }

    @media screen and (max-width: 450px) {
      .PhotoImage1 {
        height: 75vh;
      }
    }
    
    .PhotoImage2{
      position:absolute !important;
      top:0 !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }


    @media screen and (max-width: 450px) {
      .PhotoImage2 {
        height: 75vh;
      }
    }
    

    .PhotoImageText{
      position:relative !important;
      top:5vh !important;
      left:0 !important;
      height: 100vh;
      width: 100vw;
    }

    @media screen and (max-width: 450px) {
      .PhotoImageText {
        top:-5vh !important;
        height: 50vh;
      }
    }