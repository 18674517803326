@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

.h1 {
    font-family: 'Mulish', sans-serif;
    font-size: 28px;
    color: #CCCBCB;
}


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-black: #333333;
    --amplify-colors-blackRowHighLight:#414141;
    --amplify-colors-black2: rgb(56, 52, 52);
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;  
  }


  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    font-weight: 500 !important;
  }

  @media screen and (max-width: 450px) { 
    .TextField{
      color: var(--amplify-colors-grey) !important;
      font-size: 0.8em !important;
    }
  }


  .TextFieldUpdated{
    --amplify-components-text-color: var(--amplify-colors-orange) !important;
  }

  @media screen and (max-width: 450px) { 
    .TextFieldUpdated{
      color: var(--amplify-colors-grey) !important;
      font-size: 0.8em !important;
    }
  }


  .TextFieldTitle {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:30vw !important;
  }

  .TextFieldDateTime {
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
    width:10vw !important;
  }

  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }


  .RowButtons {
    color: var(--amplify-colors-purple) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-turquoise) !important;
  }


  .RowButtons:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  .RowButtons:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-turquoise) !important;
  }
  
  @media screen and (max-width: 450px) {
    .RowButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  auto  !important;
      width:  8vw  !important;
      padding: 0px 0px;
    }
  }

  @media screen and (min-width: 451px) {
    .RowButtons {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      height:  4vh  !important;
      width:  2vw  !important;
      padding: 0px 0px;
    }
  }



  .TableRowBackground {
    background-color: transparent!important;
    width:10vw !important;    
    border-color:  var(--amplify-colors-turquoise) !important;
  }

  @media screen and (max-width: 450px) {
    .TableRowBackground {
      background-color: transparent!important;
      width:40vw !important;     
      border-color:  var(--amplify-colors-turquoise) !important;
    }
    }
  
  .RedditTitleSpan{
    padding: 100px 10px;
  }

  .ToolBar{
    padding: 20px 10px;
  }

  .HideStatusRow {
    visibility: collapse;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .ShowStatusRow {
    background-image: url("https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItLogo2.svg");
    visibility: visible;    
    background-color: var(--amplify-colors-black2) !important;  
    color: var(--amplify-colors-grey) !important;
  }


  .TableRowDataStatus{
    color: var(--amplify-colors-grey) !important;  

  }    


  .TableRowData{
    color: var(--amplify-colors-grey) !important;  
    text-align: left !important;
    vertical-align: middle !important;
    font-size: 0.8em !important;
  } 

  .TableRowHeadingData{
    color: var(--amplify-colors-grey) !important;  
    text-align: left !important;
    vertical-align: middle !important;
    font-size: 0.8em !important;
    width: 20vw !important;
  } 
  

  .TableRowDataERROR{
    background-color: darkred !important;
    color: var(--amplify-colors-grey) !important;  
  }

  .TableRowDataERROR:hover {
    background-color: var(--amplify-colors-black2) !important;
  }

  .RhyteItMenuBackground {  
    background-color: var(--amplify-colors-purple) !important;
    width:2.2vw  !important;
  }

  .CustomFileUpload{
    border: 1px solid #ccc;
    display: inline-block;
    height: 3vh !important;
    width: 4vw !important; 
    padding: 0.75em 1.5em;
    text-align: center !important;
    cursor: pointer;       
    color: var(--amplify-colors-turquoise) !important;
    background-color: transparent !important;
    border-color:  var(--amplify-colors-orange) !important;
  }

  .CustomFileUpload:hover {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }
  
  .CustomFileUpload:active {
    color: var(--amplify-colors-black) !important;
    background-color: var(--amplify-colors-purple) !important;
    border-color:  var(--amplify-colors-orange) !important;
  }



  .CheckboxFieldColumn {
    color: transparent !important;
    border-style: solid;
    border-color: transparent;
    border-width: 1px;
    box-sizing: border-box;  
    height: 1em; 
    width: 1em; 
    font-size:1em !important;
    text-align: center !important;
    vertical-align: middle !important;
  }
  
  span {
    font-family: Graphik, Arial, Helvetica, sans-serif;
    font-size:1.5em !important;
    font-weight: 100 !important;
  }

  @media screen and (max-width: 900px) {

  
    [data-amplify-theme="studioTheme"]  {
      --amplify-colors-grey: #CCCBCA;
      --amplify-colors-darkgrey: #333333;
      --amplify-colors-black: #000000;
      --amplify-colors-turquoise: #00CCCC;
      --amplify-colors-orange: #CC6633;
      --amplify-colors-purple: #9999CC;
      --amplify-components-fieldcontrol-font-size:11px !important;
      --amplify-internal-table-td-font-size:11px !important;
    }
    
    .RhyteItTable {
      --amplify-components-table-width: 50vw !important;
    } 
    
    .TableHeaderRowCells{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    }
    
    .TableRowDataStatus{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    }    
  
  
    .TableRowData{
      font-size: var(--amplify-components-fieldcontrol-font-size) !important;
    } 
  
      
  .ErrorMessageRow{
    color: var(--amplify-colors-orange) !important;
    --amplify-internal-table-td-font-size: 0.7em !important;
    --amplify-components-text-color: var(--amplify-colors-orange) !important;
    height:auto !important;
  }

  .ErrorMessageRow:hover{
    background-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-turquoise) !important;
    --amplify-internal-table-td-font-size: 0.7em !important;
    height:auto !important;
  }
    
    
  }

  .TextAreaField {
    --amplify-components-textareafield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-grey) !important;
    color: var(--amplify-colors-grey) !important;
    font-family:ConigenReg !important;
  }
  
  @media screen and (max-width: 450px) {
    .TextAreaField {
      --amplify-components-fieldcontrol-font-size:0.8em !important;
    }
  }
  
  @media screen and (min-width: 451px) {
    .TextAreaField textarea {
      --amplify-components-fieldcontrol-font-size:1em !important;
      height: 10vh !important;
      width: 10vw !important;
      --amplify-components-fieldcontrol-height: 10vh !important;
    }
  }

  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    --amplify-components-select-background-color:var(--amplify-colors-black) !important;
    --amplify-components-select-color:var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
  }



  .SelectField option {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    --amplify-components-select-option-background-color:var(--amplify-colors-black) !important;
    color: var(--amplify-colors-grey)!important;
    height: 2vh !important;
  }


  .SelectField option:hover {
    --amplify-components-select-background-color:var(--amplify-colors-orange) !important;
  }


  .Image {
    height: 10vh !important;
    width: 7vw !important;
  }

  .ImageActive {
    height: 10vh !important;
    width: 7vw !important;
    border-style: solid;
    border-color: var(--amplify-colors-turquoise) !important;
    border-width: 1px;
  }


  @media screen and (max-width: 450px) { 
    .ImageActive {
      width: 30vw !important;
      height: 15vh !important;
      border-style: solid;
      border-color: var(--amplify-colors-turquoise) !important;
      border-width: 1px;
    }    
  }

  .ImageInActive {
    height: 10vh !important;
    width: 5vw !important;
    border-style: solid;
    border-color: var(--amplify-colors-orange) !important;
    border-width: 1px;
    filter: blur(4px);
  }  
  
.ImageDeleteIcon {
  height:25px;
  width:25px;
  transition-duration: 2s;
}

@media screen and (max-width: 450px) { 
  .ImageDeleteIcon {
    height:2vh !important;
    width:5vw !important;
    transition-duration: 2s;
  }
}

.ImageDeleteIcon:hover {
  height:25px;
  width:25px;
}

@media screen and (max-width: 450px) { 
  .ImageDeleteIcon:hover {
    height:2vh !important;
    width:5vw !important;
    border-style: solid;
    border-color: var(--amplify-colors-orange) !important;
    border-width: 2px;
  }
}


.ImageActive:hover .ImageDeleteIcon {
  display:block;
}

.overlay {
  position: absolute;
  width: 7vw !important;
  opacity: 1;
  transition: .3s ease;
  background-color: transparent !important;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  padding-right:1px !important;
  padding-top:1px !important;
  gap:0.001em!important;
}

@media screen and (max-width: 450px) { 
.overlay {
  position: absolute;
  width: 17vw !important;
  padding-right:1px !important;
  padding-top:1px !important;
  gap:0.25em!important;
}
}



.IconButton{
  background-color: transparent;
  border-style: solid;
  border-color: transparent;
  border-width: 0px;
  padding-left:0px !important;
  padding-right:5px !important;
  background-color: black !important;
}

@media screen and (max-width: 450px) { 
  .IconButton{
    background-color: transparent;
    border-style: solid;
    border-color: transparent;
    border-width: 0px;
    padding-left:0px !important;
    padding-right:5px !important;
    background-color: black !important;
  }
}


.IconButton:hover {
  opacity: 1;
}


.Fieldset{
  border-top-color:white !important;
  border-top-width: 0.25em !important;
  border-top-style: solid !important;
  font-weight:500 !important;
  font-size:1em !important;
  color:white !important;
  --amplify-components-fieldset-legend-color:white !important;
  width:50vw !important;
  padding:4em !important;
  margin-top:4em !important;

}
