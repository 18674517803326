@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
  }  

.Text{
  font-family: Graphik  !important;
  font-size: 1.5em !important;
  color: var(--amplify-colors-turquoise-light)!important;
}

.Highlight{
  font-family: Graphik  !important;
  font-size: 1.5em !important;
  font-weight: 400 !important;
  color: var(--amplify-colors-orange-light)!important;

}