
  
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-purple: #9999CC;
  --amplify-components-fieldcontrol-font-size:20px !important;
}

.RhyteItTable {
  position:relative !important;
  top: 30vh !important;
  left:5vw !important;
  width:50vw !important;
  height:20vh !important;
  background-color:transparent !important;
}



@media screen and (max-width: 450px) {
  .RhyteItTable {
    position:relative !important;
    top: 30vh !important;
    left:5vw !important;
    width:90vw !important;
    height:20vh !important;
    background-color:transparent !important;
  }
}

.TableRow {
  height:10vh !important;
  background-color:transparent !important;
}
 

.TableCellIcon {
  width:5vw !important;
  height:10vh !important;
  color: var(--amplify-colors-purple) !important;
  border-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  font-size: 4em !important;
}

@media screen and (max-width: 450px) {
  .TableCellIcon {
    width:12vw !important;
    height:10vh !important;
    color: var(--amplify-colors-purple) !important;
    border-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    font-size: 2em !important;
  }
}

.Icon {
  height: 5vh !important;
  width:2.5vw !important;
}


@media screen and (max-width: 450px) {
  .Icon {
    height:3vh !important;
    width:10vw !important;
  }
}

.TableCell {
  width:10vw !important;
  height:10vh !important;
  color: var(--amplify-colors-purple) !important;
  border-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  font-size: 4em !important;
}

@media screen and (max-width: 450px) {
  .TableCell {
    width:3vw !important;
    height:10vh !important;
    color: var(--amplify-colors-purple) !important;
    border-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    font-size: 0.75em !important;
  }
}




.TableCellDescription {
  width:35vw !important;
  height:10vh !important;
  color: var(--amplify-colors-orange) !important;
  border-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  font-size: 2em !important;
}

@media screen and (max-width: 450px) {
  .TableCellDescription {
    width:45vw !important;
    height:10vh !important;
    color: var(--amplify-colors-orange) !important;
    border-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    font-size: 0.5em !important;
  }
  }


.RhyteItProcessMapVideo {  
  position:relative !important;
  top:10vh !important;
  left:20vw !important;
  width:1000px !important;
  height:1000px !important;
}

