@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
    --amplify-colors-grey: #CCCBCA;
    --amplify-colors-darkgrey: #333333;
    --amplify-colors-black: #000000;
    --amplify-colors-turquoise: #00CCCC;
    --amplify-colors-orange: #CC6633;
    --amplify-colors-purple: #9999CC;
  }

  
  .GridContainer {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
  }

  @media screen and (max-width: 450px) {
  .GridContainer {
    position:relative !important;
    top: 10vh !important;
    left: 3vw !important;
    width: 90vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;;
  }
}

  .Container {
    position:relative !important;
    top: 15vh !important;
    left: 3vw !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;;
  }

  
  @media screen and (max-width: 450px) {
  .Container {
    position:relative !important;
    top: 5vh !important;
    left: 3vw !important;
    width: 90vw !important;
  }
}


  .h1 {
    font-family: Graphik, sans-serif;
    font-size: 3em !important;
    color: var(--amplify-colors-turquoise);
  }
  
  @media screen and (max-width: 450px) {
    .h1 {
      font-family: Graphik, sans-serif;
      font-size: 1.5em !important;
      color: var(--amplify-colors-turquoise);
    }
  }

  .headerline {
    height: 0.25vh;
    width: 70vw;
    background: var(--amplify-colors-orange);
    animation: dude 2s 1 forwards;
  }
  

  @media screen and (max-width: 450px) {
    .headerline {
      height: 0.25vh;
      width: 100vw;
      background: var(--amplify-colors-orange);
      animation: dude 2s 1 forwards;
    }
  }

  @keyframes dude {
    0% {
      width: 0;
    }
    100% {
      width: 50vw;
    }
  }
  
  .h2 {
    font-family: Graphik, sans-serif;
    font-size: 2em !important;
    color: var(--amplify-colors-purple);
  }

  @media screen and (max-width: 450px) {
    .h2 {
      font-family: Graphik, sans-serif;
      font-size: 1em !important;
      color: var(--amplify-colors-purple);
    }
  }

  .PhoneNumberField {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color:var(--amplify-colors-orange)
  
  }
  
  .PhoneNumberField:after {
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise);
  
  }

  .LabelField {
    --amplify-components-text-font-size: 1rem !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
  }



  .TextField{
    --amplify-components-text-color: var(--amplify-colors-grey);
    --amplify-components-text-border-color: var(--amplify-colors-turquoise);
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple);
  }


  .Text{    
    --amplify-components-text-color: var(--amplify-colors-grey);
    padding-right: 2vw !important;
  }
  
  .CheckBox{    
    cursor: var(--amplify-colors-turquoise);
    --amplify-components-checkbox-button-focus-outline-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-focus-border-color: var(--amplify-colors-purple);
    --amplify-components-checkbox-button-color: var(--amplify-colors-black);    
    --amplify-components-checkbox-icon-background-color:var(--amplify-colors-purple);    
    --amplify-components-checkbox-button-focus-box-shadow: var(--amplify-colors-grey);  
    --amplify-components-checkbox-button-before-border-color: var(--amplify-colors-turquoise); 
  }


  .Instructions{
    padding-bottom: 3em !important;
  }


  .SMSOptInText{
    color: var(--amplify-colors-grey) !important;
    font-size: 18px !important;

  }

  .QueryTopicTextField{
    
    --amplify-components-text-color: var(--amplify-colors-grey) !important;
    --amplify-components-text-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-focus-border-color: var(--amplify-colors-purple) !important;
    --amplify-components-text-font-size: 1rem !important;

  }


  .SelectField {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
  }

  .SelectFieldOption {
    --amplify-components-selectfield-border-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-selectfield-focus-border-color:var(--amplify-colors-orange) !important;
    --amplify-components-selectfield-font-size:15px !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

 .SelectFieldOption:hover {
    background: var(--amplify-colors-orange) !important;
  }

  .SearchField {
    --amplify-components-button-color:var(--amplify-colors-turquoise) !important;
    --amplify-components-button-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-border-color: var(--amplify-colors-turquoise) !important;
    --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
    background-color: var(--amplify-colors-black)!important;
    color: var(--amplify-colors-grey)!important;
  }

.amplify-autocomplete {
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-fieldcontrol-color: var(--amplify-colors-turquoise) !important;
  background-color: var(--amplify-colors-black)!important;
  color: var(--amplify-colors-grey)!important;  
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}
.amplify-autocomplete__menu__options {
  --amplify-components-autocomplete-menu-options-flex-direction:column;
  --amplify-components-autocomplete-menu-width:100% !important;
  --amplify-components-autocomplete-menu-option-active-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-background-color: var(--amplify-colors-turquoise) !important;
  --amplify-components-autocomplete-menu-option-color: var(--amplify-colors-turquoise) !important;
}


@media screen and (max-width: 450px) {

  .TableSection{
    position:relative !important;
    top:10vh !important;
    left:3vw !important;
  }
}



@media screen and (min-width: 451px) {

  .TableSection{
    position:relative !important;
    top:20vh !important;
    left:3vw !important;
  }
}


@media screen and (min-width: 451px) {
.MessageBox{
  position:relative !important;
  top:1vh !important;
  font-size: 1em !important;
  font-weight:500 !important;
}
}

@media screen and (max-width: 450px) {
  .MessageBox{
    font-size: 0.75em !important;
    width: 90vw !important;
    font-weight:500 !important;
  }
}



@media screen and (min-width: 451px) {
  .RadioGroupFieldContainer
  {
    position:relative !important;
    top:3vh !important;
  }
}

@media screen and (min-width: 451px) {
  .RadioGroupField {
    font-size: 0.75em !important;
    color: var(--amplify-colors-turquoise) !important;

  }
}
  
span {
  font-weight: 400 !important;
}

@media screen and (min-width: 451px) {
  .RadioOption {
    color: var(--amplify-colors-turquoise) !important;
    --amplify-components-text-color: var(--amplify-colors-turquoise) !important;
    font-weight: 400 !important;
    --amplify-components-radio-button-background-color: var(--amplify-colors-grey) !important;
    --amplify-components-radio-button-border-color: var(--amplify-colors-orange) !important;

  }

  .RadioOption:checked {
    color: var(--amplify-colors-turquoise) !important;
  }  
}

.Table{
  border-style: solid;
  border-color: transparent !important;
  box-sizing: border-box;
  border-width: 2px;
}

@media screen and (max-width: 450px){
  .Table{
    width: 90vw !important;
    position: relative !important;
    top: 0vh !important;
    left:0vw !important;
  }
}

@media screen and (min-width: 451px){
  .Table{
    border-width: 2px;
    width: 40vw !important;
    position: relative !important;
    top: 0vh !important;
    left:0vw !important;
    height: 4vh !important;
  }
}

.TableRow {
  border-style: solid;
  border-color: transparent !important;
  border-width: 2px;  
}

@media screen and (max-width: 450px){
  .TableRow {  
    position: relative !important;
    top: 0vh !important;
    margin-bottom: 1em !important;
    height: 1vh !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 1000px) {
  .TableRow {
    position: relative !important;
    left:3vw !important;    
    width: 60vw !important;
    padding-bottom: 2em !important;
    height: 8vh !important;
  }
}

@media screen and (min-width: 1001px) {
  .TableRow {
    position: relative !important;
    top: 0vh !important;
    left:3vw !important;
    width: 60vw !important;
    padding-bottom: 1em !important;
    height: 3vh !important;
  }
}

.amplifyautocomplete{
  color: var(--amplify-colors-turquoise) !important;
  font-size: 0.5em !important;
  border-color: var(--amplify-colors-orange) !important;
  --amplify-components-autocomplete-menu-border-color: var(--amplify-colors-purple) !important;
  --amplify-components-fieldcontrol-border-color: var(--amplify-colors-orange) !important;
}

.amplifyautocomplete input{
  color: var(--amplify-colors-turquoise) !important;
  font-weight: 500 !important;
}

@media screen and (max-width: 450px){
  .Button{
    border-width: 1px !important;
    border-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-turquoise) !important;
    width:30vw !important;
    font-size: 0.55em !important;

  }
}

@media screen and (min-width: 451px){
  .Button{
    border-width: 1px !important;
    border-color: var(--amplify-colors-orange) !important;
    color: var(--amplify-colors-turquoise) !important;
    width:10vw !important;
    font-size: 1em !important;

  }
}


