@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');


[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
}

  @media screen and (max-width: 450px) {
    .FlexColumn {
      position:relative !important;
      top: 10vh !important;
      left: 3vw !important;
      width: 90vw !important;
      height: 80vh !important;
    }
  }
  
    
  @media screen and (min-width: 451px) and (max-width: 1000px) {
    .FlexColumn {
      position:relative !important;
      top: 15vh !important;
      left: 3vw !important;
      width: 90vw !important;
    }
  }

  
  @media screen and (min-width: 1001px) {
  .FlexColumn {
    position:relative !important;
    top: 3vh !important;
    left: 3vw !important;
    width: 90vw !important;
    height: 80vh !important;
  }
}

.UnsubscribedBox{
  background-color: var(--amplify-colors-orange-light)!important;
  width:30vw !important;
  padding:2rem !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
  align-content: center !important;
}


  .WhiteBox{
    background-color: var(--amplify-colors-turquoise-light)!important;
    width:30vw !important;
    padding:2rem !important;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    align-content: center !important;
  }

  .Text{
    color: var(--amplify-colors-black)!important;
    font-size: 1.5rem !important; 
    font-weight: 400 !important;
  }