@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;800&display=swap');



[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise-dark: #047f7f;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-dark: #cf5011;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;  
  --amplify-colors-purple-dark:#1a1a21;  
    --amplify-components-rating-empty-color: var(--amplify-colors-grey) !important;
    --amplify-components-rating-filled-color: var(--amplify-colors-purple) !important;
  }

.FormContainer {
  position: relative !important;
  top:0vh !important; 
  left: 0vw !important;
  border-style: solid;
  border-width: 1px;
  border-color:transparent !important;
  width:50vw !important;
}

@media screen and (max-width: 450px) {
  .FormContainer {
    width:90vw !important;
  }
}

  .Heading {
    font-size: 0.65em !important;
    color: var(--amplify-colors-orange-light) !important;
  }

  h1 {
    font-size: 0.5em !important;
  }

.HeaderText{
  font-size: 2em !important;
  color: var(--amplify-colors-orange-light) !important;
  text-align: center !important;
}

@media screen and (max-width: 450px) {
.HeaderText{
  font-size: 1em !important;
}
}

.DividerHeader {
  color: var(--amplify-colors-purple-light) !important;
  width:40vw !important;
}

@media screen and (max-width: 450px) {
  .DividerHeader {
    width:90vw !important;
  }
}

.cardSection {
  position: relative !important;
  top:10vh !important;
  height: 30vh !important;
  width: 50vw !important;
  display: flex;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color:transparent !important;

}
@media screen and (max-width: 450px) {
  .cardSection {
    width:90vw !important;
  }
}

.CommentSection{
  height: 30vh !important;
  width: 60vw !important;
  display: flex;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color:transparent !important;

}

  .Image {
    width:50% !important;
    height: 50% !important;
    object-fit: cover;
  }

  @media screen and (max-width: 450px) {
    .Image {
      width:100% !important;
      height: 45% !important;
    }
  }

  .card {
    position: relative !important;
    width: 12vw !important;
    height: 30vh !important;
    margin: 1px;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
  }

  
@media screen and (max-width: 450px) {
  .card {
    width:50vw !important;
  }
}
  
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 15px #00CCCC;  
    transition: transform 1s;
    transform-style: preserve-3d;
  }
  
  .card:hover .content {
    transform: rotateY( 180deg ) ;
    transition: transform 0.5s;
  }
  
  .front{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 2vh !important;
    background: var(--amplify-colors-black) !important;
    line-height: 100px;    
    text-align: center;
    font-size: 30px;
    border-radius: 5px;
    backface-visibility: hidden;
    color: white;
  }

  
@media screen and (max-width: 450px) {
  .front {
    font-size:1em !important;
  }
}
  
  .back {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--amplify-colors-black) !important;
    line-height: 3em !important;
    text-align: center;
    font-size: 25px;
    font-weight: 100 !important;
    font-family: Graphik;
    border-radius: 5px;
    backface-visibility: hidden;
    color: white;
    transform: rotateY( 180deg );
    padding: 2vh !important;
  }

  @media screen and (max-width: 450px) {
    .back {
      font-size: 1em !important;
    }
  }

  .DividerFront {
    border-color: var(--amplify-colors-purple) !important;

  }


  .RatingSection {
    position:relative !important;
    top:2vh !important;
    width:50vw !important;
    height:5vh !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
    align-items: center !important;
  }


  @media screen and (max-width: 450px) {
    .RatingSection {
      width:90vw !important;
      --amplify-components-flex-justify-content: center !important;
      align-content: center !important;
      align-items: center !important;
    }
  }


  .Rating{
    color: var(--amplify-colors-turquoise) !important;
    font-size: var(--amplify-font-sizes-xxs);
    line-height: var(--amplify-font-sizes-xxs);
    justify-self: center !important;
    width:50vw !important;
    height:5vh !important;
    --amplify-components-flex-justify-content: center !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
  }

  @media screen and (max-width: 450px) {
    .Rating {
      font-size: 0.75em !important;
      --amplify-components-flex-justify-content: center !important;
      --amplify-components-flex-align-items:stretch !important;
    }
  }


  .RatingCustomer{
    font-size: var(--amplify-font-sizes-xxs);
    line-height: var(--amplify-font-sizes-xxs);
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;
  }

  @media screen and (max-width: 450px) {
    .RatingCustomer {
      width:5vw !important;
      font-size: 0.2em !important;
      line-height: 0.2em !important;
      letter-spacing: 2px !important;
      --amplify-components-flex-align-items:normal !important;
    }
  }


  .ReviewText {
    font-size: 0.5em !important;
    line-height: 2em !important;  
    color: white !important;
    font-weight: 400 !important;
  }
  

  .amplify-rating-icon-empty {
    color: var(--amplify-components-rating-empty-color);
  }

  .SpaceAtTheBottom {
    position: relative !important;
    top: 30vh !important;
    height: 20vh !important;
    border-style: solid;
    border-width: 1px;
    border-color:transparent !important;    
  }

  @media screen  and (max-width: 450px) {
    .Button {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      text-align: center !important;
      font-size: 1em !important;
      height:  10vh  !important;
      width: 70vw  !important;
      padding: 5px 5px;
    }
  }


  @media screen and (min-width: 1001px) and (max-width: 3000px) {
    .Button {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      text-align: center !important;
      font-size: 1em !important;
      height:  5vh  !important;
      width: 30vw  !important;
      padding: 5px 5px;
    }
  }

  @media screen and (min-width: 3001px) {
    .Button {
      color: var(--amplify-colors-orange) !important;
      background-color: var(--amplify-colors-black) !important;
      border-color:  var(--amplify-colors-purple)  !important;
      font-size: 1em !important;
      height:  5vh  !important;
      width:  30vw  !important;
      padding: 0px 0px;
    }
  }

    .Button:hover {
      color: var(--amplify-colors-black) !important;
      background-color: var(--amplify-colors-turquoise) !important;
      border-color:  var(--amplify-colors-orange)  !important;
    }

    .FlexRow {
      padding: 0px 0 50px;
      border-style: solid;
      border-width: 1px;
      border-color:transparent !important;    
    }

