

[data-amplify-theme="studioTheme"]  {
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;

}

.GridContainer {
  position: relative !important;
  top:3vh !important;
  left:3vw !important;
}

.MenuBackground{
  height:100vh;
  width:100vh;
}


@media screen and (max-width: 450px) {
  .RhyteItMenuContainer {
    position: relative !important;
    top: 10vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}


@media screen and (min-width: 451px) {
  .RhyteItMenuContainer {
    position: relative !important;
    top: 10vh !important;
    left:5vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}


.UserStatusText {
  font-size: 1em !important;
  color: var(--amplify-colors-grey) !important;
  font-weight: 500 !important;
  line-height: 1.5em !important;
}


@media screen and (max-width: 450px) {
.UserStatusText {
  font-size: 0.75em !important;
  color: var(--amplify-colors-grey) !important;
  font-weight: 500 !important;
  line-height: 1.5em !important;
}
}


@media screen and (max-width: 450px) {
  .ErrorMessage {
    position: relative !important;
    font-size: 0.5em !important;
    top: 10vh !important;
    color: var(--amplify-colors-grey) !important;
  }
}


@media screen and (min-width: 451px) {
  .ErrorMessage {
    position: relative !important;
    top: 10vh !important;
    left:5vh !important;
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 0.5em !important;
  }
}


.TableImage{
  height: 10vh !important;
  width: 10vw !important;
}


.headerline {
  width: 50vw;
  color: var(--amplify-colors-grey) !important;
  border-color: var(--amplify-colors-turquoise)!important;
  animation: linegrowanimation 2s 1 forwards;
}

.Fieldset{
  width: 50vw;
  color: var(--amplify-colors-grey) !important;
  border-color: var(--amplify-colors-purple)!important;
  animation: linegrowanimation 2s 1 forwards;
  --amplify-components-fieldset-legend-color: var(--amplify-colors-orange) !important;
  --amplify-components-fieldset-legend-font-size:2em !important;
}

@keyframes linegrowanimation {
  0% {
    width: 0;
  }
  100% {
    width: 50vw;
  }
}



@media screen and (max-width: 450px) {
  .TableHeaderRow {
    --amplify-components-table-data-color:  var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}

@media screen and (min-width: 451px) {
  .TableHeaderRow {
    --amplify-components-table-data-color: var(--amplify-colors-grey) !important;
    --amplify-internal-table-td-font-size: 1.5em !important;
    height:1.5vh !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
    background-color:transparent !important;
  }
}

.TableHeaderRow:hover {
  background-color:transparent !important;
}


@media screen and (max-width: 450px) {
  .TableRow {
    font-size:  0.75em !important;
    --amplify-components-table-data-color: var(--amplify-colors-purple) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    line-height: 2 !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}

@media screen and (min-width: 451px) {
  .TableRow {
    --amplify-components-table-data-color: var(--amplify-colors-purple) !important;
    --amplify-internal-table-td-font-size: 1em !important;
    line-height: 2 !important;
    height:auto !important;
    border-style: solid !important;
    border-width: 2px !important;
    border-color: transparent !important;
  }
}


.TableRow:hover {
  --amplify-components-table-data-color: var(--amplify-colors-orange) !important;
  background-color: var(--amplify-colors-black) !important;
}

.Link {
  color:var(--amplify-colors-grey) !important;
  font-weight: 500 !important;
}

.RedditButtons {
  color: var(--amplify-colors-purple) !important;
  background-color: transparent !important;
  border-color:  var(--amplify-colors-turquoise) !important;
  vertical-align: top;
  width:12vw !important;
}

.RedditButtons:hover {
  color: var(--amplify-colors-black) !important;
  background-color: var(--amplify-colors-turquoise) !important;
}

.RedditButtons:active {
  color: var(--amplify-colors-black) !important;
  background-color: var(--amplify-colors-turquoise) !important;
}

.TableRowDescription{
  color: var(--amplify-colors-orange) !important;
  font-family:ConigenReg !important;
}

.metallicbutton {
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  width:12vw !important;
  height:5vh !important;
  font-size: 1em !important;
  padding: 6px 16px;
  font-weight: 400;
  border: none;
  outline: none;
  color: var(--amplify-colors-grey);
  background: linear-gradient(
    45deg,
    rgb(15, 72, 72) 5%,
    #005050  10%,
    #006868  30%,
    #003434  50%,
    #000303  70%,
    #004e4e  80%,
    #004f4f  95%
  ) !important;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0 2px 5px #78ebeb;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.metallicbutton:hover {
  background: linear-gradient(
    45deg,
    #090909 5%,
    #3e3e3e 10%,
    #4d4c4c 30%,
    #202020 50%,
    #616161 70%,
    #4f4e4e 80%,
    #212121 95%
    ) !important;
}


@media screen and (max-width: 450px) {
  .metallicbutton {
    width:55vw !important;
    height:5vh !important;
    font-size: 1.15em !important;
  }
}

.Text{
  color: var(--amplify-colors-orange) !important;
  font-family:ConigenReg !important;
  font-size:2em !important;

}

.CategoryRow{
  border-color:  var(--amplify-colors-purple-light)  !important;
  border-style:  solid !important;
  border-width:  2px !important;
  padding:1em !important;
  width: 70vw !important;

}


.TextLabel{
  color: var(--amplify-colors-orange-light) !important;
  font-family:ConigenReg !important;
  font-size:2em !important;
  font-weight: 600 !important;

}
