
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.SectionContainer{
  position: relative !important;
  width:100vw !important;
  z-index: 100 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
  background-color: black !important;
}

@media screen and (max-width: 450px) {
  .SectionContainer{
    height:100vh !important;
  }
}

.Circle{
  position: relative !important;
  z-index: 200 !important;
  top:0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}

@media screen and (max-width: 450px) {
  .Circle{
    top:0vh !important;
  }
}


.TextFlexColumn{
  position: absolute !important;
  z-index: 300 !important;
  top: 0 !important;
  left:0 !important;
  height:100vh !important;
  width:100vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}



@media screen and (max-width: 450px) {
  .TextFlexColumn{
    width:90vw !important;
    top: -12vh !important;
  }
}

.HeaderDiv{
  position: relative !important;
  top: 20vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}



.Title{
  color:var(--amplify-colors-black) !important;
  font-family: 'BeautyLuxury', Arial, sans-serif !important;
  letter-spacing: 10px !important;
  font-size: 5rem !important;
  font-weight: 400 !important;

}


@media screen and (max-width: 450px) {
  .Title{
    font-size: 1rem !important;  
  }
}

.Text{
  color:var(--amplify-colors-black) !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 450px) {
  .Text{
    font-size: 1rem !important;  
  }
}


.TextAreaField{
  position: relative !important;
  z-index: 400 !important;
  width:50vw !important;
  height:5vh !important;
  align-content: center !important;
  background-color:transparent !important;
  border-color:var(--amplify-colors-purple) !important;
}

@media screen and (max-width: 450px) {
  .TextAreaField{
    width:80vw !important;
    height:7vh !important;
  }
}

.TextAreaField textarea{
  background-color:var(--amplify-colors-orange-light) !important;
  border-color:var(--amplify-colors-purple) !important;
  border-radius: 30px !important;
}

.TextAreaField textarea:focus{
  background-color:var(--amplify-colors-purple-light) !important;
  border-color:var(--amplify-colors-purple) !important;
}



.CaveatDiv{
  position: relative !important;
  top: 20vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
  row-gap:0.1rem !important;
}

.caveats{
  color:var(--amplify-colors-darkgrey) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding-top:0em !important;
  margin-top:0em !important;


}


.StatusMessage{
  color:var(--amplify-colors-black) !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
  padding-top:0em !important;
  margin-top:0em !important;
}


@media screen and (max-width: 450px) {
  .StatusMessage{
    font-size: 1rem !important;  
  }
}


.ButtonRowDiv{
  width:15vw !important;
  height:5vh !important;
  background-color: var(--amplify-colors-black) !important;
  border-color: var(--amplify-colors-darkgrey) !important;
  border-style:  solid !important;
  border-width:  1px !important;
  border-radius: 30px !important;
  transition: all 1s ease;
}

@media screen and (max-width: 450px) {
  .ButtonRowDiv{
    width:35vw !important;
    height:5vh !important;
  }
}


@media screen and (min-width: 451px)  and (max-width: 1500px){
.ButtonRowDiv{
  width:25vw !important;
  height:5vh !important;
}
}

.ButtonRowDiv:hover{
  background-color: var(--amplify-colors-darkgrey) !important;
  transform: translateX(10px)!important;
}

.ButtonText{
  color:var(--amplify-colors-purple-light) !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}


@media screen and (max-width: 450px) {
  .ButtonText{
    font-size: 1rem !important;  
  }
}

.ButtonArrow{  
  width: 2vw !important;
  height:5vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  1px !important;
}