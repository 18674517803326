
[data-amplify-theme="studioTheme"]  {
  --amplify-colors-grey: #CCCBCA;
  --amplify-colors-darkgrey: #333333;
  --amplify-colors-black: #000000;
  --amplify-colors-turquoise: #00CCCC;
  --amplify-colors-turquoise-light:#99FFFF;
  --amplify-colors-orange: #CC6633;
  --amplify-colors-orange-light: #FFCC99;
  --amplify-colors-purple: #9999CC;
  --amplify-colors-purple-light:#CCCCFF;
  --amplify-components-fieldcontrol-font-size:20px !important;
}


.Section{
  position: sticky !important;
  top:0 !important;
  left:0 !important;
  height:100vh !important;
  width:100vw !important;
  z-index: -990 !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

.Background {  
  position: absolute;  
  top:0 !important;
  left:0 !important;
  z-index: -990 !important;
  height: 100vh !important;
  width: 100vw !important;
}


.TextFlexColumn{
  position: absolute !important;
  top:20vh !important;
  left:0vw !important;
  gap: 0vh !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  5px !important;
}


@media screen and (max-width: 450px) {
  .TextFlexColumn {
    top:10vh !important;
  }
}

.TextRow {
  position: relative !important;
  top:10vh !important;
  left:7vw !important; 
  width:70vw !important;
  gap:3vw !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



.SubTextRow{
  position: relative !important;
  top:10vh !important;
  left:17vw !important; 
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


.HeaderText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 1.5em !important;
  font-weight: 400 !important;
  line-height: 1em !important;
  color: var(--amplify-colors-turquoise-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}



@media screen and (max-width: 450px) {
  .HeaderText {
    left: 1vw !important;
    font-size: 1em !important;
    width: 90vw !important;
  }
}

.Text {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3.5em !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  margin-bottom:1px !important;
  -webkit-font-smoothing: antialiased;
  color: white !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .Text {
    left: 1vw !important;
    font-size: 1em !important;
    width: 90vw !important;
  }
}


.SubText {
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-size: 3.5em !important;
  font-weight: 300 !important;
  line-height: 1.5em !important;
  left: 10vw !important;
  width: 60vw !important;
  -webkit-font-smoothing: antialiased;
  color: var(--amplify-colors-orange-light) !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


@media screen and (max-width: 450px) {
  .SubText {
    left: 3vw !important;
    font-size: 1em !important;
    width: 80vw !important;
    line-height: 1.5em !important;
  }
}

.MayanNumber{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
  height:5vh !important;
  width:7vw !important;
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  column-gap: 0em !important;
}

.MayanDot{
  position: relative !important;
  top:0vh !important;
  left:0vw !important; 
  font-family: Graphik, Arial, Helvetica, sans-serif;
  color: var(--amplify-colors-turquoise-light) !important;
  font-size: 4em !important;
  font-weight: 500  !important;
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

@media screen and (max-width: 450px) {
  .MayanDot {
    font-size: 2em !important;
  }
}

.ApplicationLink{
  position: relative !important;
  top:20vh !important;
  left:17vw !important; 
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}

.TurboFanBoostButton{
  border-color:  transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;
}


.ScrollContainer{
  position: absolute !important;
  bottom:-30vh !important;
  left:17vw !important; 
  border-color: transparent !important;
  border-style:  solid !important;
  border-width:  2px !important;

}

.ScrollMessage{
  font-size:1em !important;
  font-weight:100 !important;
  color: var(--amplify-colors-orange-light) !important;
}